<template>
  <article :class="{ [$style.Output]: true }">
    <template v-if="output.active_feed.type === 'recorded'">
      <!-- Pre recorded video outputs -->
      <output-recorded
        v-if="output.active_feed && output.active_feed.url"
        :type="output.active_feed.type"
        :url="output.active_feed.url"
        :label="output.active_feed.label"
        :playback="output.playback"
        :currentTime="output.current_time"
        :paused="output.paused"
        :loop="output.loop"
        :uid="output.active_feed.uid"
        :muted="output.muted || output.mute_audience || forceMute"
      >
        <template slot="timestamp">
          <header :class="$style.Timestamp">{{ timestamp }}<span /></header>
        </template>
        <template slot="output">
          <footer :class="$style.FeedInfo">
            <span>{{ output.label }}</span>
            <span>{{ output.active_feed.label }}</span>
          </footer>
        </template>
      </output-recorded>
    </template>

    <!-- Live video feeds -->
    <template>
      <output-live
        v-show="output.active_feed.type === 'live'"
        :type="output.active_feed.type"
        :url="output.active_feed.url"
        :label="output.active_feed.label"
        :playback="output.playback"
        :paused="output.paused"
        :active-panel="panel"
        :uid="output.active_feed.uid"
        :muted="output.muted || output.mute_audience || forceMute"
      >
        <template slot="timestamp">
          <header :class="$style.Timestamp">{{ timestamp }}<span /></header>
        </template>
        <template slot="output">
          <footer :class="$style.FeedInfo">
            <span>{{ output.label }}</span>
            <span>{{ output.active_feed.label }}</span>
          </footer>
        </template>
      </output-live>
    </template>
    <div v-if="output.text_overlay !== ''" :class="$style.TextOverlay">
      {{ output.text_overlay }}
    </div>
    <template v-if="!output.active_feed">
      <div :class="$style.NoFeed">
        <header :class="$style.Timestamp">{{ timestamp }}<span /></header>
        no feed configured
        <footer :class="$style.FeedInfo">
          <span>{{ output.label }}</span>
        </footer>
      </div>
    </template>
  </article>
</template>

<script>
import format from "date-fns/format";
export default {
  name: "AudienceOutput",
  props: {
    output: {
      type: [Object, String],
      required: true,
    },
    forceMute: {
      type: Boolean,
      default: false,
    },
    panel: {
      type: [Number, Boolean],
      default: false,
    },
  },
  computed: {
    feedUrl() {
      return this.output.active_feed ? this.output.active_feed.url : false;
    },
  },
  data() {
    return {
      autoplayAvailable: true,
      timestamp: "--",
    };
  },
  mounted() {
    this.getTimestamp();
  },
  methods: {
    getTimestamp() {
      setInterval(() => {
        const date = new Date();
        this.timestamp = format(date, "hh:mm:ss/dd.MMM.yy");
      }, 1000);
    },
  },
};
</script>

<style module lang="postcss">
.Output {
  @apply relative w-full;
}

.Timestamp {
  @apply uppercase p-1 absolute top-0 left-0 z-10 bg-black bg-opacity-50;
  @apply text-white flex items-center leading-none;
  & span {
    @apply block w-3 h-3 rounded-full bg-red-600 transform scale-75 ml-1;
  }
}

.FeedInfo {
  @apply flex justify-between w-full uppercase absolute bottom-0 left-0;
  & span {
    @apply block bg-black bg-opacity-50 text-white px-1 py-px;
  }
}

.TextOverlay {
  @apply absolute top-0 left-0 bottom-0 right-0 bg-black bg-opacity-50 text-white;
  @apply p-6 flex items-center justify-center uppercase;
}

.NoFeed {
  @apply relative w-full max-h-full bg-black flex items-center justify-center;
  aspect-ratio: 4/3;
}
</style>
