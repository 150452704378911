<template>
  <div id="app">
    <div
      :class="{
        [$style.Grid]: true,
      }"
    >
      <img
        :src="'https://storage.googleapis.com/remote-control-fced4.appspot.com/gifs/ezgif.com-gif-maker.gif'"
        :class="$style.BgStatic"
      />
      <img
        :src="'https://storage.googleapis.com/remote-control-fced4.appspot.com/gifs/ezgif.com-gif-maker.gif'"
        :class="[$style.BgStatic, $style.Flip]"
      />
      <app-header :class="$style.Header" />
      <div :class="$style.Content">
        <template v-if="authChecked">
          <router-view />
        </template>
      </div>
      <screen-blocker />
      <v-dialog :class="$style.Dialog" />
    </div>
  </div>
</template>
<script>
import { auth } from "@/firebase";
import { mapActions, mapGetters } from "vuex";
import AppHeader from "@/components/AppHeader";
import ScreenBlocker from "@/components/ScreenBlocker";

import "sanitize.css";
export default {
  name: "App",
  components: {
    AppHeader,
    ScreenBlocker,
  },
  data() {
    return {
      authChecked: false,
      glitch: false,
    };
  },
  computed: {
    ...mapGetters["admin"],
  },
  async mounted() {
    try {
      auth.onAuthStateChanged(async (profile) => {
        if (profile) {
          const user = await this.accountSetup(profile);
          if (
            user.admin === true &&
            (this.$route.name === "login" || this.$route.name === "admin-login")
          )
            this.$router.replace({ name: "dashboard" }).catch(() => {});
        } else {
          await this.accountReset();
        }
        this.authChecked = true;
      });
    } catch (err) {
      console.error(err);
    } finally {
      await this.bindCopy();
    }
  },
  methods: {
    ...mapActions(["accountSetup", "accountReset", "bindCopy"]),
  },
};
</script>
<style lang="postcss">
@import "./assets/main.css";

html {
  font-family: sans-serif;
}
body {
  font-smooth: always;
  @apply fixed top-0 left-0 w-full;
}
</style>

<style module lang="postcss">
@tailwind base;
@layer base {
  html {
    @apply h-screen max-h-screen;
    @apply overflow-auto;
  }

  body {
    @apply overflow-auto;
    @apply font-mono bg-black text-white  text-sm;
    @apply h-screen max-h-screen;
    font-family: "brass", monospace;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  textarea[type="text"],
  textarea.c-textarea {
    font-family: "brass", monospace;

    @apply text-xs outline-none w-full font-mono h-8 px-2;
    @apply border border-solid border-gray-400 bg-gray-900 bg-opacity-10;
    &:disabled {
      @apply opacity-50;
    }
    &:focus,
    &:active {
      @apply border-white outline-none shadow-none ring-0;
    }
    &::placeholder {
      font-family: "brass", monospace;
      @apply text-xs leading-none !important;
    }
  }
  textarea {
    @apply p-2 h-auto resize-none !important;
  }
}

.Grid {
  @apply overflow-auto bg-black h-screen;
  @apply flex flex-col gap-3;
  min-height: 730px;
}

.Header {
  @apply h-10 flex-shrink-0 fixed top-2 left-2 right-2 z-30;
}

.Content {
  @apply pt-12 flex-grow grid h-full relative z-20;
}

.Dialog {
  @apply bg-black bg-opacity-30;
  & :global(.vue-dialog) {
    @apply p-0;
    & div {
      @apply p-3;
    }
    @apply rounded-none border border-solid border-white bg-black;
  }
  & :global(.vue-dialog-content),
  & :global(.vue-dialog-buttons) {
    @apply p-0 !important;
  }
  & :global(.vue-dialog-content-title) {
    @apply bg-white text-black p-3;
  }
  & :global(.vue-dialog-buttons) {
    & button {
      @apply hover:bg-black hover:underline;
    }
  }
}

.SnackBar {
  @apply top-0 right-0 bottom-auto left-auto;
}

.BgStatic {
  @apply fixed top-0 left-0 w-full h-full object-cover opacity-5 bg-black;
  z-index: 0;
}

.Flip {
  transform: scaleY(-1);
  z-index: 1;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
</style>
