<template>
  <form @submit.prevent="submit">
    <block v-if="interaction" light-header>
      <template slot="header">
        <h2>{{ interaction.label || `Interaction ${index + 1}` }}</h2>
      </template>
      <form-row :error="$v.label.$error || error !== false" label="label">
        <input
          v-model.trim="$v.label.$model"
          placeholder="/label/"
          type="text"
        />
        <form-error v-if="!$v.label.required">
          This field is required
        </form-error>
      </form-row>
      <form-row
        :error="$v.qlab_target.$error || error !== false"
        label="Qlab path thingy"
      >
        <input
          v-model.trim="$v.qlab_target.$model"
          placeholder="/set Qlab cue path thingy/"
          type="text"
        />
        <form-error v-if="!$v.qlab_target.required">
          This field is required
        </form-error>
      </form-row>
      <form-row :error="$v.qlab_ip.$error || error !== false" label="Qlab IP">
        <input
          v-model.trim="$v.qlab_ip.$model"
          placeholder="/set Qlab IP address/"
          type="text"
        />
        <form-error v-if="!$v.qlab_ip.required">
          This field is required
        </form-error>
        <form-error v-if="!$v.qlab_ip.ipAddress">
          Please enter a valid IP address
        </form-error>
      </form-row>
      <form-row :error="$v.timer.$error || error !== false" label="Timer">
        <input
          v-model.trim="$v.timer.$model"
          placeholder="/how long users have to vote/"
          type="number"
        />
        <form-error v-if="!$v.timer.required">
          This field is required
        </form-error>
      </form-row>
      <form-row
        :error="$v.votes_required.$error || error !== false"
        label="Votes Required"
      >
        <input
          v-model.trim="$v.votes_required.$model"
          placeholder="/votes required to activate/"
          type="number"
        />
        <form-error v-if="!$v.votes_required.required">
          This field is required
        </form-error>
      </form-row>
      <template slot="footer">
        <shitty-button
          :disabled="
            $v.$anyError || !$v.$anyDirty || submitStatus === 'PENDING'
          "
          type="submit"
          >save</shitty-button
        >
      </template>
    </block>
  </form>
</template>

<script>
import { required, ipAddress } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  name: "AdminInteractions",
  props: {
    interaction: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
  },
  validations: {
    label: {
      required,
    },
    qlab_target: {
      required,
    },
    timer: {
      required,
    },
    votes_required: {
      required,
    },
    qlab_ip: {
      required,
      ipAddress,
    },
  },

  data() {
    return {
      label: "",
      qlab_target: "",
      timer: 10,
      votes_required: 1,
      error: false,
      submitStatus: null,
      qlab_ip: null,
    };
  },
  mounted() {
    this.label = this.interaction.label;
    this.qlab_target = this.interaction.qlab_target;
    this.qlab_ip = this.interaction.qlab_ip;
    this.timer = this.interaction.timer;
    this.votes_required = this.interaction.votes_required;
  },
  methods: {
    ...mapActions("performance/interactions", ["updateInteraction"]),
    async submit() {
      try {
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.submitStatus = "ERROR";
        } else {
          // do your submit logic here
          this.submitStatus = "PENDING";
          await this.updateInteraction({
            performanceId: this.$route.params.id,
            interactionId: this.interaction.id,
            data: {
              label: this.label,
              qlab_target: this.qlab_target,
              qlab_ip: this.qlab_ip,
              timer: this.timer,
              votes_required: parseInt(this.votes_required),
            },
          });
          this.submitStatus = "SUCCESS";
          this.$modal.show("dialog", {
            title: "Interaction updated",
            buttons: [
              {
                title: "OK",
                handler: () => {
                  this.$modal.hide("dialog");
                  this.$v.$reset();
                },
              },
            ],
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
