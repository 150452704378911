import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

require("dotenv").config();

const isDev = process.env.NODE_ENV === "development";
const emulate = false;

let firebaseConfig;

if (isDev && emulate) {
  firebaseConfig = {
    apiKey: "AIzaSyBjTbosoM5cb93uii59g_0NleKrCC_Jsso",
    authDomain: "remote-control-fced4.firebaseapp.com",
    projectId: "remote-control-fced4",
    storageBucket: "remote-control-fced4.appspot.com",
    messagingSenderId: "933412250038",
    appId: "1:933412250038:web:d2f80e77c168d8a9162117",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyBjTbosoM5cb93uii59g_0NleKrCC_Jsso",
    authDomain: "remote-control-fced4.firebaseapp.com",
    projectId: "remote-control-fced4",
    storageBucket: "remote-control-fced4.appspot.com",
    messagingSenderId: "933412250038",
    appId: "1:933412250038:web:c3a9f2dade4b40cf162117",
  };
}

const app =
  firebase.apps && !firebase.apps.length
    ? firebase.initializeApp(firebaseConfig)
    : firebase.apps[0];

const db = app.firestore();
const auth = app.auth();
const storage = app.storage();

const { Timestamp, FieldValue } = firebase.firestore;

const getDisplayName = (username) => {
  if (!username) return "--";
  const name = username.split("-");
  return name.length ? name[0] : name;
};
const getTicketId = (username) => {
  username.split("-")[1];
};
// console.log(firebase);
if (isDev && emulate) {
  auth.useEmulator("http://localhost:9099");
  db.useEmulator("localhost", 8080);
  storage.useEmulator("localhost", 8081);
}

export {
  db,
  auth,
  storage,
  Timestamp,
  FieldValue,
  getDisplayName,
  getTicketId,
};
