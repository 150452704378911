import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";

const lobby = {
  namespaced: true,
  state: {
    lobby: null,
  },
  actions: {
    createLobby: firestoreAction(({ rootGetters }, { performanceId, data }) => {
      console.log(data);
      return db
        .collection("performances")
        .doc(performanceId)
        .collection("lobby")
        .doc(performanceId)
        .set(data);
    }),
    updateLobby: firestoreAction(({ rootGetters }, { performanceId, data }) => {
      return db
        .collection("performances")
        .doc(performanceId)
        .collection("lobby")
        .doc(performanceId)
        .update(data);
    }),
    deleteLobby: firestoreAction(({ rootGetters }, { performanceId }) => {
      return db
        .collection("performances")
        .doc(performanceId)
        .collection("lobby")
        .doc(performanceId)
        .delete();
    }),
    bindLobby: firestoreAction(({ bindFirestoreRef }, performanceId) => {
      // Bind all performances to the state
      return bindFirestoreRef(
        "lobby",
        db
          .collection("performances")
          .doc(performanceId)
          .collection("lobby")
          .doc(performanceId)
      );
    }),
    unbindLobby: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("lobby");
    }),
  },
};

export default lobby;
