<template>
  <ul :class="$style.Grid">
    <li v-for="(item, i) in interactions" :key="`interaction-${i}`">
      <block
        :inactive="item.locked && activeInteraction !== false"
        light-header
      >
        <template slot="header">
          <div :class="$style.Activation">
            {{ item.label }}
            <button
              v-if="item.locked && !item.used"
              :disabled="activeInteraction && activeInteraction.id !== item.id"
              @click="(val) => update(item, true)"
            >
              <img :src="require('@/assets/icons/inactive.svg')" />
            </button>
            <button
              v-if="!item.locked && !item.used"
              :disabled="activeInteraction && activeInteraction.id !== item.id"
              @click="(val) => update(item, false)"
            >
              <img :src="require('@/assets/icons/active.svg')" />
            </button>
          </div>
          <div :class="$style.Manual">
            <button
              :disabled="activeInteraction && activeInteraction.id !== item.id"
              @click="() => resetInteraction(item)"
            >
              <img :src="require('@/assets/icons/reset.svg')" />
            </button>
            <button
              v-if="!item.used"
              :disabled="activeInteraction && activeInteraction.id !== item.id"
              @click="() => forceInteraction(item)"
            >
              <img :src="require('@/assets/icons/trigger.svg')" />
            </button>
          </div>
        </template>
        <dl :class="[$style.List, 'u-dl']">
          <dt>Active:</dt>
          <dd>{{ !item.locked }}</dd>
          <dt>Timer:</dt>
          <dd>{{ item.timer }}s</dd>
          <dt>Time remaining:</dt>
          <dd>{{ item.time_remaining || item.timer }}s</dd>
          <dt>Voting Open:</dt>
          <dd>{{ item.voting_open }}</dd>
          <dt>Votes:</dt>
          <dd>{{ item.votes }}</dd>
          <dt>Votes Required:</dt>
          <dd>{{ item.votes_required }}</dd>
          <dt>Vote Passed:</dt>
          <dd>{{ item.votes >= item.votes_required }}</dd>
          <dt>Used:</dt>
          <dd>{{ item.used || false }}</dd>
        </dl>
      </block>
    </li>
  </ul>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ControlsInteractions",
  props: {
    interactions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    activeInteraction() {
      for (let index = 0; index < this.interactions.length; index++) {
        if (!this.interactions[index].locked) return this.interactions[index];
      }
      return false;
    },
    votePassed() {
      if (!this.activeInteraction) return false;
      return (
        this.activeInteraction.votes >= this.activeInteraction.votes_required
      );
    },
  },
  watch: {
    async votePassed(val) {
      if (val) {
        console.log("VOTE PASSED. TRIGGERING INTERACTION");
        this.interval = clearInterval(this.interval);
        // If voting is closed then dont do anything
        // This attempts to handle multiple Interaction Admin actions (badly)
        // if (!this.activeInteraction.voting_open) return;
        try {
          // Trigger the interaction if the vote passes
          this.triggerInteraction(this.activeInteraction).then(() => {
            console.log("interaction triggered");
          });
        } catch (err) {
          console.error(err);
        }
      }
    },
  },
  data() {
    return {
      timer: null,
      interval: null,
    };
  },
  methods: {
    ...mapActions("performance/interactions", [
      "updateInteraction",
      "resetVoteStatus",
    ]),
    async update(interaction, val) {
      this.$modal.show("dialog", {
        title: `${val ? "Activate" : "Deactivate"} interaction: ${
          interaction.label
        }`,
        text: `Are you sure you want to ${
          val ? "activate" : "deactivate"
        } this interaction?`,
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: val ? "Activate" : "Deactivate",
            // TODO: refactor this clusterfuck
            handler: async () => {
              if (val) {
                await this.startTimer(interaction);
              } else {
                this.interval = clearInterval(this.interval);
                await this.updateInteraction({
                  performanceId: this.$route.params.id,
                  interactionId: interaction.id,
                  data: {
                    locked: true,
                  },
                });
              }
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    },
    resetInteraction(interaction) {
      this.$modal.show("dialog", {
        title: `Reset interaction: ${interaction.label}`,
        text: "Are you sure you want to reset this interaction?",
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: "Reset",
            handler: async () => {
              this.interval = clearInterval(this.interval);
              try {
                await this.resetVoteStatus({
                  performanceId: this.$route.params.id,
                  interactionId: interaction.id,
                });
              } catch (err) {
                console.error(err);
              } finally {
                this.$modal.hide("dialog");
              }
            },
          },
        ],
      });
    },
    async forceInteraction(interaction) {
      try {
        console.log("Attempting QLAB trigger:", interaction.qlab_target);
        // TODO: enable this once Qlab is up and running
        await fetch(
          `http://${interaction.qlab_ip}:5000/${interaction.qlab_target}`
        );
        console.log("QLAB trigger SUCCCESS:", interaction.qlab_target);
      } catch (err) {
        console.error("QLAB trigger FAILURE:", err);
        console.error("Please check the QLAB setup");
      } finally {
        let voters = [];
        for (let index = 0; index < interaction.votes_required; index++) {
          voters.push(index);
        }
        await this.updateInteraction({
          performanceId: this.$route.params.id,
          interactionId: interaction.id,
          data: {
            voting_open: false,
            vote_passed: true,
            vote_failed: false,
            votes: interaction.votes_required,
            used: true,
            voters,
          },
        });
        // Wait for a bit, then handle UI state
      }
    },
    async triggerInteraction(interaction) {
      // Force an interaction withough allowing audience to vote
      try {
        console.log("Attempting QLAB trigger:", interaction.qlab_target);
        // TODO: enable this once Qlab is up and running
        await fetch(
          `http://${interaction.qlab_ip}:5000/${interaction.qlab_target}`
        );
        console.log("QLAB trigger SUCCCESS:", interaction.qlab_target);
      } catch (err) {
        console.error("QLAB trigger FAILURE:", err);
        console.error("Please check the QLAB setup");
      } finally {
        this.interval = clearInterval(this.interval);
        await this.updateInteraction({
          performanceId: this.$route.params.id,
          interactionId: interaction.id,
          data: {
            voting_open: false,
            vote_passed: true,
          },
        });
        // Wait for a bit, then handle UI state
        setTimeout(async () => {
          await this.updateInteraction({
            performanceId: this.$route.params.id,
            interactionId: interaction.id,
            data: {
              locked: true,
              used: true,
            },
          });
        }, 5000);
      }
    },
    async startTimer(interaction) {
      this.timer = interaction.timer;
      try {
        await this.updateInteraction({
          performanceId: this.$route.params.id,
          interactionId: interaction.id,
          data: {
            locked: false,
            voting_open: true,
            time_remaining: parseInt(this.timer),
            used: false,
            vote_passed: false,
            vote_failed: false,
            votes: 0,
          },
        });
      } catch (err) {
        console.error(err);
      }
      // Start the timer. Update every second
      this.interval = setInterval(async () => {
        try {
          this.timer = this.timer - 1;
          await this.updateInteraction({
            performanceId: this.$route.params.id,
            interactionId: interaction.id,
            data: {
              time_remaining: this.timer,
            },
          });
          if (this.timer === 0) {
            console.log("TIMER HAS EXPIRED WITHOUT REQUIRED VOTES");
            this.interval = clearInterval(this.interval);
            await this.updateInteraction({
              performanceId: this.$route.params.id,
              interactionId: interaction.id,
              data: {
                voting_open: false,
                vote_passed: false,
                vote_failed: true,
              },
            });
            // Wait for a bit, then handle UI state
            setTimeout(async () => {
              await this.updateInteraction({
                performanceId: this.$route.params.id,
                interactionId: interaction.id,
                data: {
                  locked: true,
                  used: true,
                },
              });
            }, 5000);
          }
        } catch (err) {
          console.error(err);
        }
      }, 1000);
    },
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid grid-cols-4 gap-4;
  @apply h-auto !important;
}

.List {
  @apply flex flex-wrap;
  & dt {
    @apply w-2/5 py-1 border-b border-gray-800 uppercase;
  }
  & dd {
    @apply w-3/5 flex-grow m-0 py-1 pl-2 border-b border-l border-gray-800;
  }
}

.Warning {
  @apply text-red-600;
}

.Activation,
.Manual {
  @apply flex items-center gap-2;
}
</style>
