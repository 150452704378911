var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.output)?_c('block',{attrs:{"light-header":""}},[_c('template',{class:_vm.$style.Header,slot:"header"},[_c('h2',[_vm._v(_vm._s(_vm.output.label)+" preview")])]),_c('div',{class:_vm.$style.Output},[_c('div',{class:_vm.$style.Video},[_c('div',{class:['aspect-w-16 aspect-h-9', _vm.$style.VideoPreview]},[(
            _vm.output.active_feed &&
            _vm.output.active_feed.type === 'recorded' &&
            _vm.output.active_feed.url
          )?[_c('output-recorded',{attrs:{"is-admin":"","label":_vm.output.active_feed.label,"url":_vm.output.active_feed.url,"type":_vm.output.active_feed.type,"uid":_vm.output.active_feed.uid,"playback":_vm.output.playback,"paused":_vm.output.paused,"loop":_vm.output.loop,"muted":_vm.output.muted,"controls":true},on:{"play":function () { return _vm.onPlayPause(false); },"pause":function () { return _vm.onPlayPause(true); },"seeked":function (time) { return _vm.onSeeked(time); }}})]:_vm._e(),(
            _vm.output.active_feed &&
            _vm.output.active_feed.type === 'live' &&
            _vm.output.active_feed.url
          )?[_c('output-live',{attrs:{"is-admin":"","label":_vm.output.active_feed.label,"url":_vm.output.active_feed.url,"type":_vm.output.active_feed.type,"uid":_vm.output.active_feed.uid,"playback":_vm.output.playback,"muted":_vm.output.muted,"paused":_vm.output.paused},on:{"play":function () { return _vm.onPlayPause(false); },"pause":function () { return _vm.onPlayPause(true); }}})]:_vm._e(),_c('span',{class:_vm.$style.textOverlayOutput},[_vm._v(" "+_vm._s(_vm.output.text_overlay)+" ")])],2)]),_c('div',{class:_vm.$style.Options},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.textOverlay),expression:"textOverlay"}],attrs:{"type":"text","placeholder":"text overlay"},domProps:{"value":(_vm.textOverlay)},on:{"input":function($event){if($event.target.composing){ return; }_vm.textOverlay=$event.target.value}}}),_c('shitty-button',{attrs:{"disabled":(_vm.output.text_overlay === '' && _vm.textOverlay === '') ||
          _vm.output.text_overlay === _vm.textOverlay},nativeOn:{"click":function($event){return _vm.onUpdateTextOverlay.apply(null, arguments)}}},[_c('img',{attrs:{"src":require('@/assets/icons/update.svg')}})])],1),_c('div',{class:_vm.$style.Feeds},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.feed),expression:"feed"}],staticClass:"c-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.feed=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.onSetFeed]}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("feeds")]),_vm._l((_vm.feedOptions),function(item,i){return _c('option',{key:("feed-" + i),domProps:{"value":item}},[_vm._v(" "+_vm._s(item.type)+" - "+_vm._s(item.label)+" ")])})],2)]),_c('div',{class:_vm.$style.Options},[_c('div',{class:_vm.$style.Playback},[(_vm.output.muted)?_c('shitty-button',{attrs:{"danger":""},nativeOn:{"click":function($event){return _vm.onToggleMute(false)}}},[_c('img',{attrs:{"src":require('@/assets/icons/muted.svg')}})]):_vm._e(),(!_vm.output.muted)?_c('shitty-button',{nativeOn:{"click":function($event){return _vm.onToggleMute(true)}}},[_c('img',{attrs:{"src":require('@/assets/icons/unmuted.svg')}})]):_vm._e()],1),_c('shitty-button',{class:( _obj = {}, _obj[_vm.$style.CutFeed] = true, _obj['c-btn-danger'] = !_vm.output.playback, _obj ),nativeOn:{"click":function($event){_vm.output.playback ? _vm.onCutFeed() : _vm.onEnableFeed()}}},[_vm._v(" "+_vm._s(_vm.output.playback ? "disable" : "enable")+" ")])],1)])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }