<template>
  <container outer>
    <form-audience-login @form-submit="onLogIn">
      <p v-if="copy">
        {{ copy.login_text }}
      </p>
    </form-audience-login>
  </container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import FormAudienceLogin from "@/components/FormAudienceLogin.vue";

export default {
  name: "login",
  components: {
    FormAudienceLogin,
  },
  computed: {
    ...mapState(["copy"]),
  },
  methods: {
    ...mapActions("performance/audience", ["validateTicketId"]),
    async onLogIn({ ticketId, displayName }) {
      try {
        const performances = await this.validateTicketId({
          ticketId,
          displayName,
        });
        console.log(performances);
        if (!performances.length) {
          console.log("access denied");
          return;
        }
        if (performances.length > 1) {
          console.log("user assigned to more than 1 performance");
          return;
        }
        this.$router
          .replace({ name: "waiting-room", params: { id: performances[0] } })
          .catch(() => {});
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
