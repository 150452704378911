<template>
  <div
    :class="$style.Switch"
    role="checkbox"
    :aria-checked="value.toString()"
    tabindex="0"
    @click="toggle"
    @keydown.space.prevent="toggle"
  >
    <span
      :class="{
        [$style.ToggleBackground]: true,
        [$style.Active]: value,
        [$style.Inactive]: !value,
      }"
    />
    <span
      :class="{
        [$style.Indicator]: true,
        [$style.Active]: value,
        [$style.Inactive]: !value,
      }"
    />
  </div>
</template>

<script>
export default {
  name: "ShittySwitch",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggle() {
      this.$emit("input", !this.value);
    },
  },
};
</script>
<style module lang="postcss">
.Switch {
  @apply inline-block w-10 h-6 relative cursor-pointer rounded-full;
  &:focus {
    @apply outline-none;
  }
}

.ToggleBackground {
  @apply bg-gray-400 inline-block rounded-full w-full h-full;
  @apply transition-colors duration-500 ease-in-out;
  &.Active {
  }
}

.Indicator {
  @apply absolute w-4 h-4 top-1/2 left-1 transform -translate-y-1/2;
  @apply bg-gray-200 rounded-full;
  @apply transition-all duration-500 ease-in-out;
  &.Active {
    @apply left-auto right-1;
    @apply bg-green;
  }
}
</style>
