<template>
  <div
    :class="{
      [$style.Wrap]: true,
      [$style.Outer]: outer,
      [$style.Border]: border,
      [$style.Scroll]: scroll,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    outer: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply w-full;
}

.Border {
  @apply border border-white border-solid;
}
.Outer {
  @apply px-2 pt-3 pb-2 overflow-hidden;
}
.Scroll {
  @apply overflow-y-auto;
}
</style>
