import { vuexfireMutations, firestoreAction } from "vuexfire";
import { db } from "@/firebase";
import messages from "./messages";
import interactions from "./interactions";
import audience from "./audience";
import outputs from "./outputs";
import liveStreams from "./liveStreams";
import recordedVideos from "./recordedVideos";
import panels from "./panels";
import audio from "./audio";
import lobby from "./lobby";

const performance = {
  namespaced: true,
  state: {
    current: null,
    defaultOutput: null,
    defaultPerformance: null,
    list: null,
    hasInteracted: false,
  },
  getters: {
    setupComplete(state) {
      if (!state.current) return false;
      return Object.values(state.current.setup).every((item) => item);
    },
  },
  mutations: {
    ...vuexfireMutations,
    SET_DEFAULT_OUTPUT(state, data) {
      state.defaultOutput = data;
    },
    SET_CURRENT(state, data) {
      state.current = data;
    },
    SET_HAS_INTERACTED(state, data) {
      state.hasInteracted = data;
    },
  },
  actions: {
    create({ rootGetters }, data) {
      if (!rootGetters.admin) return;
      // Add a new document with a generated id.
      return db
        .collection("performances")
        .add(data)
        .then((docRef) => {
          return docRef.id;
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    async get({ commit }, performanceId) {
      try {
        const docRef = db.collection("performances").doc(performanceId);
        const doc = await docRef.get();
        if (doc.exists) {
          commit("SET_CURRENT", doc.data());
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error(err);
      }
    },
    update: firestoreAction(({ rootGetters }, { data, id }) => {
      console.log(data, id);
      // Only admins can perform CRUD actions
      if (!rootGetters.admin) return;
      return db.collection("performances").doc(id).update(data);
    }),
    updatePanel: firestoreAction(
      ({ rootGetters, state }, { data, id, index }) => {
        const outputs = data.outputs;
        const outputRefs = [];
        for (let i = 0; i < outputs.length; i++) {
          const ref = db
            .collection("performances")
            .doc(id)
            .collection("outputs")
            .doc(outputs[i].id);
          outputRefs.push(ref);
        }
        let panels = state.current.panels;
        panels[index].outputs = outputRefs;
        // Only admins can perform CRUD actions
        if (!rootGetters.admin) return;
        return db.collection("performances").doc(id).update({ panels });
      }
    ),
    delete: firestoreAction(async ({ rootGetters }, id) => {
      // Only admins can perform CRUD actions
      // TODO: delete all messages too
      if (!rootGetters.admin) return;

      // get all audience with matching performance ID
      // const audience = db.collectionGroup("audience");
      // set archived flag
      // then delete the doc
      db.collection("performances")
        .doc(id)
        .delete()
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          console.error(err);
        });
    }),
    bind: firestoreAction(({ bindFirestoreRef }, id) => {
      // Bind a single performance to the state
      return bindFirestoreRef("current", db.collection("performances").doc(id));
    }),
    unbind: firestoreAction(({ unbindFirestoreRef }) => {
      // Unbind a single performance to the state
      return unbindFirestoreRef("current");
    }),
    bindAll: firestoreAction(({ bindFirestoreRef, rootGetters }) => {
      // Bind all performances to the state
      console.log("binding performances");
      return bindFirestoreRef("list", db.collection("performances"));
    }),
    unbindAll: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("list");
    }),
    bindDefault: firestoreAction(({ bindFirestoreRef, rootGetters }) => {
      // Bind a single performance to the state
      return bindFirestoreRef(
        "defaultPerformance",
        db.collection("defaults").doc("performance")
      );
    }),
    unbindDefault: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("defaultPerformance");
    }),
    getDefaultPerformance: () => {
      const docRef = db.collection("defaults").doc("performance");
      return docRef
        .get()
        .then((resp) => {
          const data = resp.data();
          return data;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return error;
        });
    },
    getDefaultOutput: () => {
      const docRef = db.collection("defaults").doc("output");
      return docRef
        .get()
        .then((resp) => {
          const data = resp.data();
          return data;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return error;
        });
    },
    getDefaultAudio: () => {
      const docRef = db.collection("defaults").doc("audio");
      return docRef
        .get()
        .then((resp) => {
          const data = resp.data();
          return data;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return error;
        });
    },
    getDefaultInteractions: () => {
      const docRef = db.collection("defaults").doc("interactions");
      return docRef
        .get()
        .then((resp) => {
          const data = resp.data();
          return data;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return error;
        });
    },
    getDefaultLiveStreams: () => {
      const docRef = db.collection("defaults").doc("live-streams");
      return docRef
        .get()
        .then((resp) => {
          const data = resp.data();
          return data;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return error;
        });
    },
    getDefaultPanel: () => {
      const docRef = db.collection("defaults").doc("panel");
      return docRef
        .get()
        .then((resp) => {
          const data = resp.data();
          return data;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return error;
        });
    },
    getDefaultLobby: () => {
      const docRef = db.collection("defaults").doc("lobby");
      return docRef
        .get()
        .then((resp) => {
          const data = resp.data();
          return data;
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          return error;
        });
    },
    startShow: firestoreAction(async ({ rootGetters }, id) => {
      if (!rootGetters.admin) return;
      return db.collection("performances").doc(id).update({
        active: true,
        performance_started: true,
        performance_complete: false,
      });
    }),
    endShow: firestoreAction(({ rootGetters }, id) => {
      if (!rootGetters.admin) return;
      return db.collection("performances").doc(id).update({
        active: false,
        performance_started: false,
        performance_complete: true,
      });
    }),
    resetShow: firestoreAction(({ rootGetters }, id) => {
      if (!rootGetters.admin) return;
      return db.collection("performances").doc(id).update({
        active: false,
        performance_started: false,
        performance_complete: false,
      });
    }),
  },
  modules: {
    messages,
    interactions,
    audience,
    outputs,
    liveStreams,
    recordedVideos,
    panels,
    audio,
    lobby,
  },
};

export default performance;
