<template>
  <button
    :class="{
      ['c-btn']: true,
      ['c-btn-danger']: danger,
      ['c-btn-success']: success,
      [$style.Icon]: icon,
      [$style.FullWidth]: fullWidth,
    }"
  >
    <span><slot /></span>
  </button>
</template>

<script>
export default {
  name: "ShittyButton",
  props: {
    danger: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style module lang="postcss">
.Icon {
  @apply px-3;
}

.FullWidth {
  @apply w-full;
}
</style>
