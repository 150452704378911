<template>
  <block :class="$style.CharacterMessageInput" light-header>
    <template slot="header">
      <h3>{{ castMember.displayName }}</h3>
    </template>
    <div :class="$style.Input">
      <textarea
        v-model="message"
        type="text"
        rows="2"
        style="resize: none"
        placeholder="/send a message to the audience/"
      />
      <shitty-button @click.native="onSendMessage">Send message</shitty-button>
    </div>
  </block>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CharacterMessagingInput",
  props: {
    castMember: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  data() {
    return {
      message: "",
    };
  },
  methods: {
    ...mapActions("performance/messages", ["createMessage"]),
    async onSendMessage() {
      if (this.message === "") return;
      try {
        const date = new Date();
        await this.createMessage({
          performanceId: this.$route.params.id,
          text: this.message,
          role: "Cast",
          displayName: this.castMember.displayName,
          time: date,
        });
        this.message = "";
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style module lang="postcss">
.CharacterMessageInput {
  & h3 {
    @apply m-0;
  }
}

.Input {
  @apply p-0 relative flex gap-3;
  & textarea {
    @apply rounded-none h-full w-full p-3 font-sans;
  }
  & button {
    @apply self-end whitespace-nowrap;
  }
}
</style>
