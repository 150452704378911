<template>
  <label :class="$style.Label">
    <slot />
  </label>
</template>

<script>
export default {
  name: "FormLabel",
};
</script>

<style module lang="postcss">
.Label {
  @apply flex mb-1 uppercase text-white text-xs items-center gap-3 leading-none;
}
</style>
