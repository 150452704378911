import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login";
import Enter from "@/views/Enter";
import LoginAdmin from "@/views/LoginAdmin";
import Register from "@/views/Register";
import WaitingRoom from "@/views/WaitingRoom";
import Dashboard from "@/views/admin/Dashboard";
import Performance from "@/views/Performance";
import PerformanceSetup from "@/views/admin/Setup";
import PerformanceControls from "@/views/admin/Controls";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/enter",
    name: "enter",
    component: Enter,
  },
  {
    path: "/login",
    name: "admin-login",
    component: LoginAdmin,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      requiresAuth: true,
      requiresAdminAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      requiresAdminAuth: true,
    },
  },

  {
    path: "/performance/admin/:id",
    name: "create-performance",
    component: PerformanceSetup,
    meta: {
      requiresAuth: true,
      requiresAdminAuth: true,
    },
  },
  {
    path: "/performance/admin/:id/controls",
    name: "performance-outputs",
    component: PerformanceControls,
    meta: {
      requiresAuth: true,
      requiresAdminAuth: true,
    },
  },
  {
    path: "/performance/:id",
    name: "performance",
    component: Performance,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/performance/:id/waiting-room",
    name: "waiting-room",
    component: WaitingRoom,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    let position = { x: 0, y: 0 };
    // Keep scroll position when using browser buttons
    if (savedPosition) {
      position = savedPosition;
    }
    // Workaround for transitions scrolling to the top of the page
    // However, there are still some problems being fixed by the vue team
    return new Promise((resolve) => {
      resolve(position);
    });
  },
});

export default router;
