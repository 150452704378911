import { firestoreAction } from "vuexfire";
import { db, Timestamp } from "@/firebase";

const messages = {
  namespaced: true,
  state: {
    list: null,
  },
  actions: {
    createMessage: firestoreAction(
      ({ rootGetters }, { performanceId, text, time, displayName }) => {
        const seconds = time.getTime() / 1000;
        const nanoseconds = time.getTime() / 10000;
        const message = {
          name: displayName,
          text,
          role: rootGetters.admin ? "Cast" : "Audience",
          time: new Timestamp(seconds, nanoseconds),
        };
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("messages")
          .doc()
          .set(message);
      }
    ),
    deleteMessage: firestoreAction(
      ({ rootGetters }, { performanceId, messageId }) => {
        db.collection("performances")
          .doc(performanceId)
          .collection("messages")
          .doc(messageId)
          .delete()
          .then((resp) => {
            return resp;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    ),
    bindMessages: firestoreAction(({ bindFirestoreRef }, performanceId) => {
      // Bind all performances to the state
      return bindFirestoreRef(
        "list",
        db
          .collection("performances")
          .doc(performanceId)
          .collection("messages")
          .orderBy("time", "asc")
      );
    }),
    unbindAllMessages: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("list");
    }),
  },
};

export default messages;
