<template>
  <form action="#" @submit.prevent="() => {}" :class="$style.Form">
    <block auto-content-height>
      <template slot="header"> Register </template>
      <form-row>
        <input v-model="form.displayName" type="text" placeholder="Name" />
      </form-row>
      <form-row>
        <input v-model="form.email" type="email" placeholder="Email address" />
      </form-row>
      <form-row>
        <input v-model="form.password" type="password" placeholder="Password" />
      </form-row>
      <template slot="footer">
        <shitty-button @click.native="submit"> Register </shitty-button>
      </template>
    </block>
  </form>
</template>

<script>
import { auth } from "@/firebase";

export default {
  data() {
    return {
      form: {
        displayName: "Euan",
        email: "euan@afew.london",
        password: "290afew!",
      },
      error: null,
    };
  },
  methods: {
    submit() {
      try {
        auth.createUserWithEmailAndPassword(
          this.form.email,
          this.form.password
        );
        console.log("form submit");
      } catch (err) {
        this.error = err;
      }
    },
  },
};
</script>

<style module lang="postcss">
.Form {
  @apply w-96 mx-auto mt-6 text-center;
}
</style>
