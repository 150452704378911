<template>
  <section :class="$style.Wrap">
    <audio ref="player" :id="playerid" :class="$style.Player">
      <source :src="url" type="audio/mpeg" />
    </audio>
    <shitty-button v-if="isPlaying" @click.native="toggleAudio()">
      <img :src="require('@/assets/icons/pause.svg')" />
    </shitty-button>
    <shitty-button v-if="!isPlaying" @click.native="toggleAudio()">
      <img :src="require('@/assets/icons/play.svg')" />
    </shitty-button>
    <div :class="$style.RangeSlider">
      <input
        v-model="playbackTime"
        type="range"
        min="0"
        :max="audioDuration"
        :class="$style.Slider"
        name="position"
      />
      <div :class="$style.Timestamp">
        <span v-html="elapsedTime()"> 00:00 </span>
        /
        <span v-html="totalTime()"> 00:00 </span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AudioPlayer",
  props: ["url", "playerid"],
  data() {
    return {
      playbackTime: 0,
      audioDuration: 100,
      audioLoaded: false,
      isPlaying: false,
      player: null,
    };
  },
  methods: {
    // Set the range slider max value equal to audio duration
    initSlider() {
      if (this.player) this.audioDuration = Math.round(this.player.duration);
    },
    // Convert audio current time from seconds to min:sec display
    convertTime(seconds) {
      const format = (val) => `0${Math.floor(val)}`.slice(-2);
      // const hours = seconds / 3600;
      const minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(":");
    },
    // Show the total duration of audio file
    totalTime() {
      return this.player ? this.convertTime(this.player.duration) : "00:00";
    },
    // Display the audio time elapsed so far
    elapsedTime() {
      return this.player ? this.convertTime(this.player.currentTime) : "00:00";
    },
    // Playback listener function runs every 100ms while audio is playing
    playbackListener() {
      // Sync local 'playbackTime' var to audio.currentTime and update global state
      this.playbackTime = this.player.currentTime;
      // Add listeners for audio pause and audio end events
      this.player.addEventListener("ended", this.endListener);
      this.player.addEventListener("pause", this.pauseListener);
    },
    // Function to run when audio is paused by user
    pauseListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    // Function to run when audio play reaches the end of file
    endListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    // Remove listeners after audio play stops
    cleanupListeners() {
      this.player.removeEventListener("timeupdate", this.playbackListener);
      this.player.removeEventListener("ended", this.endListener);
      this.player.removeEventListener("pause", this.pauseListener);
    },
    // Toggle playback on CTA interaction
    toggleAudio() {
      if (this.player.paused) {
        this.player.play();
        this.isPlaying = true;
      } else {
        this.player.pause();
        this.isPlaying = false;
      }
    },
  },
  mounted: function () {
    // nextTick code will run only after the entire view has been rendered
    this.$nextTick(function () {
      this.player = this.$refs.player;
      // Wait for audio to load, then run initSlider() to get audio duration
      // and set the max value of our slider
      this.player.addEventListener(
        "loadedmetadata",
        function () {
          this.initSlider();
        }.bind(this)
      );

      // "canplay" HTML Event lets us know audio is ready for play
      this.player.addEventListener(
        "canplay",
        function () {
          this.audioLoaded = true;
        }.bind(this)
      );

      // Wait for audio to begin play, then start playback listener function
      this.$watch("isPlaying", function () {
        if (this.isPlaying) {
          this.initSlider();
          //prevent starting multiple listeners at the same time
          if (!this.listenerActive) {
            this.listenerActive = true;
            this.player.addEventListener("timeupdate", this.playbackListener);
          }
        }
      });

      // Update current audio position when user drags progress slider
      this.$watch("playbackTime", function () {
        const diff = Math.abs(this.playbackTime - this.player.currentTime);
        // Throttle synchronization to prevent infinite loop between playback listener and this watcher
        if (diff > 0.01) {
          this.player.currentTime = this.playbackTime;
        }
      });
    });
  },
};
</script>

<style lang="postcss">
input[type="range"] {
  @apply m-auto appearance-none relative  w-full cursor-pointer;
  @apply outline-none rounded-none bg-transparent;
}

input[type="range"]:focus {
  @apply outline-none;
}

::-webkit-slider-runnable-track {
  @apply bg-gray-500 w-full h-3 relative;
  @apply border-t-4 border-b-4 border-black border-solid;
}

::-webkit-slider-thumb {
  @apply appearance-none;
  @apply bg-white shadow-none w-1 h-5 border-none;
  @apply transform -translate-y-3 mt-1;
}

/* ::-moz-range-track {
  height: 40px;
  background: #ddd;
}

::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 0;
  border: none;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw dodgerblue;
  box-sizing: border-box;
} */

/*
::-ms-fill-lower {
  background: dodgerblue;
}

::-ms-thumb {
  background: #fff;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}

::-ms-ticks-after {
  display: none;
}

::-ms-ticks-before {
  display: none;
}

::-ms-track {
  background: #ddd;
  color: transparent;
  height: 40px;
  border: none;
}

::-ms-tooltip {
  display: none;
} */
</style>

<style module lang="postcss">
.Wrap {
  @apply flex gap-3 w-full;
}
.Player {
  @apply hidden !important;
}

.RangeSlider {
  @apply relative flex items-center gap-3 flex-grow;
}

.Timestamp {
  @apply flex pointer-events-none whitespace-nowrap;
}

.Slider {
  @apply relative;
}
</style>
