<template>
  <block
    :class="$style.ChatWindow"
    scrollContent
    noScrollBorder
    :light-header="highlight"
  >
    <template slot="header">
      <h2 :title="'Chat Window'"><span>Ch</span>at Window</h2>
    </template>
    <div :class="$style.Messages">
      <div :class="$style.ChatListCast">
        <template v-if="castMessages">
          <ul ref="cast">
            <template v-for="(item, i) in castMessages">
              <li
                :key="`message-${i}`"
                :class="{
                  [$style.Message]: true,
                  [$style.MessageCast]: item.role === 'Cast',
                }"
              >
                <span :class="$style.Time">
                  {{ formattedTime(item.time) }}
                </span>
                <span :class="$style.Name">{{ item.name }}</span>
                <span :class="$style.Text">{{ item.text }} </span>
              </li>
            </template>
          </ul>
        </template>
        <template v-else>
          <p :class="[$style.Placeholder, $style.PlaceholderCast]">
            INFO: You will see messages from characters here
          </p>
        </template>
      </div>
      <div :class="$style.ChatList">
        <template v-if="audienceMessages.length">
          <ul ref="messages">
            <li
              v-for="(item, i) in audienceMessages"
              :key="`message-${i}`"
              :class="{
                [$style.Message]: true,
                [$style.MessageCast]: item.role === 'Cast',
                [$style.MessageUser]: item.name === account.displayName,
              }"
            >
              <span :class="$style.Time">
                {{ formattedTime(item.time) }}
              </span>
              <span :class="$style.Name">{{ item.name }}</span>
              <span :class="$style.Text">{{ item.text }} </span>
            </li>
          </ul>
        </template>
        <template v-else>
          <p :class="$style.Placeholder">
            INFO: You will see messages from the audience here.
          </p>
        </template>
      </div>
    </div>
  </block>
</template>

<script>
import { mapActions, mapState } from "vuex";
import orderBy from "lodash/orderBy";
import format from "date-fns/format";
export default {
  name: "AudienceChatWindow",
  watch: {
    audienceMessages: {
      deep: true,
      handler(val) {
        if (this.$refs.messages && val.length) {
          const list = this.$refs.messages;
          const cast = this.$refs.cast;
          if (!list) return;
          this.$nextTick(() => {
            list.scrollTop = list.scrollHeight;
            cast.scrollTop = cast.scrollHeight;
          });
        }
      },
    },
    list(val) {
      console.log(val);
      if (
        val &&
        val.length &&
        val[val.length - 1].role &&
        val[val.length - 1].role === "Cast"
      ) {
        this.highlight = true;
        this.animateInterface();
      } else {
        this.highlight = false;
      }
    },
  },

  computed: {
    ...mapState(["account"]),
    ...mapState("performance/messages", ["list"]),
    audienceMessages() {
      const messages = this.list.filter(
        (message) => message.role === "Audience"
      );
      return messages && messages.length ? messages : false;
    },
    castMessages() {
      const messages = this.list.filter((message) => message.role === "Cast");
      return messages && messages.length ? messages : false;
    },
  },
  data() {
    return {
      interval: null,
      highlight: false,
      count: 0,
    };
  },
  mounted() {
    if (this.$refs.messages) {
      setTimeout(() => {
        const list = this.$refs.messages;
        list.scrollTop = list.scrollHeight;
      }, 500);
    }
  },
  methods: {
    ...mapActions("performance/messages", ["bindAllMessages"]),
    formattedTime(date) {
      const helperDate = date.toDate(date);
      return format(helperDate, "hh:mm:ss");
    },
    animateInterface() {
      this.interval = setInterval(() => {
        this.highlight = !this.highlight;
        this.count++;
        if (this.count >= 5) {
          this.interval = clearInterval(this.interval);
          this.count = 0;
        }
      }, 500);
    },
  },
};
</script>

<style module lang="postcss">
.ChatWindow {
  @apply flex flex-col relative;
}

.ChatList {
  @apply m-0 list-none;
}

.Messages {
  @apply flex flex-col gap-3 h-full relative pt-0;
}

.ChatList {
  @apply overflow-hidden flex-grow;
  & ul {
    @apply overflow-y-auto h-full;
    & li {
      @apply mb-1;
      &:last-child {
        @apply mb-0;
      }
    }
  }
}

.ChatListCast {
  @apply overflow-hidden flex-shrink-0 bottom-0 w-full h-2/6;
  @apply border-b border-solid border-yellow pt-2;
  & ul {
    @apply overflow-y-auto py-1 h-full;
    @apply mb-1;
    & li {
      @apply mb-1;
    }
    &:last-child {
      @apply mb-0;
    }
  }
}

.Message {
  @apply flex text-white leading-none gap-3;
  &:before {
    @apply hidden;
  }
  &:last-child {
    @apply border-0;
  }
}

.Time {
  @apply flex-shrink-0;
}

.Name {
  @apply flex-shrink-0 w-24;
}

.Text {
  @apply col-span-8;
}

.MessageCast {
  @apply text-yellow;
}

.MessageUser {
  @apply bg-white text-black;
}

.Placeholder {
}
.PlaceholderCast {
  @apply text-yellow;
}
</style>
