import Vue from "vue";
import VModal from "vue-js-modal";
import Vuelidate from "vuelidate";
import Toasted from "vue-toasted";
import TreeView from "vue-json-tree-view";
import { auth } from "@/firebase";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import Container from "@/components/Container";
import ShittyButton from "@/components/ShittyButton";
import ShittyLink from "@/components/ShittyLink";
import ShittySwitch from "@/components/ShittySwitch";
import OutputRecorded from "@/components/OutputRecorded";
import OutputLive from "@/components/OutputLive";
import Block from "@/components/Block";
import FormRow from "@/components/FormRow";
import TabGroup from "@/components/TabGroup";
import ProgressBar from "@/components/ProgressBar";
import FormError from "@/components/FormError";
import Loading from "@/components/Loading";
import FormLabel from "@/components/FormLabel";
import AudioPlayer from "@/components/AudioPlayer";

Vue.use(Toasted);
Vue.use(Vuelidate);
Vue.use(TreeView);

Vue.use(VModal, { dialog: true, styles: ["u-overlay"] });

Vue.component("audio-player", AudioPlayer);
Vue.component("form-label", FormLabel);
Vue.component("form-error", FormError);
Vue.component("container", Container);
Vue.component("shitty-link", ShittyLink);
Vue.component("shitty-button", ShittyButton);
Vue.component("shitty-switch", ShittySwitch);
Vue.component("output-recorded", OutputRecorded);
Vue.component("output-live", OutputLive);
Vue.component("block", Block);
Vue.component("form-row", FormRow);
Vue.component("tab-group", TabGroup);
Vue.component("progress-bar", ProgressBar);
Vue.component("loading", Loading);

Vue.config.productionTip = false;

const getCurrentUser = async () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdminAuth = to.matched.some(
    (record) => record.meta.requiresAdminAuth
  );
  if (requiresAuth) {
    // Get current user
    const user = await getCurrentUser();
    // User is unauthenticated. Go to login page
    if (!user) {
      next("/");
      return;
    }
    if (requiresAdminAuth && user.isAnonymous) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
