<template>
  <div :class="$style.Bar">
    <div :class="$style.Progress" :style="{ width: `${progress}%` }" />
  </div>
</template>

<script>
export default {
  name: "Progressbar",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style module lang="postcss">
.Bar {
  @apply w-full h-4 relative bg-white bg-opacity-30;
}
.Progress {
  @apply bg-yellow absolute top-0 left-0 h-full;
}
</style>
