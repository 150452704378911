<template>
  <div :class="{ [$style.VideoWrap]: true }">
    <slot name="timestamp" />
    <video
      ref="lobby"
      autoplay
      playsInline
      :controls="false"
      :class="$style.Video"
    />
    <screen-static />
    <div v-show="!connected && !connectionError" :class="$style.Loading">
      Connecting...
    </div>
    <div v-show="!connected && connectionError" :class="$style.Loading">
      Connection error!
    </div>
    <slot name="output" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ScreenStatic from "@/components/ScreenStatic";
export default {
  name: "LiveOutput",
  components: {
    ScreenStatic,
  },
  computed: {
    ...mapState("performance/liveStreams", ["streams"]),
    connected() {
      return this.stream?.connected;
    },
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      connectionError: false,
      interval: null,
      stream: false,
    };
  },
  watch: {
    uid(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.lobby) {
        this.stream = this.streams[newVal];
      }
    },
    stream(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.lobby) {
        this.$refs.lobby.srcObject = newVal.srcObject;
      }
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.stream = this.streams === undefined ? false : this.streams[this.uid];
      if (this.stream) this.interval = clearInterval(this.interval);
    }, 1000);
  },
};
</script>

<style module lang="postcss">
.VideoWrap {
  @apply relative w-full h-full bg-gray-800;
  & video {
    aspect-ratio: 4/3;
    @apply object-cover w-full h-full;
  }
}

.Loading {
  @apply absolute top-0 left-0 w-full h-full;
  @apply flex items-center justify-center;
}
</style>
