<template>
  <section
    :class="{
      [$style.Block]: true,
      [$style.Divider]: divider,
      [$style.Scroll]: scrollContent,
      [$style.NoScrollBorder]: noScrollBorder,
      [$style.Inactive]: inactive,
      [$style.Highlight]: highlight,
      [$style.NoPadding]: noPadding,
      [$style.Glitch]: glitch,
    }"
  >
    <block-header
      v-if="header"
      :divider="divider"
      :light-header="lightHeader"
      :highlight="flash"
      :class="{ [$style.Header]: true, [$style.PadTop]: padTop }"
    >
      <slot name="header" />
    </block-header>
    <div
      v-show="!hideContent"
      :class="{
        [$style.Content]: true,
        [$style.AutoContentHeight]: autoContentHeight,
      }"
    >
      <div v-if="scrollContent" :class="$style.ScrollContent">
        <slot />
      </div>
      <slot v-else />
    </div>
    <block-footer v-if="$slots.footer" :class="$style.Footer">
      <slot name="footer" />
    </block-footer>
  </section>
</template>

<script>
import BlockHeader from "@/components/BlockHeader";
import BlockFooter from "@/components/BlockFooter";
export default {
  name: "Block",
  props: {
    padTop: {
      type: Boolean,
      default: false,
    },
    flash: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: true,
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    scrollContent: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Boolean,
      default: false,
    },
    noScrollBorder: {
      type: Boolean,
      default: false,
    },
    lightHeader: {
      type: Boolean,
      default: false,
    },
    autoContentHeight: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BlockHeader,
    BlockFooter,
  },
  data() {
    return {
      glitch: false,
    };
  },
  mounted() {
    // this.getRandomGlitch();
  },
  methods: {
    getRandomGlitch() {
      const rand = Math.round(Math.random() * (5000 - 2000)) + 2000;
      setTimeout(() => {
        this.glitch = !this.glitch;
        this.getRandomGlitch();
      }, rand);
    },
  },
};
</script>

<style module lang="postcss">
@keyframes glitchy {
  0% {
    /* transform: translate(-1px, 1px); */
    opacity: 1;
  }
  10% {
    /* transform: translate(0, 0); */
    opacity: 1;
  }
  25% {
    /* transform: translate(-1px, -1px); */
    opacity: 0.2;
  }
  30% {
    /* transform: translate(0, 0); */
    opacity: 1;
  }
  50% {
    /* transform: translate(1px, 2px); */
    opacity: 0.6;
  }
  40% {
    /* transform: translate(0, 0); */
    opacity: 1;
  }
  75% {
    /* transform: translate(1px, -1px); */
    opacity: 0.8;
  }
  80% {
    /* transform: translate(0, 0); */
    opacity: 1;
  }
  100% {
    /* transform: translate(-1px, 1px); */
    opacity: 1;
  }
}
.Block {
  @apply border-none;
  @apply border border-solid border-keyline border-t-0 transform;
  @apply flex flex-col;
}

.Highlight {
  @apply border-amber-500;
}

.Divider {
  @apply border-none;
}

.Content {
  @apply relative text-sm p-2 flex flex-col flex-grow;
  & p {
    @apply mb-3;
    &:last-child {
      @apply mb-0;
    }
  }
  /* @apply h-full; */
}

.PadTop {
  @apply mt-3;
}

.Scroll {
  @apply overflow-hidden;
  & .Content {
    @apply overflow-hidden h-full p-0;
  }
  & .ScrollContent {
    @apply absolute overflow-y-auto w-full h-full border border-solid border-keyline p-2;
  }
}

.NoScrollBorder {
  & .ScrollContent {
    @apply border-none;
  }
}

.Inactive {
  @apply opacity-75;
}

.AutoContentHeight {
  @apply h-auto;
}

.NoPadding {
  & .Content {
    @apply p-0;
  }
}

.Glitch {
  animation: glitchy 0.4s ease reverse;
}
</style>
