<template>
  <form action="#" novalidate @submit.prevent="submit" :class="$style.Form">
    <block>
      <template slot="header"> Department Story </template>
      <slot />
      <form-row v-if="error !== false">
        <form-error> {{ error }} </form-error>
      </form-row>
      <form-row label="Ticket ID" :error="$v.ticketId.$error">
        <input
          v-model.trim.lazy="$v.ticketId.$model"
          type="text"
          placeholder="/enter your ticket ID/"
        />
        <form-error v-if="$v.$error && !$v.ticketId.required">
          This field is required
        </form-error>
      </form-row>
      <form-row
        label="display name"
        :error="$v.$error && !$v.displayName.required"
      >
        <input
          v-model.trim.lazy="$v.displayName.$model"
          type="text"
          placeholder="/set your display name/"
        />
        <form-error v-if="$v.$error && !$v.displayName.required">
          This field is required
        </form-error>
        <form-error v-if="$v.$error && !$v.ticketId.alphaNum">
          Your display name can only contain letters and numbers. No spaces or
          special characters are allowed
        </form-error>
      </form-row>
      <template slot="footer">
        <shitty-button type="submit">Enter Waiting room </shitty-button>
      </template>
    </block>
  </form>
</template>

<script>
import { mapActions } from "vuex";
import { getTicketId, getDisplayname } from "@/firebase";
import { required, alphaNum } from "vuelidate/lib/validators";

export default {
  name: "FormAudienceLogin",
  data() {
    return {
      ticketId: "",
      displayName: "",
      submitStatus: null,
      error: false,
    };
  },
  validations: {
    ticketId: {
      required,
    },
    displayName: {
      required,
      alphaNum,
    },
  },
  methods: {
    ...mapActions(["signInAnonymously", "accountUpdate"]),
    ...mapActions("performance/audience", [
      "validateTicketId",
      "updateAudience",
    ]),

    async submit() {
      try {
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.submitStatus = "ERROR";
        } else {
          // do your submit logic here
          this.submitStatus = "PENDING";

          // check if ticket UID exists in any of the performances
          const performances = await this.validateTicketId(this.ticketId);

          // UID does not exist. SHow error message
          if (!performances.length) {
            // TODO: show error dialog
            console.log("access denied");
            this.error = "This ticket ID is invalid";
            return;
          }

          // Ticket UID is assigned to more than 1 performance
          if (performances.length > 1) {
            this.error =
              "This ticket ID has beeen assigned to more than 1 performance";
            return;
          }
          // Combine display name and ticket ID to account for multiple ticket
          // purchased by single user

          const performance = performances[0];

          // Sign the user in
          await this.signInAnonymously(this.displayName);

          // Update the account store with display name
          await this.accountUpdate({
            displayName: this.displayName,
          });

          // Update the performance audience doc with users display name
          await this.updateAudience({
            performanceId: performance.performanceId,
            ticketId: this.ticketId,
            displayName: this.displayName,
          });

          this.$router
            .replace({
              name: "waiting-room",
              params: { id: performance.performanceId },
            })
            .catch(() => {});
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
<style module lang="postcss">
.Form {
  @apply w-96 mx-auto mt-6 text-left;
}
</style>
