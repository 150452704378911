<template>
  <div :class="$style.Wrap">
    <img :src="require('@/assets/static.gif')" />
  </div>
</template>

<script>
export default {
  name: "ScreenStaticDisabled",
};
</script>

<style module lang="postcss">
.Wrap {
  aspect-ratio: 4/3;
  @apply absolute top-0 left-0 w-full h-full bg-black;
  & img {
    @apply opacity-30 block object-cover w-full h-full;
  }
}
</style>
