import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";

const audio = {
  namespaced: true,
  state: {
    list: null,
  },
  actions: {
    createAudio: firestoreAction(({ rootGetters }, { performanceId, data }) => {
      return db
        .collection("performances")
        .doc(performanceId)
        .collection("audio")
        .doc()
        .set(data);
    }),
    updateAudio: firestoreAction(
      ({ rootGetters }, { performanceId, audioId, data }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("audio")
          .doc(audioId)
          .update(data);
      }
    ),
    deleteAudio: firestoreAction(
      ({ rootGetters }, { performanceId, audioId }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("audio")
          .doc(audioId)
          .delete();
      }
    ),
    bindAudio: firestoreAction(({ bindFirestoreRef }, performanceId) => {
      // Bind all performances to the state
      return bindFirestoreRef(
        "list",
        db.collection("performances").doc(performanceId).collection("audio")
      );
    }),
    unbindAudio: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("list");
    }),
  },
};

export default audio;
