<template>
  <section :class="$style.Wrap">
    <audio ref="player" :loop="loop" :id="playerid" :class="$style.Player">
      <source :src="url" type="audio/mpeg" />
    </audio>
    <shitty-button v-if="isPlaying" @click.native="togglePlayback()">
      <img :src="require('@/assets/icons/pause.svg')" />
    </shitty-button>
    <shitty-button v-if="!isPlaying" @click.native="togglePlayback()">
      <img :src="require('@/assets/icons/play.svg')" />
    </shitty-button>
    <div :class="$style.RangeSlider">
      <input
        v-model="playbackTime"
        type="range"
        min="0"
        :max="audioDuration"
        :class="$style.Slider"
        name="position"
        readonly
      />
      <div :class="$style.Timestamp">
        <span v-html="elapsedTime()"> 00:00 </span>
        /
        <span v-html="totalTime()"> 00:00 </span>
      </div>
    </div>
    <shitty-button v-if="muted" icon @click.native="muted = false">
      <img :src="require('@/assets/icons/muted.svg')" />
    </shitty-button>
    <shitty-button v-if="!muted" icon @click.native="muted = true">
      <img :src="require('@/assets/icons/unmuted.svg')" />
    </shitty-button>
  </section>
</template>

<script>
export default {
  name: "AudienceMasterSoundtrack",
  props: ["url", "playerid", "playback", "currentTime"],
  data() {
    return {
      playbackTime: 0,
      audioDuration: 100,
      audioLoaded: false,
      isPlaying: false,
      player: null,
      loop: true,
      muted: false,
    };
  },
  methods: {
    // Set the range slider max value equal to audio duration
    initSlider() {
      if (this.player) {
        this.audioDuration = Math.round(this.player.duration);
        if (this.playback) {
          this.player.currentTime = this.currentTime;
          this.player.play();
          this.isPlaying = true;
        } else {
          this.player.currentTime = this.currentTime;
          this.player.pause();
          this.isPlaying = false;
        }
      }
    },
    // Convert audio current time from seconds to min:sec display
    convertTime(seconds) {
      const format = (val) => `0${Math.floor(val)}`.slice(-2);
      // const hours = seconds / 3600;
      const minutes = (seconds % 3600) / 60;
      return [minutes, seconds % 60].map(format).join(":");
    },
    // Show the total duration of audio file
    totalTime() {
      return this.player ? this.convertTime(this.player.duration) : "00:00";
    },
    // Display the audio time elapsed so far
    elapsedTime() {
      return this.player ? this.convertTime(this.player.currentTime) : "00:00";
    },
    // Playback listener function runs every 100ms while audio is playing
    playbackListener() {
      // Sync local 'playbackTime' var to audio.currentTime and update global state
      this.playbackTime = this.player.currentTime;
      // Add listeners for audio pause and audio end events
      this.player.addEventListener("seeked", this.seekedListener);
      this.player.addEventListener("pause", this.pauseListener);
    },
    // Function to run when audio is paused by user
    async pauseListener() {
      this.isPlaying = false;
      this.listenerActive = false;
      this.cleanupListeners();
    },
    seekedListener() {
      this.$emit("seeked", this.playbackTime);
    },
    // Remove listeners after audio play stops
    cleanupListeners() {
      this.player.removeEventListener("timeupdate", this.playbackListener);
      this.player.removeEventListener("seeked", this.seekedListener);
      this.player.removeEventListener("pause", this.pauseListener);
    },
    // Toggle playback on CTA interaction
    togglePlayback() {
      if (!this.playback) {
        this.player.currentTime = this.currentTime;
        this.player.pause();
        this.isPlaying = false;
      } else {
        this.player.currentTime = this.currentTime;
        this.player.play();
        this.isPlaying = true;
      }
    },
    initPlayer() {
      // Wait for audio to load, then run initSlider() to get audio duration
      // and set the max value of our slider
      this.player.addEventListener(
        "loadedmetadata",
        function () {
          this.initSlider();
        }.bind(this)
      );

      // "canplay" HTML Event lets us know audio is ready for play
      this.player.addEventListener(
        "canplay",
        function () {
          this.audioLoaded = true;
        }.bind(this)
      );

      this.player.addEventListener(
        "seeked",
        function () {
          console.log("seeked");
        }.bind(this)
      );

      // Wait for audio to begin play, then start playback listener function
      this.$watch("playback", function () {
        if (this.playback) {
          this.initSlider();
          //prevent starting multiple listeners at the same time
          if (!this.listenerActive) {
            this.listenerActive = true;
            this.player.addEventListener("timeupdate", this.playbackListener);
          }
        }
        this.togglePlayback(this.playback);
      });

      // Update current audio position when user drags progress slider
      this.$watch("playbackTime", function () {
        const diff = Math.abs(this.playbackTime - this.player.currentTime);
        // Throttle synchronization to prevent infinite loop between playback listener and this watcher
        if (diff > 0.01) {
          this.player.currentTime = this.playbackTime;
        }
      });
      this.$watch("url", function (newVal) {
        this.player.src = newVal;
        if (this.isPlaying) {
          this.player.play();
        } else {
          this.playbackTime = 0;
          this.initSlider();
        }
      });
      this.$watch("muted", function (newVal) {
        this.player.muted = newVal;
      });
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.player = this.$refs.player;
      this.player.currentTime = this.currentTime;
      this.initPlayer();
    });
  },
};
</script>

<style lang="postcss">
input[type="range"] {
  @apply m-auto appearance-none relative  w-full cursor-pointer;
  @apply outline-none rounded-none bg-transparent;
}

input[type="range"]:focus {
  @apply outline-none;
}

::-webkit-slider-runnable-track {
  @apply bg-gray-500 w-full h-3 relative;
  @apply border-t-4 border-b-4 border-black border-solid;
}

::-webkit-slider-thumb {
  @apply appearance-none;
  @apply bg-white shadow-none w-1 h-5 border-none;
  @apply transform -translate-y-3 mt-1;
}

/* ::-moz-range-track {
  height: 40px;
  background: #ddd;
}

::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 0;
  border: none;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw dodgerblue;
  box-sizing: border-box;
} */

/*
::-ms-fill-lower {
  background: dodgerblue;
}

::-ms-thumb {
  background: #fff;
  border: 2px solid #999;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}

::-ms-ticks-after {
  display: none;
}

::-ms-ticks-before {
  display: none;
}

::-ms-track {
  background: #ddd;
  color: transparent;
  height: 40px;
  border: none;
}

::-ms-tooltip {
  display: none;
} */
</style>

<style module lang="postcss">
.Player {
  @apply hidden !important;
}

.Wrap {
  @apply fixed flex gap-3 top-0 left-0 w-0 h-0 overflow-hidden pointer-events-none;
}

.RangeSlider {
  @apply relative flex items-center gap-3 pointer-events-none;
}

.Timestamp {
  @apply flex pointer-events-none whitespace-nowrap;
}

.Slider {
  @apply relative;
}
</style>
