<template>
  <div :class="$style.Wrap" :style="{ opacity: opacity }">
    <img v-show="active === 2" :src="require('@/assets/noise/1.gif')" />
    <img v-show="active === 1" :src="require('@/assets/noise/2.gif')" />
    <img v-show="active === 3" :src="require('@/assets/noise/3.gif')" />
    <img v-show="active === 4" :src="require('@/assets/noise/4.gif')" />
  </div>
</template>

<script>
export default {
  name: "ScreenStatic",
  data() {
    return {
      active: 1,
      opacity: 0.02,
    };
  },
  mounted() {
    this.getRandomImage();
  },
  computed: {},
  methods: {
    getRandomImage() {
      const rand = Math.round(Math.random() * (5000 - 1000)) + 1000;
      setTimeout(() => {
        this.active = Math.round(Math.random() * (4 - 1) + 1);
        this.opacity = Math.random() * (0.02 - 0.01) + 0.01;
        this.getRandomImage();
      }, rand);
    },
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply absolute top-0 left-0 w-full h-full;
  @apply opacity-5 overflow-hidden pointer-events-none;
  & img {
    @apply block w-full h-full object-center object-cover;
  }
}
</style>
