<template>
  <section :class="$style.Grid">
    <block auto-content-height light-header :class="$style.Upload">
      <template slot="header">
        <h3>Upload a new audio track</h3>
      </template>
      <shitty-button @click.native="() => showUploadModal()">
        Upload audio file
      </shitty-button>
    </block>

    <ul v-if="tracks" :class="$style.Existing">
      <li
        v-for="(track, i) in tracks"
        :key="`track-${i}`"
        :class="$style.Stream"
      >
        <block light-header>
          <template slot="header">
            <h3>{{ track.label || "New Audio Track" }}</h3>
            <button @click="() => onDeleteAudio(track.id)">
              <img :src="require('@/assets/icons/delete.svg')" />
            </button>
          </template>

          <audio-player :url="track.url" :class="$style.Audio" />

          <form-row no-wrap label="Track URL">
            <input
              v-model="track.url"
              type="text"
              placeholder="Stream URL"
              readonly
            />
            <shitty-button icon @click.native="() => showUploadModal(track.id)">
              <img :src="require('@/assets/icons/upload.svg')" />
              <!-- <span class="sr-only">upload a different video</span> -->
            </shitty-button>
          </form-row>

          <form-row :label="'label'" no-wrap>
            <input
              v-model="track.label"
              type="text"
              placeholder="Set the stream label"
            />
            <shitty-button
              icon
              @click.native="
                () =>
                  onUpdateAudio({
                    audioId: track.id,
                    data: { label: track.label },
                  })
              "
            >
              <img :src="require('@/assets/icons/update.svg')" />
              <!-- <span class="sr-only">Update label</span> -->
            </shitty-button>
          </form-row>
        </block>
      </li>
    </ul>
    <modal
      :name="`upload-audio`"
      :width="'500px'"
      :height="'auto'"
      :adaptive="true"
      :classes="[$style.Modal]"
      :styles="['u-modal-overlay']"
      :clickToClose="true"
    >
      <form-upload-audio
        :audio-id="audioId"
        @upload="onUpload"
        @close="hideUploadModal"
      />
    </modal>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import AudioPlayer from "@/components/AudioPlayer";
import FormUploadAudio from "@/components/FormUploadAudio";

export default {
  name: "AdminAudio",
  components: { AudioPlayer, FormUploadAudio },
  props: {
    tracks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      audioId: false,
    };
  },
  methods: {
    ...mapActions("performance/audio", [
      "createAudio",
      "updateAudio",
      "deleteAudio",
    ]),
    async onUpload({ url, audioId, label }) {
      try {
        audioId
          ? await this.onUpdateAudio({ audioId, data: { url, label } })
          : await this.onCreateAudio({ url, label });
        this.$modal.hide(`upload-audio`);
      } catch (err) {
        console.error(err);
        this.$modal.hide(`upload-audio`);
      }
    },
    async onCreateAudio(data) {
      try {
        await this.createAudio({
          performanceId: this.$route.params.id,
          data,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onUpdateAudio({ audioId, data }) {
      try {
        await this.updateAudio({
          performanceId: this.$route.params.id,
          audioId,
          data,
        });
        this.$modal.show("dialog", {
          title: "Audio updated",
          buttons: [
            {
              title: "OK",
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
          ],
        });
      } catch (err) {
        console.error(err);
      }
    },
    onDeleteAudio(videoId) {
      this.$modal.show("dialog", {
        title: `Delete audio track`,
        text: "Are you sure you want to delete this audio file? This cannot be undone.",
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: "Delete",
            handler: async () => {
              try {
                await this.deleteAudio({
                  performanceId: this.$route.params.id,
                  videoId,
                });
                this.$modal.hide("dialog");
              } catch (err) {
                console.error(err);
                this.$modal.hide("dialog");
              }
            },
          },
        ],
      });
    },
    showUploadModal(audioId) {
      this.audioId = audioId;
      this.$modal.show(`upload-audio`);
    },
    hideUploadModal() {
      this.audioId = false;
      this.$modal.hide("upload-audio");
    },
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid grid-cols-8 gap-4;
}

.Upload {
  @apply col-span-2 row-span-1;
}

.Existing {
  @apply col-span-6 row-span-2 grid grid-cols-2 gap-3;
}

.Modal {
  @apply bg-black rounded-none;
}

.Audio {
  @apply mt-2 mb-3;
}
</style>
