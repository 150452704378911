<template>
  <div :class="{ [$style.PerformanceList]: true }">
    <block
      v-for="(item, i) in list"
      :key="`performance-${i}`"
      :light-header="true"
      auto-content-height
      :class="$style.Form"
    >
      <template slot="header">
        <h3 :class="$style.Title">{{ item.title }}</h3>
        <button @click="deletePerformance(item.id)">delete</button>
      </template>
      <dl class="u-dl">
        <dt>Title</dt>
        <dd>{{ item.title }}</dd>
        <dt>Location</dt>
        <dd>{{ item.location }}</dd>
        <dt>ID</dt>
        <dd>{{ item.id }}</dd>
      </dl>
      <template slot="footer">
        <nav :class="$style.EditToolbar">
          <shitty-link class="c-btn" :to="`/performance/admin/${item.id}`">
            Setup
          </shitty-link>
          <shitty-link
            v-if="setupComplete(item)"
            class="c-btn"
            :to="`/performance/admin/${item.id}/controls`"
          >
            Control
          </shitty-link>
        </nav>
      </template>
    </block>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PerformanceList",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("performance", ["delete"]),
    deletePerformance(id) {
      this.$modal.show("dialog", {
        title: "Delete Performance",
        text: "Are you sure you want to delete this performance? This action cannot be undone.",
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: "Delete",
            handler: async () => {
              try {
                await this.delete(id);
                this.$modal.hide("dialog");
              } catch (err) {
                console.error(err);
                this.$modal.hide("dialog");
              }
            },
          },
        ],
      });
    },
    setupComplete(performance) {
      return Object.values(performance.setup).every((item) => item);
    },
  },
};
</script>

<style module lang="postcss">
.PerformanceList {
  @apply grid grid-cols-2 gap-4;
}
.EditToolbar {
  @apply flex gap-3 w-full;
  & a {
    @apply flex-grow;
  }
}
</style>
