<template>
  <div
    :class="{
      [$style.Line]: true,
      [$style.Active]: isActiveInteraction && !activeInteraction.vote_failed,
      [$style.Inactive]:
        (activeInteraction && !isActiveInteraction) ||
        disabled ||
        (isActiveInteraction && activeInteraction.vote_failed),
      [$style.Voted]: voted,
      [$style.HasBeenVoted]: activeInteraction && activeInteraction.votes > 0,
      [$style.One]: index === 0,
      [$style.Two]: index === 1,
      [$style.Three]: index === 2,
      [$style.Four]: index === 3,
      [$style.Five]: index === 4,
      [$style.Six]: index === 5,
      [$style.Seven]: index === 6,
      [$style.Eight]: index === 7,
    }"
  >
    <span
      :class="{ [$style.Dash]: true, [$style.Active]: isActiveInteraction }"
    />
    <span :class="$style.Progress" :style="{ width: `${progress}%` }" />
  </div>
</template>

<script>
export default {
  name: "InteractionLine",
  props: {
    index: {
      type: Number,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    active: {
      type: [Boolean, String],
      default: false,
    },
    activeInteraction: {
      type: [Object, Boolean],
      default: () => {},
    },
    voted: {
      type: Boolean,
      default: false,
    },
    votePassed: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isActiveInteraction() {
      return this.activeInteraction && this.activeInteraction.id === this.id;
    },
    progress() {
      if (!this.activeInteraction || this.activeInteraction.id !== this.id)
        return 0;
      const progress =
        (this.activeInteraction.votes / this.activeInteraction.votes_required) *
        100;
      return progress >= 100 ? 100 : progress;
    },
  },
};
</script>

<style module lang="postcss">
.Line {
  @apply h-px max-h-px bg-keyline flex-grow-0 relative;
  margin-top: 3px;
  margin-bottom: 3px;
  &.Active {
    @apply bg-red-500;
  }
  &.Inactive {
    @apply opacity-30;
  }
}

.Dash {
  @apply w-px bg-keyline block absolute;
  height: 1rem;
}

.Active {
  & .Dash {
    @apply bg-red-500;
  }
  &.HasBeenVoted .Dash,
  &.Voted .Dash {
    @apply bg-yellow;
  }
}

.Progress {
  @apply absolute w-1/2 h-full bg-yellow;
  transition: width 0.5s;
}

.One,
.Four,
.Five,
.Eight {
  @apply w-1/2;
  & .Dash {
    height: 1.5rem;
  }
}

.Two,
.One {
  .Progress {
    @apply left-0;
  }
  & .Dash {
    @apply bottom-0 left-0;
  }
}

.Three,
.Four {
  .Progress {
    @apply right-0;
  }
  & .Dash {
    @apply bottom-0 right-0;
  }
}

.Five,
.Six {
  .Progress {
    @apply left-0;
  }
  & .Dash {
    @apply top-0 left-0;
  }
}

.Seven,
.Eight {
  .Progress {
    @apply right-0;
  }
  & .Dash {
    @apply top-0 right-0;
  }
}

.Two,
.Three,
.Six,
.Seven {
  @apply w-1/6;
}
</style>
