<template>
  <block lightHeader>
    <template slot="header">
      {{ heading }}
      <button @click="close">
        <img :src="require('@/assets/icons/close.svg')" />
      </button>
    </template>
    <form novalidate v-if="isInitial || isSaving" :class="$style.Form">
      <div :class="$style.Dropbox">
        <input
          type="file"
          :name="uploadFieldName"
          :disabled="isSaving"
          accept="audio/mp3"
          @change="
            fileChange($event.target.name, $event.target.files);
            fileCount = $event.target.files.length;
          "
          :class="$style.Input"
        />
        <p v-if="isInitial">
          Drag your file here to begin<br />
          or click to browse
        </p>
        <div v-if="isSaving" :class="$style.Progress">
          <p>Uploading video</p>
          <progress-bar :progress="this.progress" />
        </div>
      </div>
    </form>
    <div v-if="isSuccess" :class="$style.Success">
      <form-row :label="label">
        <audio-player :url="downloadUrl" :class="$style.Audio" />
      </form-row>
    </div>
    <form-row v-if="isSuccess" flex>
      <shitty-button full-width @click.native="save"> save </shitty-button>
      <shitty-button full-width @click.native="reset"> change </shitty-button>
    </form-row>
    <template slot="footer"> </template>
  </block>
</template>

<script>
import { storage } from "@/firebase";

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

export default {
  name: "FormUploadAudio",
  props: {
    audioId: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      heading: "Upload a new audio track",
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "audio",
      progress: 0,
      downloadUrl: null,
      label: "",
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  mounted() {
    this.reset();
  },

  methods: {
    reset() {
      // reset form to initial state
      this.heading = "Upload a new audio track";
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.progress = 0;
      this.downloadUrl = null;
      this.label = "";
    },
    async fileChange(fieldName, files) {
      if (!files.length) return;

      // Only one file at a time
      const file = files[0];

      // Create the file metadata
      const metadata = {
        contentType: "audio/mp3",
      };

      // Create a root reference
      const storageRef = await storage.ref();

      // Upload file and metadata
      const uploadTask = storageRef
        .child(`audio/${this.$route.params.id}/${file.name}`)
        .put(file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              this.currentStatus = STATUS_SAVING;
              this.progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              break;
          }
        },
        (error) => {
          this.currentStatus = STATUS_FAILED;
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              console.log(error.code);
              break;
            case "storage/canceled":
              // User canceled the upload
              console.log(error.code);
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              console.log(error.code);
              break;
          }
        },
        async () => {
          this.downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
          this.currentStatus = STATUS_SUCCESS;
          this.heading = "Upload complete";
          this.label = uploadTask.snapshot.ref.name;
        }
      );
    },
    save() {
      this.$emit("upload", {
        url: this.downloadUrl,
        label: this.label,
        audioId: this.audioId,
      });
      this.reset();
    },
    close() {
      this.reset();
      this.$emit("close");
    },
  },
};
</script>

<style module lang="postcss">
.Form {
  @apply w-full;
}

.Form {
  @apply h-24 flex items-center justify-center w-full;
}

.Dropbox {
  @apply relative cursor-pointer z-10 flex-grow;
  & p {
    @apply cursor-pointer text-center pointer-events-none relative z-20;
  }
}

.Input {
  @apply opacity-0 w-full h-full absolute cursor-pointer z-10;
}

.Progress {
  @apply px-6;
  & p {
    @apply block mb-2 leading-none;
  }
}

.Success {
  @apply mb-3 mt-1;
}

.Video {
  @apply object-cover w-full h-full;
  background: red;
  aspect-ratio: 4/3;
}
</style>
