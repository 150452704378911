<template>
  <div :class="$style.Error">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FormError",
};
</script>

<style module lang="postcss">
.Error {
  @apply text-red-600 mt-1 p-1 leading-none text-left;
}
</style>
