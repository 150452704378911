var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('block',{class:( _obj = {}, _obj[_vm.$style.Wrap] = true, _obj ),attrs:{"light-header":_vm.highlight}},[_c('template',{slot:"header"},[_c('h2',[_c('span',[_vm._v("Sy")]),_vm._v("stem")]),(_vm.activeInteraction.voting_open && !_vm.voted)?_c('div',[_vm._v(" Click button to unlock "+_vm._s(_vm.activeInteraction.label)+". "+_vm._s(_vm.activeInteraction.time_remaining >= 0 ? _vm.activeInteraction.time_remaining : 0)+"s remaining ")]):_vm._e(),(
        _vm.activeInteraction.voting_open &&
        _vm.voted &&
        !_vm.activeInteraction.vote_passed
      )?_c('div',[_vm._v(" Other people are deciding: "+_vm._s(_vm.activeInteraction.time_remaining)+"s ")]):_vm._e(),(!_vm.activeInteraction.vote_open && _vm.activeInteraction.vote_passed)?_c('div',[_vm._v(" This vote has passed ")]):_vm._e(),(_vm.activeInteraction && _vm.activeInteraction.vote_failed)?_c('div',[_vm._v(" This vote has not passed ")]):_vm._e()]),_c('nav',{class:_vm.$style.Controls},[_c('div',{class:_vm.$style.ControlSet},[_vm._l((_vm.list),function(item,i){
      var _obj;
return [(i < _vm.list.length / 2)?_c('div',{key:("control-" + i),class:_vm.$style.Control},[_c('shitty-button',{class:( _obj = {}, _obj[_vm.$style.Cta] = true, _obj[_vm.$style.CtaLocked] = item.locked, _obj[_vm.$style.CtaVoted] = _vm.voted && _vm.activeInteraction.id === item.id, _obj[_vm.$style.CtaVoteFailed] = _vm.activeInteraction.id === item.id && item.vote_failed, _obj[_vm.$style.CtaInactive] = (_vm.activeInteraction && _vm.activeInteraction.id !== item.id) ||
                item.used, _obj ),attrs:{"disabled":item.locked || item.used || _vm.voted || item.vote_failed},nativeOn:{"click":function($event){return _vm.vote.apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.label)+" ")])],1):_vm._e()]})],2),_c('div',{class:_vm.$style.ControlLines},[_c('div',{class:_vm.$style.ControlLineGroup},[_vm._l((_vm.list),function(item,i){return [(i === 1 || i === 2)?_c('interaction-line',{key:("interaction-line-" + i),attrs:{"index":i,"id":_vm.list[i].id,"active":_vm.votingOpen,"active-interaction":_vm.activeInteraction,"voted":_vm.votingOpen && _vm.voted && _vm.activeInteraction.id === item.id,"vote-passed":_vm.votePassed,"disabled":item.used}}):_vm._e()]})],2),_c('div',{class:_vm.$style.ControlLineGroup},[_vm._l((_vm.list),function(item,i){return [(i === 0 || i === 3)?_c('interaction-line',{key:("interaction-line-" + i),attrs:{"index":i,"id":_vm.list[i].id,"active":_vm.votingOpen,"active-interaction":_vm.activeInteraction,"voted":_vm.votingOpen && _vm.voted && _vm.activeInteraction.id === item.id,"vote-passed":_vm.votePassed,"disabled":item.used}}):_vm._e()]})],2),_c('icon-lock',{class:_vm.$style.Lock,attrs:{"voting-open":_vm.votingOpen,"vote-passed":_vm.votePassed,"voted":_vm.voted,"time":_vm.activeInteraction.time_remaining >= 0
            ? _vm.activeInteraction.time_remaining
            : 0 || false}}),_c('div',{class:_vm.$style.ControlLineGroup},[_vm._l((_vm.list),function(item,i){return [(i === 4 || i === 7)?_c('interaction-line',{key:("interaction-line-" + i),attrs:{"index":i,"id":_vm.list[i].id,"active":_vm.votingOpen,"active-interaction":_vm.activeInteraction,"voted":_vm.votingOpen && _vm.voted && _vm.activeInteraction.id === item.id,"vote-passed":_vm.votePassed,"disabled":item.used}}):_vm._e()]})],2),_c('div',{class:_vm.$style.ControlLineGroup},[_vm._l((_vm.list),function(item,i){return [(i === 5 || i === 6)?_c('interaction-line',{key:("interaction-line-" + i),attrs:{"index":i,"id":_vm.list[i].id,"active":_vm.votingOpen,"active-interaction":_vm.activeInteraction,"voted":_vm.votingOpen && _vm.voted && _vm.activeInteraction.id === item.id,"vote-passed":_vm.votePassed,"disabled":item.used}}):_vm._e()]})],2)],1),_c('div',{class:_vm.$style.ControlSet},[_vm._l((_vm.list),function(item,i){
            var _obj;
return [(i >= _vm.list.length / 2)?_c('div',{key:("control-" + i),class:_vm.$style.Control},[_c('shitty-button',{class:( _obj = {}, _obj[_vm.$style.Cta] = true, _obj[_vm.$style.CtaLocked] = item.locked, _obj[_vm.$style.CtaVoted] = _vm.voted && _vm.activeInteraction.id === item.id, _obj[_vm.$style.CtaVoteFailed] = _vm.activeInteraction.id === item.id && item.vote_failed, _obj[_vm.$style.CtaInactive] = (_vm.activeInteraction && _vm.activeInteraction.id !== item.id) ||
                item.used, _obj ),attrs:{"disabled":item.locked || item.used || _vm.voted || item.vote_failed},nativeOn:{"click":function($event){return _vm.vote.apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.label)+" ")])],1):_vm._e()]})],2)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }