<template>
  <div :class="$style.Logo">
    <svg
      v-show="active === 1"
      width="58"
      height="38"
      viewBox="0 0 58 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 19.12C10.08 38.19 28.84 37.23 28.84 37.23C28.84 37.23 47.67 38.23 57.78 19.12C47.73 0.0499878 29 0.999986 29 0.999986C29 0.999986 10.11 0.0499878 0 19.12Z"
        fill="#F4F4F4"
        :class="$style.Fill"
      />
      <path
        d="M2 19.1198C11.39 32.9998 28.85 32.2898 28.85 32.2898C28.85 32.2898 46.35 32.9898 55.76 19.1198C46.41 5.24982 29 5.99982 29 5.99982C29 5.99982 11.42 5.24982 2 19.1198Z"
        fill="black"
      />
      <path
        d="M18.9001 27.6698C23.6332 27.6698 27.4701 23.8329 27.4701 19.0998C27.4701 14.3667 23.6332 10.5298 18.9001 10.5298C14.167 10.5298 10.3301 14.3667 10.3301 19.0998C10.3301 23.8329 14.167 27.6698 18.9001 27.6698Z"
        fill="#F4F4F4"
        :class="$style.Fill"
      />
    </svg>
    <svg
      v-show="active === 2"
      width="58"
      height="38"
      viewBox="0 0 58 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 19.12C10.08 38.19 28.84 37.23 28.84 37.23C28.84 37.23 47.67 38.23 57.78 19.12C47.73 0.0499878 29 0.999986 29 0.999986C29 0.999986 10.11 0.0499878 0 19.12Z"
        fill="#F4F4F4"
        :class="$style.Fill"
      />
      <path
        d="M2 19.1198C11.39 32.9998 28.85 32.2898 28.85 32.2898C28.85 32.2898 46.35 32.9898 55.76 19.1198C46.41 5.24982 29 5.99982 29 5.99982C29 5.99982 11.42 5.24982 2 19.1198Z"
        fill="black"
      />
      <path
        d="M28.9001 27.6698C33.6332 27.6698 37.4701 23.8329 37.4701 19.0998C37.4701 14.3667 33.6332 10.5298 28.9001 10.5298C24.167 10.5298 20.3301 14.3667 20.3301 19.0998C20.3301 23.8329 24.167 27.6698 28.9001 27.6698Z"
        fill="#F4F4F4"
        :class="$style.Fill"
      />
    </svg>

    <svg
      v-show="active === 3"
      width="58"
      height="38"
      viewBox="0 0 58 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 19.12C10.08 38.19 28.84 37.23 28.84 37.23C28.84 37.23 47.67 38.23 57.78 19.12C47.73 0.0499878 29 0.999986 29 0.999986C29 0.999986 10.11 0.0499878 0 19.12Z"
        fill="#F4F4F4"
        :class="$style.Fill"
      />
      <path
        d="M2 19.1198C11.39 32.9998 28.85 32.2898 28.85 32.2898C28.85 32.2898 46.35 32.9898 55.76 19.1198C46.41 5.24982 29 5.99982 29 5.99982C29 5.99982 11.42 5.24982 2 19.1198Z"
        fill="black"
      />
      <path
        d="M38.9001 27.6698C43.6332 27.6698 47.4701 23.8329 47.4701 19.0998C47.4701 14.3667 43.6332 10.5298 38.9001 10.5298C34.167 10.5298 30.3301 14.3667 30.3301 19.0998C30.3301 23.8329 34.167 27.6698 38.9001 27.6698Z"
        fill="#F4F4F4"
        :class="$style.Fill"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Logo",
  data() {
    return {
      active: 1,
    };
  },
  mounted() {
    this.getRandomLogo();
  },
  computed: {},
  methods: {
    getRandomLogo() {
      const rand = Math.round(Math.random() * (20000 - 10000)) + 10000;
      setTimeout(() => {
        this.active = Math.round(Math.random() * (3 - 1) + 1);
        this.getRandomLogo();
      }, rand);
    },
  },
};
</script>

<style module lang="postcss">
.Logo {
  & svg {
    @apply h-6 block object-contain;
  }
}

.Fill {
  @apply fill-white;
}
</style>
