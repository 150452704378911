<template>
  <header
    v-if="loggedIn"
    :class="{
      [$style.AppHeader]: true,
    }"
  >
    <div :class="$style.Spinner">
      <img :src="require('@/assets/world.gif')" />
    </div>

    <div :class="$style.LogoWrap">
      <logo :class="$style.Logo" />
    </div>

    <nav :class="$style.Menu">
      <ul v-if="!loggedIn" :class="$style.Nav">
        <li>
          <router-link :to="'/'" :class="$style.Cta"> Sign In </router-link>
        </li>
        <!-- <li>
          <router-link :to="'/register'" :class="$style.Cta">
            Register
          </router-link>
        </li> -->
      </ul>
      <ul v-if="loggedIn && admin" :class="$style.Nav">
        <li>
          <router-link :to="'/dashboard'" :class="$style.Cta">
            Dashboard
          </router-link>
        </li>
        <li v-if="performanceId">
          <router-link
            :to="`/performance/admin/${performanceId}`"
            :class="$style.Cta"
          >
            Setup
          </router-link>
        </li>
        <li v-if="performanceId && setupComplete">
          <router-link
            :to="`/performance/admin/${performanceId}/controls`"
            :class="$style.Cta"
          >
            Controls
          </router-link>
        </li>
        <li>
          <button
            v-if="loggedIn"
            :class="[$style.Cta, $style.CtaSignOut]"
            @click="onSignOut"
          >
            Sign Out
          </button>
        </li>
      </ul>
      <ul v-if="loggedIn && !admin" :class="$style.Nav">
        <li>
          <button :class="$style.Cta" @click="onFullscreen">
            <!-- <img :src="require('@/assets/icons/fullscreen.svg')" /> -->
            Fullscreen
          </button>
        </li>
        <li>
          <button
            v-if="loggedIn"
            :class="[$style.Cta, $style.CtaSignOut]"
            @click="onSignOut"
          >
            Sign Out
          </button>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Logo from "@/components/Logo";
export default {
  name: "AppHeader",
  components: {
    Logo,
  },
  data() {
    return {
      menuActive: true,
    };
  },
  computed: {
    ...mapGetters(["admin", "loggedIn"]),
    ...mapGetters("performance", ["setupComplete"]),
    ...mapState(["account"]),
    performanceId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions(["signOut"]),
    ...mapActions("performance/audience", ["updateAudience"]),
    async onSignOut() {
      this.$modal.show("dialog", {
        title: "Are you sure you want to sign out?",
        text: "You can always sign back in again using the same details as before.",
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: "Sign out",
            handler: async () => {
              try {
                await this.signOut();
                this.$router
                  .replace({
                    name: "login",
                  })
                  .catch(() => {});
                this.$modal.hide("dialog");
              } catch (err) {
                console.error(err);
              }
            },
          },
        ],
      });
    },
    onFullscreen() {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else {
        console.error("no full screen available");
      }
    },
  },
};
</script>

<style module lang="postcss">
.AppHeader {
  @apply flex justify-between items-center px-3;
  @apply border border-solid border-white bg-black;
}

.AppHeaderDark {
  & .Logo {
    @apply self-end;
  }
}
.LogoWrap {
  @apply flex items-center gap-4;
}

.Logo {
  @apply absolute left-1/2 transform top-1/2 -translate-y-1/2 -translate-x-1/2;
  & img {
    @apply block h-4;
  }
}

.Nav {
  @apply m-0 flex gap-4 items-center;
  & li {
    & a,
    & button {
      @apply block uppercase;
    }
    &:before {
      @apply hidden;
    }
  }
}

.Cta {
  @apply relative flex items-center gap-1 p-0 opacity-90;
  @apply hover:opacity-100 hover:underline;
  &:global(.router-link-exact-active) {
    @apply underline opacity-100;
  }
}

.NavLabel {
  @apply whitespace-nowrap uppercase text-black text-sm;
  @apply items-center cursor-pointer;
  @apply hover:underline;
}

.Spinner {
  @apply w-6 h-6 rounded-full border border-black;
  & img {
    @apply block w-full h-full transform scale-110;
  }
}
</style>
