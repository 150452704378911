<template>
  <container outer>
    <template v-if="current && ready">
      <tab-group :setup-complete="setupComplete" :pad-bottom="!setupComplete">
        <template slot="tabs">
          <button
            v-for="(tab, i) in tabs"
            :key="`tab-cta-${i}`"
            :disabled="current.setup[tab.id] === false"
            :class="{
              ['c-tab-cta']: true,
              ['c-tab-cta--active']: activeTab === tab.id,
            }"
            @click="changeTab(tab.id)"
          >
            {{ tab.label }}
          </button>
        </template>

        <template slot="title">
          <h2>{{ current.title }} / {{ current.location }}</h2>
        </template>

        <!--  -->
        <template v-if="activeTab === 'performance'">
          <admin-performance
            :performance="current"
            :class="$style.GeneralInfo"
          />
          <div v-if="!setupComplete" :class="$style.TabItemFooter">
            <shitty-button
              success
              @click.native="onUpdateSetupProgress('live-streams')"
            >
              Continue setup
            </shitty-button>
          </div>
        </template>

        <!-- Live Stream admin -->
        <template v-if="activeTab === 'live-streams'">
          <admin-live-streams :streams="liveStreams" />
          <div v-if="!setupComplete" :class="$style.TabItemFooter">
            <shitty-button
              success
              @click.native="onUpdateSetupProgress('recorded-videos')"
            >
              Continue setup
            </shitty-button>
          </div>
        </template>

        <!-- Recorded Videos admin -->
        <template v-if="activeTab === 'recorded-videos'">
          <admin-recorded-videos :videos="recordedVideos" />
          <div v-if="!setupComplete" :class="$style.TabItemFooter">
            <shitty-button
              success
              @click.native="onUpdateSetupProgress('audio')"
            >
              Continue setup
            </shitty-button>
          </div>
        </template>

        <!-- Audio admin -->
        <template v-if="activeTab === 'audio'">
          <admin-audio :tracks="tracks" />
          <div v-if="!setupComplete" :class="$style.TabItemFooter">
            <shitty-button
              success
              @click.native="onUpdateSetupProgress('outputs')"
            >
              Continue setup
            </shitty-button>
          </div>
        </template>

        <!-- Outputs admin -->
        <template v-if="activeTab === 'outputs'">
          <admin-outputs :outputs="outputs" />
          <div v-if="!setupComplete" :class="$style.TabItemFooter">
            <shitty-button
              success
              @click.native="onUpdateSetupProgress('panels')"
            >
              Continue setup
            </shitty-button>
          </div>
        </template>

        <!-- Panels admin -->
        <template v-if="activeTab === 'panels'">
          <admin-panels :outputs="outputs" />
          <div v-if="!setupComplete" :class="$style.TabItemFooter">
            <shitty-button
              success
              @click.native="onUpdateSetupProgress('lobby')"
            >
              Continue setup
            </shitty-button>
          </div>
        </template>

        <!-- Lobby admin -->
        <template v-if="activeTab === 'lobby'">
          <admin-lobby
            :background-video="lobby.background_video"
            :active-feed="lobby.active_feed"
            :text="copy.waiting_room_text"
            :loginText="copy.login_text"
            :endedText="copy.ended_text"
          />
          <div v-if="!setupComplete" :class="$style.TabItemFooter">
            <shitty-button
              success
              @click.native="onUpdateSetupProgress('interactions')"
            >
              Continue setup
            </shitty-button>
          </div>
        </template>

        <!-- Interactions admin -->
        <template v-if="activeTab === 'interactions'">
          <ul :class="[$style.List, $style.ListInteractions]">
            <li
              v-for="(interaction, interactionIndex) in interactions"
              :key="`interaction-${interactionIndex}`"
            >
              <admin-interaction
                :interaction="interaction"
                :index="interactionIndex"
                @update="onUpdateInteraction"
              />
            </li>
          </ul>
        </template>
      </tab-group>
    </template>
    <loading v-else />
  </container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AdminPerformance from "@/components/admin/AdminPerformance.vue";
import AdminInteraction from "@/components/admin/AdminInteraction.vue";
import AdminPanels from "@/components/admin/AdminPanels.vue";
import AdminLiveStreams from "@/components/admin/AdminLiveStreams.vue";
import AdminRecordedVideos from "@/components/admin/AdminRecordedVideos.vue";
import AdminAudio from "@/components/admin/AdminAudio.vue";
import AdminLobby from "@/components/admin/AdminLobby.vue";
import AdminOutputs from "@/components/admin/AdminOutputs.vue";

export default {
  name: "Setup",
  components: {
    AdminPerformance,
    AdminInteraction,
    AdminPanels,
    AdminLiveStreams,
    AdminRecordedVideos,
    AdminAudio,
    AdminLobby,
    AdminOutputs,
  },
  computed: {
    ...mapState(["copy"]),
    ...mapState("performance", ["current"]),
    ...mapState("performance/interactions", { interactions: "list" }),
    ...mapState("performance/liveStreams", { liveStreams: "list" }),
    ...mapState("performance/recordedVideos", { recordedVideos: "list" }),
    ...mapState("performance/outputs", { outputs: "list" }),
    ...mapState("performance/panels", { panels: "list" }),
    ...mapState("performance/audio", { tracks: "list" }),
    ...mapState("performance/lobby", { lobby: "lobby" }),
    setupComplete() {
      for (var o in this.current.setup)
        if (!this.current.setup[o]) return false;
      return true;
    },
  },
  data() {
    return {
      tabs: [
        {
          label: "Performance",
          id: "performance",
        },
        {
          label: "Live Streams",
          id: "live-streams",
        },
        {
          label: "Recorded Videos",
          id: "recorded-videos",
        },
        {
          label: "Audio",
          id: "audio",
        },
        {
          label: "Outputs",
          id: "outputs",
        },
        {
          label: "Panels",
          id: "panels",
        },
        {
          label: "Lobby",
          id: "lobby",
        },
        {
          label: "Interactions",
          id: "interactions",
        },
      ],
      activeTab: "performance",
      ready: false,
    };
  },
  async mounted() {
    try {
      await this.bind(this.$route.params.id);
      await this.bindInteractions(this.$route.params.id);
      await this.bindLiveStreams(this.$route.params.id);
      await this.bindRecordedVideos(this.$route.params.id);
      await this.bindAudience(this.$route.params.id);
      await this.bindOutputs(this.$route.params.id);
      await this.bindPanels(this.$route.params.id);
      await this.bindAudio(this.$route.params.id);
      await this.bindLobby(this.$route.params.id);
      await this.initStreams();
      this.ready = true;
    } catch (err) {
      console.error(err);
    }
  },

  methods: {
    ...mapActions("performance", ["update", "unbind", "bind"]),
    ...mapActions("performance/interactions", [
      "bindInteractions",
      "updateInteraction",
    ]),
    ...mapActions("performance/liveStreams", [
      "bindLiveStreams",
      "initStreams",
    ]),
    ...mapActions("performance/recordedVideos", ["bindRecordedVideos"]),
    ...mapActions("performance/outputs", ["bindOutputs"]),
    ...mapActions("performance/panels", ["bindPanels"]),
    ...mapActions("performance/audio", ["bindAudio"]),
    ...mapActions("performance/lobby", ["bindLobby"]),
    ...mapActions("performance/audience", ["bindAudience"]),
    changeTab(tab) {
      this.activeTab = tab;
    },
    async onUpdateSetupProgress(tab) {
      this.current.setup[tab] = true;
      try {
        await this.update({
          id: this.$route.params.id,
          data: { setup: this.current.setup },
        });
        this.activeTab = tab;
      } catch (err) {
        console.error(err);
      }
    },

    async onUpdateInteraction({ interactionId, data }) {
      try {
        await this.updateInteraction({
          performanceId: this.$route.params.id,
          interactionId,
          data,
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style module lang="postcss">
.Header {
  @apply flex items-center justify-between;
  & h2 {
    @apply font-bold;
  }
}

.TabItemFooter {
  @apply fixed bottom-0 left-2 right-2 p-4  border-solid border-t border-white bg-black;
  @apply flex justify-end;
}

.TabBar {
  @apply flex items-end gap-0;
}

.Grid {
  @apply grid gap-4;
}

.Description {
  @apply mb-3;
}

.ListPanels {
  @apply grid grid-cols-2 gap-4;
}

.ListInteractions {
  @apply grid grid-cols-4 gap-4;
}
</style>
