<template>
  <block
    :class="{
      [$style.Wrap]: true,
    }"
    :light-header="highlight"
  >
    <template slot="header">
      <h2><span>Sy</span>stem</h2>
      <div v-if="activeInteraction.voting_open && !voted">
        Click button to unlock {{ activeInteraction.label }}.
        {{
          activeInteraction.time_remaining >= 0
            ? activeInteraction.time_remaining
            : 0
        }}s remaining
      </div>
      <div
        v-if="
          activeInteraction.voting_open &&
          voted &&
          !activeInteraction.vote_passed
        "
      >
        Other people are deciding: {{ activeInteraction.time_remaining }}s
      </div>
      <div v-if="!activeInteraction.vote_open && activeInteraction.vote_passed">
        This vote has passed
      </div>
      <div v-if="activeInteraction && activeInteraction.vote_failed">
        This vote has not passed
      </div>
    </template>
    <nav :class="$style.Controls">
      <div :class="$style.ControlSet">
        <template v-for="(item, i) in list">
          <div
            v-if="i < list.length / 2"
            :key="`control-${i}`"
            :class="$style.Control"
          >
            <shitty-button
              :class="{
                [$style.Cta]: true,
                [$style.CtaLocked]: item.locked,
                [$style.CtaVoted]: voted && activeInteraction.id === item.id,
                [$style.CtaVoteFailed]:
                  activeInteraction.id === item.id && item.vote_failed,
                [$style.CtaInactive]:
                  (activeInteraction && activeInteraction.id !== item.id) ||
                  item.used,
              }"
              :disabled="item.locked || item.used || voted || item.vote_failed"
              @click.native="vote"
            >
              {{ item.label }}
            </shitty-button>
          </div>
        </template>
      </div>
      <div :class="$style.ControlLines">
        <div :class="$style.ControlLineGroup">
          <template v-for="(item, i) in list">
            <interaction-line
              v-if="i === 1 || i === 2"
              :key="`interaction-line-${i}`"
              :index="i"
              :id="list[i].id"
              :active="votingOpen"
              :active-interaction="activeInteraction"
              :voted="votingOpen && voted && activeInteraction.id === item.id"
              :vote-passed="votePassed"
              :disabled="item.used"
            />
          </template>
        </div>
        <div :class="$style.ControlLineGroup">
          <template v-for="(item, i) in list">
            <interaction-line
              v-if="i === 0 || i === 3"
              :key="`interaction-line-${i}`"
              :index="i"
              :id="list[i].id"
              :active="votingOpen"
              :active-interaction="activeInteraction"
              :voted="votingOpen && voted && activeInteraction.id === item.id"
              :vote-passed="votePassed"
              :disabled="item.used"
            />
          </template>
        </div>
        <icon-lock
          :voting-open="votingOpen"
          :vote-passed="votePassed"
          :voted="voted"
          :time="
            activeInteraction.time_remaining >= 0
              ? activeInteraction.time_remaining
              : 0 || false
          "
          :class="$style.Lock"
        />
        <div :class="$style.ControlLineGroup">
          <template v-for="(item, i) in list">
            <interaction-line
              v-if="i === 4 || i === 7"
              :key="`interaction-line-${i}`"
              :index="i"
              :id="list[i].id"
              :active="votingOpen"
              :active-interaction="activeInteraction"
              :voted="votingOpen && voted && activeInteraction.id === item.id"
              :vote-passed="votePassed"
              :disabled="item.used"
            />
          </template>
        </div>
        <div :class="$style.ControlLineGroup">
          <template v-for="(item, i) in list">
            <interaction-line
              v-if="i === 5 || i === 6"
              :key="`interaction-line-${i}`"
              :index="i"
              :id="list[i].id"
              :active="votingOpen"
              :active-interaction="activeInteraction"
              :voted="votingOpen && voted && activeInteraction.id === item.id"
              :vote-passed="votePassed"
              :disabled="item.used"
            />
          </template>
        </div>
      </div>
      <div :class="$style.ControlSet">
        <template v-for="(item, i) in list">
          <div
            v-if="i >= list.length / 2"
            :key="`control-${i}`"
            :class="$style.Control"
          >
            <shitty-button
              :class="{
                [$style.Cta]: true,
                [$style.CtaLocked]: item.locked,
                [$style.CtaVoted]: voted && activeInteraction.id === item.id,
                [$style.CtaVoteFailed]:
                  activeInteraction.id === item.id && item.vote_failed,
                [$style.CtaInactive]:
                  (activeInteraction && activeInteraction.id !== item.id) ||
                  item.used,
              }"
              :disabled="item.locked || item.used || voted || item.vote_failed"
              @click.native="vote"
            >
              {{ item.label }}
            </shitty-button>
          </div>
        </template>
      </div>
    </nav>
  </block>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InteractionLine from "@/components/InteractionLine";
import IconLock from "@/components/IconLock";
export default {
  name: "AudienceInteractions",
  props: {},
  components: {
    InteractionLine,
    IconLock,
  },
  computed: {
    ...mapState(["account"]),
    ...mapState("performance/interactions", ["list"]),
    activeInteraction() {
      if (!this.list) return false;
      const activeInteraction = this.list.find((item) => {
        return !item.locked;
      });
      return activeInteraction || false;
    },
    votingOpen() {
      if (!this.activeInteraction) return false;
      return this.activeInteraction.voting_open;
    },
    voted() {
      const votes = this.activeInteraction.voters;
      if (!votes || !votes.length) return false;
      return votes.includes(this.account.uid);
    },
    votePassed() {
      if (!this.activeInteraction) return false;
      return (
        this.activeInteraction.votes >= this.activeInteraction.votes_required
      );
    },
  },
  watch: {
    votingOpen(val) {
      if (val) {
        this.highlight = true;
        this.animateInterface();
      } else {
        this.highlight = false;
      }
    },
  },
  data() {
    return {
      interval: null,
      highlight: false,
      count: 0,
    };
  },
  methods: {
    ...mapActions("performance/interactions", ["voteInteraction"]),
    async vote() {
      await this.voteInteraction({
        performanceId: this.$route.params.id,
        interactionId: this.activeInteraction.id,
        userId: this.account.uid,
      });
    },
    animateInterface() {
      this.interval = setInterval(() => {
        this.highlight = !this.highlight;
        this.count++;
        if (this.count >= 5) {
          this.interval = clearInterval(this.interval);
          this.count = 0;
          this.highlight = false;
        }
      }, 500);
    },
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply flex flex-col relative;
}

.Controls {
  @apply flex flex-col gap-3 flex-grow items-center justify-center;
}

.Control {
  @apply flex justify-center items-center;
}

.ControlSet {
  @apply grid grid-cols-4 w-full gap-3 relative z-20;
}

.Cta {
  @apply px-3 uppercase;
}

.CtaLocked {
  @apply border-none bg-transparent;
  @apply opacity-100 !important;
}

.CtaInactive {
  @apply border-none bg-transparent;
  @apply opacity-30 !important;
}

.CtaVoted {
  @apply border-none bg-transparent text-yellow;
  @apply opacity-100 !important;
}

.CtaVoteFailed {
  @apply border-none bg-transparent text-red-500;
  @apply opacity-100 !important;
}

.ControlLines {
  @apply flex flex-col w-9/12 relative pointer-events-none;
  height: 1.8rem;
}

.ControlLineGroup {
  @apply flex justify-center;
}

.Lock {
  @apply -mt-2;
}

.VoteReadout {
}
</style>
