<template>
  <container>
    <form-login @form-submit="(user_id) => onLogIn(user_id)" />
  </container>
</template>
<script>
import FormLogin from "@/components/FormLogin.vue";

export default {
  name: "login",
  components: {
    FormLogin,
  },
  methods: {
    async onLogIn() {
      try {
        this.$router.replace({ name: "dashboard" }).catch(() => {});
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
