import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";

const panels = {
  namespaced: true,
  state: {
    list: null,
  },
  actions: {
    createPanel: firestoreAction(({ rootGetters }, { performanceId, data }) => {
      const uid = data.label.replace(/\s+/g, "-").toLowerCase();
      return db
        .collection("performances")
        .doc(performanceId)
        .collection("panels")
        .doc(uid)
        .set(data);
    }),
    activatePanel: firestoreAction(
      ({ rootGetters }, { performanceId, panelId, data }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("panels")
          .doc(panelId)
          .update(data);
      }
    ),
    updatePanel: firestoreAction(
      ({ rootGetters }, { performanceId, panelId, data }) => {
        const outputs = data.outputs;
        async function getOutputRefs() {
          const outputRefs = [];
          for (let i = 0; i < outputs.length; i++) {
            const ref = await db
              .collection("performances")
              .doc(performanceId)
              .collection("outputs")
              .doc(outputs[i].id);
            outputRefs.push(ref);
          }
          return outputRefs;
        }

        getOutputRefs().then((refs) => {
          data.outputs = refs;
          return db
            .collection("performances")
            .doc(performanceId)
            .collection("panels")
            .doc(panelId)
            .update(data);
        });
      }
    ),
    bindPanels: firestoreAction(({ bindFirestoreRef }, performanceId) => {
      // Bind all performances to the state
      return bindFirestoreRef(
        "list",
        db.collection("performances").doc(performanceId).collection("panels")
      );
    }),
    unbindAllPanels: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("list");
    }),
  },
};

export default panels;
