<template>
  <block
    no-padding
    :class="{ [$style.ChatTerminal]: true, [$style.Disabled]: chatDisabled }"
  >
    <template slot="header">
      <h2><span>Ch</span>at Terminal</h2>
    </template>
    <textarea
      v-model="message"
      :placeholder="
        chatDisabled
          ? `/chat has been temporarily disabled/`
          : `/type message here/`
      "
      :class="$style.ChatInput"
    />
    <template slot="footer">
      <div :class="$style.ChatControls">
        <shitty-button :disabled="chatDisabled" @click.native="onSendMessage">
          Send
        </shitty-button>
        <shitty-button :disabled="chatDisabled" @click.native="onCancel">
          Cancel
        </shitty-button>
      </div>
    </template>
  </block>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AudienceChatTerminal",
  props: {
    chatDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      role: "role",
      displayName: "displayName",
    }),
  },
  data() {
    return {
      message: "",
    };
  },
  methods: {
    ...mapActions("performance/messages", ["createMessage"]),
    async onSendMessage() {
      if (this.message === "") return;
      try {
        const date = new Date();
        await this.createMessage({
          performanceId: this.$route.params.id,
          text: this.message,
          role: this.role,
          displayName: this.displayName,
          time: date,
        });
        this.message = "";
      } catch (err) {
        console.error(err);
      }
    },
    onCancel() {
      this.message = "";
    },
  },
};
</script>

<style module lang="postcss">
.ChatTerminal {
  @apply flex flex-col;
  &.Disabled {
    /* @apply opacity-80; */
  }
}

.ChatInput {
  @apply w-full border-none p-0 bg-transparent flex-grow resize-none h-full;
}

.ChatControls {
  @apply flex gap-3 w-full;
  & button {
    @apply flex-grow w-1/2;
  }
}
</style>
