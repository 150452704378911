<template>
  <header
    :class="{
      [$style.Header]: true,
      [$style.Light]: lightHeader,
      [$style.Divider]: divider,
      [$style.Highlight]: highlight,
    }"
  >
    <slot />
  </header>
</template>

<script>
export default {
  name: "BlockHeader",
  props: {
    divider: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    lightHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style module lang="postcss">
.Header {
  @apply text-sm px-2 py-1 flex justify-between items-center font-semibold;
  @apply border-t border-b border-solid border-white;
  @apply bg-black text-white;
  & span {
    @apply underline;
  }
}

.Light {
  @apply border border-solid border-white;
  @apply bg-white text-black;
  & span {
    @apply underline;
  }
}
.Divider {
  @apply bg-transparent text-white;
}

.Highlight {
  @apply border-amber-500 bg-amber-500;
}
</style>
