<template>
  <block light-header auto-content-height>
    <template slot="header">New Performance</template>
    <form v-if="form" action="#" @submit.prevent="() => {}" class="text-center">
      <form-row label="Title">
        <input v-model="form.title" type="text" placeholder="Title" />
      </form-row>
      <form-row label="location">
        <input v-model="form.location" type="text" placeholder="Location" />
      </form-row>
    </form>
    <template slot="footer">
      <shitty-button @click.native="submit()" class="w-full">
        Create Performance
      </shitty-button>
    </template>
  </block>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FormPerformance",
  props: {},
  data() {
    return {
      form: false,
      error: null,
      defaultPerformance: null,
      defaultOutput: null,
      defaultInteractions: null,
      defaultPanel: null,
      defaultAudio: null,
      defaultLiveStreams: null,
      defaultLobby: null,
    };
  },
  async mounted() {
    this.defaultPerformance = await this.getDefaultPerformance();
    this.defaultOutput = await this.getDefaultOutput();
    this.defaultPanel = await this.getDefaultPanel();
    this.defaultInteractions = await this.getDefaultInteractions();
    this.defaultAudio = await this.getDefaultAudio();
    this.defaultLiveStreams = await this.getDefaultLiveStreams();
    this.defaultLobby = await this.getDefaultLobby();
    this.form = Object.assign({}, this.defaultPerformance);
  },
  methods: {
    ...mapActions("performance", [
      "getDefaultOutput",
      "getDefaultPerformance",
      "getDefaultInteractions",
      "getDefaultAudio",
      "getDefaultLiveStreams",
      "getDefaultPanel",
      "getDefaultLobby",
      "create",
    ]),
    ...mapActions("performance/interactions", ["createInteraction"]),
    ...mapActions("performance/outputs", ["createOutput"]),
    ...mapActions("performance/liveStreams", ["createLiveStream"]),
    ...mapActions("performance/panels", ["createPanel"]),
    ...mapActions("performance/lobby", ["createLobby"]),
    async submit() {
      // Do the thing
      try {
        // Create a performance and get the UID.
        const performanceId = await this.create(Object.assign({}, this.form));

        await this.createLobby({
          performanceId,
          data: Object.assign({}, this.defaultLobby),
        });

        // Add 8 Interaction docs to the new performance.
        // Interactions are stored in a subcollection.
        const interactions = this.defaultInteractions.initial_values;
        for (let index = 0; index < interactions.length; index++) {
          await this.createInteraction({
            performanceId,
            data: Object.assign({}, interactions[index]),
          });
        }

        // Add 6 Live Stream docs to the new performance.
        // Live Streams are stored in a subcollection.
        const liveStreams = this.defaultLiveStreams.initial_values;
        for (let index = 0; index < liveStreams.length; index++) {
          await this.createLiveStream({
            performanceId,
            data: Object.assign({}, liveStreams[index]),
          });
        }

        // Add 6 Panel docs to the new performance.
        // panels are stored in a subcollection
        for (let index = 0; index < 6; index++) {
          const panel = Object.assign({}, this.defaultPanel);
          panel.label = `Panel ${index + 1}`;
          await this.createPanel({
            performanceId,
            data: panel,
          });
        }
        // Add 12 Output docs to the new performance.
        // Outputs are stored in a subcollection
        console.log(this.defaultOutput);
        for (let index = 0; index < 12; index++) {
          const output = Object.assign({}, this.defaultOutput);
          output.label = `Output ${index + 1}`;
          output.index = index + 1;
          console.log(output);
          await this.createOutput({
            performanceId,
            data: output,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style module lang="postcss">
.Grid {
}
</style>
