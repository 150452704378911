<template>
  <form action="#" novalidate @submit.prevent="submit" :class="$style.Form">
    <block>
      <template slot="header"> Sign In </template>
      <form-row v-if="error !== false">
        <form-error> {{ error }} </form-error>
      </form-row>
      <form-row label="email" :error="$v.email.$error">
        <input
          v-model.trim.lazy="$v.email.$model"
          type="email"
          placeholder="/email address/"
        />
        <form-error v-if="$v.$error && !$v.email.required">
          This field is required
        </form-error>
        <form-error v-if="$v.$error && !$v.email.email">
          Please enter a valid email address
        </form-error>
      </form-row>

      <form-row label="password" :error="$v.$error && !$v.password.required">
        <input
          v-model.trim.lazy="$v.password.$model"
          type="password"
          placeholder="/password/"
        />
        <form-error v-if="$v.$error && !$v.password.required">
          This field is required
        </form-error>
      </form-row>
      <template slot="footer">
        <shitty-button type="submit"> Sign in </shitty-button>
      </template>
    </block>
  </form>
</template>

<script>
import { auth } from "@/firebase";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "FormLogin",
  data() {
    return {
      email: "",
      password: "",
      submitStatus: null,
      error: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },

  methods: {
    async submit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.submitStatus = "ERROR";
        } else {
          // do your submit logic here
          this.submitStatus = "PENDING";
          await auth.signInWithEmailAndPassword(this.email, this.password);
          this.$router.replace({ name: "dashboard" }).catch(() => {});
        }
      } catch (err) {
        this.submitStatus = "ERROR";
        this.error = err.message;
      }
    },
  },
};
</script>

<style module lang="postcss">
.Form {
  @apply w-96 mx-auto mt-6 text-left;
}
</style>
