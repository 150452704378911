import { firestoreAction } from "vuexfire";
import { db, FieldValue } from "@/firebase";

const interactions = {
  namespaced: true,
  state: {
    list: null,
  },
  actions: {
    createInteraction: firestoreAction(
      ({ rootGetters }, { performanceId, data }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("interactions")
          .doc(data.label.toLowerCase())
          .set(data);
      }
    ),
    updateInteraction: firestoreAction(
      ({ rootGetters }, { performanceId, interactionId, data }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("interactions")
          .doc(interactionId)
          .update(data);
      }
    ),
    voteInteraction: firestoreAction(
      ({ rootGetters }, { performanceId, interactionId, userId }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("interactions")
          .doc(interactionId)
          .update({
            votes: FieldValue.increment(1),
            voters: FieldValue.arrayUnion(userId),
          });
      }
    ),
    bindInteractions: firestoreAction(({ bindFirestoreRef }, performanceId) => {
      // Bind all interactions to the state
      return bindFirestoreRef(
        "list",
        db
          .collection("performances")
          .doc(performanceId)
          .collection("interactions")
      );
    }),
    unbindAllInteractions: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("list");
    }),
    resetVoteStatus: firestoreAction(
      ({ rootGetters }, { performanceId, interactionId }) => {
        const data = {
          unlocked_by: null,
          votes: 0,
          voters: [],
          voting_open: false,
          time_remaining: null,
          locked: true,
          vote_passed: false,
          vote_failed: false,
          used: false,
        };
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("interactions")
          .doc(interactionId)
          .update(data);
      }
    ),
  },
};

export default interactions;
