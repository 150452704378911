<template>
  <container outer>
    <template v-if="current && ready">
      <tab-group
        :scroll="activeTab !== 'performance'"
        :pad-bottom="activeTab === 'outputs'"
      >
        <template slot="tabs">
          <button
            v-for="(tab, i) in tabs"
            :key="`tab-cta-${i}`"
            :disabled="current.setup[tab.id] === false"
            :class="{
              ['c-tab-cta']: true,
              ['c-tab-cta--active']: activeTab === tab.id,
            }"
            @click="changeTab(tab.id)"
          >
            {{ tab.label }}
          </button>
        </template>

        <template slot="title">
          <h2>{{ current.title }} / {{ current.location }}</h2>
        </template>

        <section v-if="activeTab === 'performance'" :class="$style.GridLobby">
          <div :class="$style.Info">
            <block light-header>
              <template slot="header">
                <h2>Performance Controls</h2>
              </template>
              <form-row label="start / end performance">
                <shitty-button
                  v-if="!current.active"
                  success
                  @click.native="onStartShow"
                >
                  Start performance
                </shitty-button>
                <shitty-button
                  v-if="current.active"
                  danger
                  @click.native="onEndShow"
                >
                  End performance
                </shitty-button>
              </form-row>
              <form-row label="toggle live / recorded lobby video">
                <shitty-button
                  v-if="!lobby.show_live_intro"
                  :disabled="
                    current.performance_started && !current.performance_complete
                  "
                  success
                  @click.native="onToggleLiveIntro(true)"
                >
                  Enable Live Lobby Feed
                </shitty-button>
                <shitty-button
                  v-if="lobby.show_live_intro"
                  danger
                  :disabled="
                    current.performance_started && !current.performance_complete
                  "
                  @click.native="onToggleLiveIntro(false)"
                >
                  Disable Live Lobby Feed
                </shitty-button>
              </form-row>
              <dl :class="[$style.Status, 'u-dl']">
                <dt>waiting room</dt>
                <dd>{{ !current.active && !current.performance_started }}</dd>
                <dt>performance started</dt>
                <dd>{{ current.active && current.performance_started }}</dd>
                <dt>performance complete</dt>
                <dd>{{ current.performance_complete }}</dd>
                <dt>chat enabled</dt>
                <dd>{{ !current.chat_disabled }}</dd>
              </dl>
            </block>
            <block light-header :class="$style.Reset">
              <template slot="header">
                <h2>Performance Reset</h2>
              </template>
              <form-row label="Reset to initial settings">
                <shitty-button
                  :disabled="current.active"
                  @click.native="onResetShow"
                >
                  Reset performance
                </shitty-button>
              </form-row>
            </block>
          </div>
          <block
            light-header
            no-scroll-border
            scroll-content
            :class="$style.Audience"
          >
            <template slot="header">
              <h2>Online Audience</h2>
            </template>
            <table :class="$style.Table">
              <tr>
                <th>Ticket ID</th>
                <th>Display Name</th>
                <th>Login count</th>
                <th />
              </tr>
              <template v-for="(item, i) in audience">
                <template v-for="(user, userIndex) in item.users">
                  <tr :key="`person-${userIndex}-${i}`">
                    <td :key="`user-id-${i}-${userIndex}`">{{ item.id }}</td>
                    <td :key="`user-name-${i}-${userIndex}`">
                      {{ user || "" }}
                    </td>
                    <td :key="`user-logins-${i}-${userIndex}`">
                      {{ item.logins }}
                    </td>
                    <td
                      :key="`user-cta-${i}-${userIndex}`"
                      :class="$style.TdShrink"
                    >
                      <button>
                        <img :src="require('@/assets/icons/reset-light.svg')" />
                      </button>
                    </td>
                  </tr>
                </template>
              </template>
            </table>
          </block>
        </section>

        <!-- Output Controls - set which video displays on each output -->
        <section v-if="activeTab === 'outputs'" :class="$style.GridOutputs">
          <template v-for="(item, i) in outputs">
            <controls-outputs
              v-if="item.active"
              :key="`output-${i}`"
              :output="item"
              :live="liveStreams"
              :recorded="recordedVideos"
              :index="i"
            />
          </template>
        </section>

        <section v-if="activeTab === 'interactions'">
          <controls-interactions
            :interactions="interactions"
            :class="$style.ControlPanel"
          />
        </section>

        <section v-if="activeTab === 'messaging'" :class="$style.GridMessaging">
          <controls-chat-window
            :show-chat-input="false"
            :class="$style.MessagingList"
            @delete="onDeleteMessage"
          />
          <div :class="$style.MessagingCast">
            <character-message-input :cast-member="castMemberOne" />
            <character-message-input :cast-member="castMemberTwo" />
            <character-message-input :cast-member="castMemberThree" />
          </div>
        </section>

        <!-- Fixed position control footer - OUTPUTS -->
        <footer v-show="activeTab === 'outputs'" :class="$style.Footer">
          <div :class="$style.Audio">
            <select
              v-model="soundtrack"
              class="c-select"
              @change="onSetmasterSoundtrack"
            >
              <option disabled value="">feeds</option>
              <option
                v-for="(item, i) in tracks"
                :key="`soundtrack-${i}`"
                :value="item"
              >
                {{ item.label }}
              </option>
            </select>
            <controls-master-soundtrack
              v-if="current.master_soundtrack.active"
              :url="current.master_soundtrack.active.url"
              :playback="current.master_soundtrack.playback"
              :currentTime="current.master_soundtrack.current_time"
              :playerid="'master-soundtrack'"
              @playback="onMasterSoundtrackPlayback"
              @seeked="onMasterSoundtrackSeeked"
            />
          </div>
          <div :class="$style.CutEnableFeeds">
            <shitty-button class="c-btn-danger" @click.native="onCutAllFeeds">
              cut all feeds
            </shitty-button>
            <shitty-button
              class="c-btn-success"
              @click.native="onEnableAllFeeds"
            >
              enable all feeds
            </shitty-button>
          </div>
        </footer>

        <!-- Fixed position control footer - MESSAGING -->
        <footer v-show="activeTab === 'messaging'" :class="$style.Footer">
          <div :class="$style.Audio">
            <p
              v-if="current.chat_disabled"
              :class="[$style.ChatStatus, $style.Disabled]"
            >
              Audience chat is currenty disabled
            </p>
          </div>
          <div :class="$style.CutEnableFeeds">
            <shitty-button
              v-if="!current.chat_disabled"
              class="c-btn-danger"
              @click.native="onDisableChat"
            >
              disable chat
            </shitty-button>
            <shitty-button
              v-if="current.chat_disabled"
              class="c-btn-success"
              @click.native="onEnableChat"
            >
              enable chat
            </shitty-button>
          </div>
        </footer>
      </tab-group>
    </template>
    <template v-else>
      <loading />
    </template>
  </container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getDisplayName } from "@/firebase";
import ControlsOutputs from "@/components/controls/ControlsOutputs.vue";
import ControlsChatWindow from "@/components/controls/ControlsChatWindow.vue";
import CharacterMessageInput from "@/components/controls/CharacterMessageInput.vue";
import ControlsInteractions from "@/components/controls/ControlsInteractions.vue";
import ControlsMasterSoundtrack from "@/components/controls/ControlsMasterSoundtrack.vue";

export default {
  name: "ViewAdminControls",
  components: {
    ControlsOutputs,
    CharacterMessageInput,
    ControlsInteractions,
    ControlsChatWindow,
    ControlsMasterSoundtrack,
  },
  computed: {
    ...mapState("performance", ["current"]),
    ...mapState("performance/audience", { audience: "list" }),
    ...mapState("performance/interactions", { interactions: "list" }),
    ...mapState("performance/liveStreams", { liveStreams: "list" }),
    ...mapState("performance/recordedVideos", { recordedVideos: "list" }),
    ...mapState("performance/outputs", { outputs: "list" }),
    ...mapState("performance/panels", { panels: "list" }),
    ...mapState("performance/audio", { tracks: "list" }),
    ...mapState("performance/lobby", { lobby: "lobby" }),

    orderedOutputs() {
      const arr = this.outputs;
      return arr.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { numeric: true })
      );
    },
  },
  data() {
    return {
      ready: false,
      tabs: [
        {
          label: "Performance",
          id: "performance",
        },
        {
          label: "Outputs",
          id: "outputs",
        },
        {
          label: "Interactions",
          id: "interactions",
        },
        {
          label: "Messaging",
          id: "messaging",
        },
      ],
      activeTab: "performance",
      castMemberOne: {
        displayName: "Personal Shopper",
      },
      castMemberTwo: {
        displayName: "Robin",
      },
      castMemberThree: {
        displayName: "Dave",
      },
      soundtrack: null,
      soundtrackVolume: 0.5,
      soundtrackPlayback: true,
    };
  },
  async mounted() {
    try {
      // TODO: maybe just do this once in App.vue? Seems wasteful...
      await this.bind(this.$route.params.id);
      await this.bindInteractions(this.$route.params.id);
      await this.bindLiveStreams(this.$route.params.id);
      await this.bindRecordedVideos(this.$route.params.id);
      await this.bindOutputs(this.$route.params.id);
      await this.bindMessages(this.$route.params.id);
      await this.bindAudio(this.$route.params.id);
      await this.bindAudience(this.$route.params.id);
      await this.bindLobby(this.$route.params.id);
      await this.initStreams();
      this.soundtrack = this.current.master_soundtrack.active;
      this.ready = true;
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    ...mapActions("performance", [
      "bind",
      "update",
      "unbind",
      "startShow",
      "endShow",
      "resetShow",
    ]),
    ...mapActions("performance/outputs", ["bindOutputs", "updateOutputs"]),
    ...mapActions("performance/messages", ["bindMessages", "deleteMessage"]),
    ...mapActions("performance/interactions", ["bindInteractions"]),
    ...mapActions("performance/liveStreams", [
      "bindLiveStreams",
      "initStreams",
    ]),
    ...mapActions("performance/audience", ["bindAudience"]),
    ...mapActions("performance/recordedVideos", ["bindRecordedVideos"]),
    ...mapActions("performance/audio", ["bindAudio"]),
    ...mapActions("performance/lobby", ["bindLobby", "updateLobby"]),
    getDisplayName,
    changeTab(tab) {
      this.activeTab = tab;
    },
    async onSetmasterSoundtrackVolume(volume) {
      try {
        await this.update({
          data: {
            ...this.current.master_soundtrack,
            volume,
          },
          id: this.$route.params.id,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onMasterSoundtrackPlayback({ playback, currentTime }) {
      try {
        await this.update({
          data: {
            master_soundtrack: {
              ...this.current.master_soundtrack,
              playback,
              current_time: currentTime,
            },
          },
          id: this.$route.params.id,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onMasterSoundtrackSeeked(currentTime) {
      try {
        await this.update({
          data: {
            master_soundtrack: {
              ...this.current.master_soundtrack,
              current_time: currentTime,
            },
          },
          id: this.$route.params.id,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onSetmasterSoundtrack() {
      console.log("setting master soundtrack", this.soundtrack);
      try {
        await this.update({
          data: {
            master_soundtrack: {
              ...this.current.master_soundtrack,
              active: {
                label: this.soundtrack.label,
                url: this.soundtrack.url,
              },
            },
          },
          id: this.$route.params.id,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onCutAllFeeds() {
      try {
        await this.updateOutputs({
          performanceId: this.$route.params.id,
          data: { playback: false, mute_audience: true },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onEnableAllFeeds() {
      try {
        await this.updateOutputs({
          performanceId: this.$route.params.id,
          data: { playback: true, mute_audience: false },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onStartShow() {
      try {
        await this.startShow(this.$route.params.id);
        await this.onToggleLiveIntro(false);
      } catch (err) {
        console.error(err);
      }
    },
    async onEndShow() {
      try {
        await this.endShow(this.$route.params.id);
        await this.onToggleLiveIntro(false);
      } catch (err) {
        console.error(err);
      }
    },
    async onResetShow() {
      try {
        await this.resetShow(this.$route.params.id);
        await this.onToggleLiveIntro(false);
      } catch (err) {
        console.error(err);
      }
    },
    async onDisableChat() {
      try {
        await this.update({
          data: { chat_disabled: true },
          id: this.$route.params.id,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onEnableChat() {
      try {
        await this.update({
          data: { chat_disabled: false },
          id: this.$route.params.id,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onToggleLiveIntro(state) {
      try {
        await this.updateLobby({
          performanceId: this.$route.params.id,
          data: { show_live_intro: state },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onDeleteMessage(message) {
      console.log("deleting message", message);
      this.$modal.show("dialog", {
        title: `Delete message`,
        text: "Are you sure you want to delete this message?",
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: "Delete",
            handler: async () => {
              try {
                await this.deleteMessage({
                  performanceId: this.$route.params.id,
                  messageId: message.id,
                });
              } catch (err) {
                console.error(err);
              } finally {
                this.$modal.hide("dialog");
              }
            },
          },
        ],
      });
    },
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid grid-cols-2 gap-4 grid-rows-1 h-full;
}

.GridOutputs {
  @apply grid grid-cols-4 gap-4 pb-20;
}

.GridLobby {
  @apply grid grid-cols-4 gap-4 h-full overflow-hidden;
}
.GridMessaging {
  @apply grid grid-cols-4 gap-4 h-full pb-20 overflow-hidden;
}

.Info {
  @apply col-span-1 self-start;
}

.Audience {
  @apply col-span-3;
}

.ControlPanel {
  @apply h-full;
}

.MessagingList {
  @apply col-span-2;
  @apply flex-shrink;
}

.MessagingCast {
  @apply col-span-2;
  @apply flex flex-col gap-3;
}

.Footer {
  @apply fixed bottom-2 left-2 right-2 h-20 pt-3 pb-1 flex justify-between items-center bg-black;
  @apply border-t border-solid border-keyline;
}

.Audio {
  @apply flex items-center gap-3;
  & select {
    @apply w-52;
  }
}

.CutEnableFeeds {
  @apply flex gap-3;
}

.ChatStatus {
  @apply uppercase text-base leading-none px-2 pt-1;
  &.Disabled {
    @apply bg-red-600;
  }
}

.Table {
  @apply w-full;
  & tr {
    & th {
      @apply border border-gray-700 w-full;
      @apply text-left px-3 py-3;
      white-space: nowrap;
    }
    & td {
      @apply border border-gray-700 w-full;
      @apply text-left px-3 py-1;
      white-space: nowrap;
      & button {
        @apply min-w-full w-full flex items-center;
        & img {
          @apply max-w-none w-4;
        }
      }
    }
  }
}

.TdShrink {
  /* max-width: 100%;
  white-space: nowrap; */
}

.Reset {
  @apply mt-4;
}

.Status {
  @apply my-3;
}
</style>
