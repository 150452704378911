<template>
  <container v-if="loggedIn" outer :class="$style.Grid">
    <form-performance
      v-if="admin"
      :class="$style.New"
      @form-submit="(form) => onFormSubmit(form)"
    />
    <block v-if="!admin" :class="$style.User" auto-content-height>
      <template slot="header"><h2>User Info</h2></template>
      <p>User account management stuff here?</p>
    </block>
    <div :class="$style.Performances">
      <performance-list :admin="admin" :list="list" />
    </div>
  </container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import FormPerformance from "@/components/FormPerformance.vue";
import PerformanceList from "@/components/PerformanceList.vue";

export default {
  name: "Dashboard",
  components: {
    FormPerformance,
    PerformanceList,
  },
  computed: {
    ...mapState("performance", ["list"]),
    ...mapGetters(["admin", "loggedIn"]),
  },
  async mounted() {
    try {
      await this.bindAll(this.$route.params.id);
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    ...mapActions("performance", ["bindAll", "create"]),
    onFormSubmit(form) {
      this.create(form)
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid grid-cols-12 gap-4;
}

.Performances {
  @apply col-span-9;
}
.New {
  @apply col-span-3;
  @apply self-start;
}
</style>
