<template>
  <ul :class="$style.List">
    <li v-for="(stream, i) in streams" :key="`stream-${i}`">
      <block light-header>
        <template slot="header">
          <h3>STREAM: {{ stream.label }}</h3>
        </template>
        <div :class="$style.Grid">
          <section :class="$style.Preview">
            <output-live
              :type="stream.type"
              :url="stream.url"
              :label="stream.label"
              :playback="true"
              :muted="true"
              :class="$style.Video"
              :uid="stream.id"
              is-admin
            />
          </section>
          <form-live-feeds
            :feed="stream"
            :class="$style.Form"
            @form-submit="(data) => update({ data, id: stream.id })"
          />
        </div>
      </block>
    </li>
  </ul>
</template>

<script>
import { mapActions } from "vuex";
import FormLiveFeeds from "@/components/FormLiveFeeds";
export default {
  name: "AdminLiveStreams",
  components: {
    FormLiveFeeds,
  },
  props: {
    streams: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    ...mapActions("performance/liveStreams", ["updateLiveStream"]),
    async update({ data, id }) {
      try {
        await this.updateLiveStream({
          performanceId: this.$route.params.id,
          streamId: id,
          data,
        });
        this.$modal.show("dialog", {
          title: "Live feed updated",
          buttons: [
            {
              title: "OK",
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
          ],
        });
      } catch (err) {
        console.error(err);
      }
      console.log("updating:", data, id);
      // this.$emit("update", { index: i, data: stream });
    },
  },
};
</script>

<style module lang="postcss">
.List {
  @apply grid gap-4 grid-cols-2;
}

.Grid {
  @apply grid gap-3 grid-cols-9;
}
.Preview {
  @apply col-span-4;
}
.Form {
  @apply col-span-5;
}
</style>
