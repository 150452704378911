<template>
  <div :class="[$style.Grid]">
    <block light-header auto-content-height :class="$style.PerformanceInfo">
      <template slot="header">
        <h2>General Info</h2>
      </template>
      <p :class="$style.Description">Set basic performance info here</p>

      <form @submit.prevent="submit">
        <form-row :error="$v.title.$error || error !== false" :label="'Title'">
          <input
            v-model.trim="$v.title.$model"
            type="text"
            placeholder="Title"
          />
          <form-error v-if="$v.$error && !$v.title.required">
            This field is required
          </form-error>
        </form-row>

        <form-row
          :error="$v.location.$error || error !== false"
          :label="'Venue Location'"
        >
          <input
            v-model.trim="$v.location.$model"
            type="text"
            placeholder="Location"
          />
          <form-error v-if="$v.$error && !$v.location.required">
            This field is required
          </form-error>
        </form-row>
      </form>
      <template slot="footer">
        <shitty-button @click.native="submit()" class="w-full">
          Save general info
        </shitty-button>
      </template>
    </block>
    <block light-header :class="$style.PerformanceTickets">
      <template slot="header">
        <h3>Add ticket ID(s)</h3>
      </template>
      <form-row no-wrap label="Ticket ID's" :class="$style.BulkUpload">
        <textarea
          v-model="ticketIds"
          placeholder="/enter a list of ticket ID's for this performance, one ID per line/"
          type="text"
          rows="10"
          :class="'c-textarea'"
        />
        <shitty-button
          :disabled="!ticketIds || ticketIds === ''"
          @click.native="onBulkAddAudience"
        >
          Add ticket ID's
        </shitty-button>
      </form-row>
    </block>

    <block light-header :class="$style.PerformanceAudience">
      <template slot="header">
        <h3>Valid ticket ID's for this performance</h3>
      </template>
      <ul v-if="list" :class="$style.List">
        <li v-for="(ticket, i) in list" :key="`ticket-${i}`">
          <div :class="$style.Ticket">
            <input type="checkbox" v-model="bulkDelete" :value="ticket.id" />
            <span>{{ ticket.id }}</span>
          </div>

          <shitty-button danger @click.native="onDeleteAudience(ticket.id)">
            delete
          </shitty-button>
        </li>
      </ul>
    </block>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "FormPerformance",
  props: {
    performance: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("performance/audience", ["list"]),
  },
  data() {
    return {
      title: "",
      location: "",
      submitStatus: null,
      error: false,
      ticketIds: null,
      bulkDelete: [],
    };
  },
  validations: {
    title: {
      required,
    },
    location: {
      required,
    },
  },
  async mounted() {
    this.title = this.performance.title;
    this.location = this.performance.location;
  },
  methods: {
    ...mapActions("performance", ["update"]),
    ...mapActions("performance/audience", [
      "addBulkAudience",
      "deleteAudience",
      "bindAudience",
    ]),

    async submit() {
      try {
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.submitStatus = "ERROR";
        } else {
          await this.update({
            id: this.$route.params.id,
            data: { title: this.title, location: this.location },
          });
          this.$modal.show("dialog", {
            title: "General Info updated",
            buttons: [
              {
                title: "OK",
                handler: () => {
                  this.$modal.hide("dialog");
                },
              },
            ],
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    async onBulkAddAudience() {
      try {
        const tickets = this.ticketIds.split(/\n/).filter((e) => e);
        await this.addBulkAudience({
          performanceId: this.$route.params.id,
          tickets,
        });
        this.ticketIds = null;
      } catch (err) {
        console.error(err);
      }
    },

    onDeleteAudience(ticketId) {
      this.$modal.show("dialog", {
        title: `Delete ticket ID`,
        text: "Are you sure you want to delete this ticket? This cannot be undone.",
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: "Delete",
            handler: async () => {
              try {
                await this.deleteAudience({
                  performanceId: this.$route.params.id,
                  ticketId,
                });
                this.$modal.hide("dialog");
              } catch (err) {
                console.error(err);
                this.$modal.hide("dialog");
              }
            },
          },
        ],
      });
    },
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid gap-4 grid-cols-3;
}

.PerformanceInfo {
  @apply col-span-1 row-span-1;
}
.PerformanceTickets {
  @apply col-span-2 row-span-1;
}

.PerformanceAudience {
  @apply col-span-2 row-start-2 col-start-2 row-span-1;
}

.List {
  @apply flex flex-col;
  & li {
    @apply flex justify-between items-center border-b border-gray-800 py-2 leading-none;
    &:last-child {
      @apply border-none;
    }
  }
}

.BulkUpload {
  & button {
    @apply self-end;
  }
}

.Ticket {
  @apply flex items-center;
  & input {
    @apply mr-4;
  }
}
</style>
