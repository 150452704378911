import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";

const recordedVideos = {
  namespaced: true,
  state: {
    list: null,
  },
  actions: {
    createRecordedVideo: firestoreAction(
      ({ rootGetters }, { performanceId, data }) => {
        const uid = data.label
          .replace(/\s+/g, "-")
          .toLowerCase()
          .substr(0, data.label.lastIndexOf("."));

        return db
          .collection("performances")
          .doc(performanceId)
          .collection("recorded-videos")
          .doc(uid)
          .set({ ...data, uid });
      }
    ),
    updateRecordedVideo: firestoreAction(
      ({ rootGetters }, { performanceId, videoId, data }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("recorded-videos")
          .doc(videoId)
          .update(data);
      }
    ),
    deleteRecordedVideo: firestoreAction(
      ({ rootGetters }, { performanceId, videoId }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("recorded-videos")
          .doc(videoId)
          .delete();
      }
    ),
    bindRecordedVideos: firestoreAction(
      ({ bindFirestoreRef }, performanceId) => {
        // Bind all performances to the state
        return bindFirestoreRef(
          "list",
          db
            .collection("performances")
            .doc(performanceId)
            .collection("recorded-videos")
        );
      }
    ),
    unbindAllRecordedVideos: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("list");
    }),
  },
};

export default recordedVideos;
