<template>
  <container outer :class="$style.Wrap">
    <img
      :src="require('@/assets/brick-moon-bg.jpeg')"
      :class="$style.BgImage"
    />
    <section :class="$style.IntroBox">
      <header><h1>Department Story</h1></header>
      <p>
        Intro text here. This text will be configurable via the RC admin
        interface.
      </p>
      <footer><router-link :to="`/`">Sign in</router-link></footer>
    </section>
  </container>
</template>

<script>
export default {
  name: "Enter",

  async mounted() {},
};
</script>

<style module lang="postcss">
.Wrap {
  @apply fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center;
}

.BgImage {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}
.IntroBox {
  font-family: "Roboto", sans-serif;
  @apply relative z-10 text-center;
  & header {
    @apply px-3 mb-6;
    & h1 {
      @apply text-5xl font-medium leading-none;
    }
  }
  & p {
    @apply text-lg m-0;
  }
  & footer {
    @apply mt-6;
    & a {
      @apply inline-flex bg-blue-700 text-xl px-6 py-3 leading-none rounded-lg;
    }
  }
}
</style>
