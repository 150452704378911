const notifications = {
  namespaced: true,
  state: {
    message: null,
    timeout: 5000,
    showCloseCta: false,
  },
  mutations: {
    SET_MESSAGE(state, data) {
      state.message = data;
    },
  },
  actions: {
    showNotification({ commit, state }, data) {
      commit("SET_MESSAGE", data);
      setTimeout(() => {
        commit("SET_MESSAGE", null);
      }, state.timeout);
    },
  },
};

export default notifications;
