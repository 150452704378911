<template>
  <form action="#" @submit.prevent="() => {}" :class="$style.Form">
    <form-row label="Label" :error="!$v.label.required">
      <input
        v-model.trim="$v.label.$model"
        type="text"
        placeholder="Enter label for this feed"
      />
      <form-error v-if="!$v.label.required">This field is required</form-error>
    </form-row>
    <form-row label="Web socket URL" :error="$v.url.$anyError">
      <input v-model.trim="$v.url.$model" type="text" placeholder="URL" />
      <form-error v-if="!$v.url.required">This field is required</form-error>
    </form-row>
    <form-row>
      <shitty-button
        :disabled="$v.$anyError || !$v.$anyDirty"
        @click.native="onSubmit({ label, url })"
        class="w-full"
      >
        Save
      </shitty-button>
    </form-row>
    <!-- <tree-view
      :data="$v.label"
      :options="{ rootObjectKey: '$v', maxDepth: 2 }"
    ></tree-view> -->
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "FormLiveFeeds",
  props: {
    feed: {
      type: [Object, Boolean],
      default: false,
    },
  },
  data() {
    return {
      label: "",
      url: "",
    };
  },
  validations: {
    label: {
      required,
    },
    url: {
      required,
    },
  },
  computed: {},
  mounted() {
    this.label = this.feed.label;
    this.url = this.feed.url;
  },
  methods: {
    onSubmit(data) {
      this.$emit("form-submit", data);
      this.$v.$reset();
    },
  },
};
</script>

<style module lang="postcss">
.Form {
  @apply flex flex-col items-start;
  & > div:last-child {
    @apply flex-grow flex items-end;
  }
}
</style>
