import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";

const liveStreams = {
  namespaced: true,
  state: {
    list: null,
    streams: {},
  },
  mutations: {
    SET_STREAM(state, { key, stream }) {
      state.streams[key] = stream;
    },
  },
  actions: {
    createLiveStream: firestoreAction(
      ({ rootGetters }, { performanceId, data }) => {
        const uid = data.label.replace(/\s+/g, "-").toLowerCase();
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("live-streams")
          .doc(uid)
          .set({ ...data, uid });
      }
    ),
    updateLiveStream: firestoreAction(
      ({ rootGetters }, { performanceId, streamId, data }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("live-streams")
          .doc(streamId)
          .update({ ...data });
      }
    ),
    bindLiveStreams: firestoreAction(
      ({ bindFirestoreRef, rootGetters }, performanceId) => {
        // Bind all performances to the state
        return bindFirestoreRef(
          "list",
          db
            .collection("performances")
            .doc(performanceId)
            .collection("live-streams")
        );
      }
    ),
    unbindAllLiveStreams: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("list");
    }),
    async initStreams({ commit, state }) {
      const streams = state.list;

      streams.forEach((item) => {
        let id, peerId;

        const ws = new WebSocket(item.url);

        const pc = new RTCPeerConnection();
        pc.addTransceiver("audio", { direction: "recvonly" });
        pc.addTransceiver("video", { direction: "recvonly" });

        pc.ontrack = (event) => {
          if (event.track.kind === "video") {
            commit("SET_STREAM", {
              key: item.id,
              stream: {
                url: item.url,
                connected: true,
                srcObject: event.streams[0],
              },
            });
          }
        };

        pc.onicecandidate = (event) => {
          const { candidate } = event;
          if (candidate) {
            sendMessage({
              id: id,
              peer_id: peerId,
              command: "candidate",
              candidates: [candidate],
            });
          }
        };

        if (ws) {
          ws.onclose = () => {
            pc.close();
            commit("SET_STREAM", {
              key: item.id,
              stream: {
                url: item.url,
                connected: false,
                srcObject: null,
              },
            });
          };

          ws.onerror = () => {
            pc.close();
            ws.close();
            commit("SET_STREAM", {
              key: item.id,
              stream: {
                url: item.url,
                connected: false,
                srcObject: null,
              },
            });
          };

          ws.onopen = () => {
            sendMessage({ command: "request_offer" });
          };

          ws.onmessage = (e) => {
            const msg = JSON.parse(e.data);

            if (msg.error) {
              console.error(msg.error);
            }

            if (msg.command === "ping") {
              sendMessage({ command: "pong" });
            }

            if (msg.command === "offer") {
              pc.setRemoteDescription(new RTCSessionDescription(msg.sdp));

              for (let i = 0; i < msg.candidates.length; i++) {
                if (msg.candidates[i] && msg.candidates[i].candidate) {
                  const basicCandidate = msg.candidates[i];
                  const cloneCandidate = copyCandidate(
                    basicCandidate,
                    item.url
                  );

                  pc.addIceCandidate(new RTCIceCandidate(basicCandidate));
                  pc.addIceCandidate(new RTCIceCandidate(cloneCandidate));
                }
              }

              pc.createAnswer().then((answer) => {
                pc.setLocalDescription(answer);

                id = msg.id;
                peerId = msg.peer_id;

                sendMessage({
                  command: "answer",
                  sdp: answer,
                  id: msg.id,
                  peer_id: msg.peer_id,
                });
              });
            }
          };
        } else {
          console.log("no socket exists");
        }

        function sendMessage(msg) {
          ws.send(JSON.stringify(msg));
        }
      });

      function copyCandidate(basicCandidate, url) {
        let cloneCandidate = basicCandidate;

        let newDomain = generateDomainFromUrl(url);
        let ip = findIp(cloneCandidate.candidate);

        if (ip === "" || ip === newDomain) {
          return null;
        }

        cloneCandidate.candidate = cloneCandidate.candidate.replace(
          ip,
          newDomain
        );

        return cloneCandidate;
      }

      function generateDomainFromUrl(url) {
        let result = "";
        let match;
        if (
          (match = url.match(
            /^(?:wss?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
          ))
        ) {
          result = match[1];
        }

        return result;
      }

      function findIp(string) {
        let result = "";
        let match;

        if (
          (match = string.match(
            new RegExp(
              "\\b(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\b",
              "gi"
            )
          ))
        ) {
          result = match[0];
        }

        return result;
      }
    },
  },
};

export default liveStreams;
