<template>
  <section :class="$style.Grid">
    <div>
      <block auto-content-height light-header :class="$style.Upload">
        <template slot="header">
          <h3>Upload a background video</h3>
        </template>
        <form-row label="Background Video">
          <template v-if="backgroundVideo">
            <video :src="backgroundVideo.url" controls />
          </template>
          <template v-else>
            <p>Nothing uploaded yet</p>
          </template>
        </form-row>
        <template slot="footer">
          <shitty-button @click.native="() => showUploadModal()">
            Upload video file
          </shitty-button>
        </template>
      </block>
      <block light-header>
        <template slot="header" :class="$style.Header">
          <h2>Live Video</h2>
        </template>
        <form-row label="Lobby Live Feed">
          <div
            v-if="activeFeed && activeFeed.url"
            :class="[$style.VideoPreview]"
          >
            <output-live
              is-admin
              :label="activeFeed.label"
              :url="activeFeed.url"
              :type="'live'"
              :uid="activeFeed.uid"
              :playback="true"
              :muted="false"
              :paused="false"
            />
            <!-- @play="() => onPlayPause(false)"
                @pause="() => onPlayPause(true)" -->
          </div>
        </form-row>

        <form-row label="Select Lobby Live feed">
          <select v-model="feed" class="c-select" @change="onSetFeed">
            <option disabled value="">feeds</option>
            <option
              v-for="(item, i) in feedOptions"
              :key="`feed-${i}`"
              :value="item"
            >
              {{ item.type }} - {{ item.label }}
            </option>
          </select>
        </form-row>
      </block>
    </div>

    <block auto-content-height light-header :class="$style.Text">
      <template slot="header">
        <h3>Intro text</h3>
      </template>
      <form-row>
        <p>
          Add some intro text for the audience. Instructions, warnings, legal
          stuff or whatever.
        </p>
      </form-row>
      <form-row label="Login page text">
        <textarea
          v-model="loginTextInput"
          rows="3"
          type="text"
          :class="[$style.Text, 'c-textarea']"
        />
      </form-row>
      <form-row label="Waiting room text">
        <textarea
          v-model="lobbyText"
          rows="10"
          type="text"
          :class="[$style.Text, 'c-textarea']"
        />
      </form-row>
      <form-row label="SHow Ended Text">
        <textarea
          v-model="lobbyEndedText"
          rows="10"
          type="text"
          :class="[$style.Text, 'c-textarea']"
        />
      </form-row>
      <template slot="footer">
        <shitty-button @click.native="onUpdateLobbyText">
          Update Text
        </shitty-button>
      </template>
    </block>

    <modal
      :name="`upload-video`"
      :width="'500px'"
      :height="'auto'"
      :adaptive="true"
      :classes="[$style.Modal]"
      :styles="['u-modal-overlay']"
      :clickToClose="true"
    >
      <form-upload-video
        :video-id="videoId"
        @upload="onUpload"
        @close="hideUploadModal"
      />
    </modal>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FormUploadVideo from "@/components/FormUploadVideo";

export default {
  name: "AdminLobby",
  components: { FormUploadVideo },
  props: {
    backgroundVideo: {
      type: Object,
      default: () => {},
    },
    activeFeed: {
      type: Object,
      default: () => {},
    },
    text: {
      type: String,
      default: "",
    },
    loginText: {
      type: String,
      default: "",
    },
    endedText: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("performance/liveStreams", ["list"]),
    feedOptions() {
      const feeds = [];
      this.list.map((option) => {
        option.type = "live";
        feeds.push(option);
      });
      return feeds;
    },
  },
  data() {
    return {
      videoId: false,
      feed: null,
      loginTextInput: "",
      lobbyText: "",
      lobbyEndedText: "",
    };
  },
  mounted() {
    this.loginTextInput = this.loginText;
    this.lobbyText = this.text;
    this.feed = this.activeFeed;
    this.lobbyEndedText = this.endedText;
  },
  methods: {
    ...mapActions("performance/lobby", ["updateLobby"]),
    ...mapActions(["updateCopy"]),
    async onUpload({ url, label }) {
      try {
        await this.onUpdateLobbyVideo({ background_video: { url, label } });
        this.$modal.hide(`upload-video`);
      } catch (err) {
        console.error(err);
        this.$modal.hide(`upload-video`);
      }
    },
    async onSetFeed() {
      console.log(this.feed);
      try {
        await this.updateLobby({
          performanceId: this.$route.params.id,
          data: { active_feed: this.feed },
        });
      } catch (err) {
        console.error(err);
      }
    },

    async onUpdateLobbyVideo(data) {
      try {
        await this.updateLobby({
          performanceId: this.$route.params.id,
          lobbyId: this.$route.params.id,
          data,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onUpdateLobbyText() {
      try {
        await this.updateCopy({
          performanceId: this.$route.params.id,
          lobbyId: this.$route.params.id,
          data: {
            waiting_room_text: this.lobbyText,
            login_text: this.loginTextInput,
            ended_text: this.lobbyEndedText,
          },
        });
        this.$modal.show("dialog", {
          title: "Update Successful",
          buttons: [
            {
              title: "OK",
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
          ],
        });
      } catch (err) {
        console.error(err);
      }
    },

    showUploadModal(videoId) {
      this.videoId = videoId;
      this.$modal.show(`upload-video`);
    },
    hideUploadModal() {
      this.videoId = false;
      this.$modal.hide("upload-video");
    },
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid grid-cols-3 gap-4;
}

.Upload {
  @apply col-span-1 mb-4;
}

.Text {
  @apply col-span-2 self-start;
}

.Modal {
  @apply bg-black rounded-none;
}
</style>
