<template>
  <block
    light-header
    no-scroll-border
    scroll-content
    :class="$style.MessagingList"
  >
    <template slot="header">
      <h2>Messages</h2>
    </template>
    <template v-if="list">
      <ul ref="messages" :class="$style.MessageList">
        <li
          v-for="(item, i) in list"
          :key="`message-${i}`"
          :class="{
            [$style.Message]: true,
            [$style.MessageCast]: item.role === 'Cast',
          }"
        >
          <span :class="$style.Name">{{ item.name }}:</span>
          <span :class="$style.Text"> {{ item.text }} </span>
          <span :class="$style.Time">
            {{ formattedTime(item.time) }}
          </span>
          <button :class="$style.Delete" @click="deleteMessage(item)">
            <img :src="require('@/assets/icons/delete-light.svg')" />
          </button>
        </li>
      </ul>
    </template>
    <div v-else>No messages yet</div>
    <div v-if="showChatInput" :class="$style.Input">
      <textarea v-model="message" />
      <shitty-button @click.native="onSendMessage">Send message</shitty-button>
    </div>
  </block>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import format from "date-fns/format";

export default {
  name: "ControlsChat",
  components: {},
  props: {
    showChatInput: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState("performance/messages", ["list"]),
    ...mapGetters({
      role: "role",
      displayName: "displayName",
    }),
  },
  watch: {
    list: {
      deep: true,
      handler(val) {
        if (this.$refs.messages && val.length) {
          const list = this.$refs.messages;
          if (!list) return;
          this.$nextTick(() => {
            list.scrollTop = list.scrollHeight;
          });
        }
      },
    },
  },
  data() {
    return {
      message: "",
    };
  },
  mounted() {
    console.log(this.$refs.messages);
    if (this.$refs.messages) {
      setTimeout(() => {
        const list = this.$refs.messages;
        list.scrollTop = list.scrollHeight;
      }, 500);
    }
  },
  methods: {
    ...mapActions("performance/messages", ["createMessage"]),

    async onSendMessage() {
      if (this.message === "") return;
      try {
        const date = new Date();
        await this.createMessage({
          performanceId: this.$route.params.id,
          text: this.message,
          role: "Cast",
          displayName: this.castMember.displayName,
          time: date,
        });
        this.message = "";
      } catch (err) {
        console.error(err);
      }
    },
    deleteMessage(message) {
      this.$emit("delete", message);
    },
    formattedTime(date) {
      const helperDate = date.toDate(date);
      return format(helperDate, "hh:mm:ss");
    },
  },
};
</script>

<style module lang="postcss">
.MessageList {
  @apply p-0 m-0 list-none h-full;
}

.Message {
  @apply py-2 border-0 border-b border-solid border-white px-2 text-left;
  @apply flex gap-3 leading-none;
  &:before {
    @apply hidden;
  }
  &:last-child {
    @apply border-0;
  }
}

.Name {
  @apply w-32 flex-shrink-0;
}

.Text {
  @apply flex-grow;
}

.MessageCast {
  @apply text-yellow;
}

.Time {
  @apply whitespace-nowrap;
}

.Input {
  @apply bg-gray-100 p-4 sticky bottom-0 h-24 border border-white border-solid border-b-0 border-l-0 border-r-0;
  & textarea {
    @apply w-full h-full;
  }
  & button {
    @apply absolute bottom-4 right-4;
  }
}

.Delete {
  @apply flex w-4 h-4 flex-shrink-0;
  & img {
    @apply inline-flex;
    @apply w-4 h-4;
  }
}
</style>
