import Vue from "vue";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import { auth, db } from "@/firebase";
import Vuex from "vuex";
import performance from "./performance";
import notifications from "./notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    account: null,
    copy: null,
    hasInteracted: false,
  },
  getters: {
    user_id(state) {
      return state.account && state.account.uid;
    },
    displayName(state) {
      if (!state.account.displayName) return false;
      return state.account.displayName.split("/")[0];
    },
    ticketId(state) {
      if (!state.account.displayName) return false;
      return state.account.displayName.split("/")[1];
    },
    loggedIn(state) {
      return state.account && state.account.uid ? true : false;
    },
    role(state) {
      return state.account && state.account.admin ? "Admin" : false;
    },
    admin(state) {
      return state.account && state.account.admin;
    },
  },
  mutations: {
    ...vuexfireMutations,
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_ACCOUNT(state, value) {
      state.account = value;
    },
    HAS_INTERACTED(state, value) {
      state.hasInteracted = value;
    },
  },
  actions: {
    accountSetup({ commit }, profile) {
      const account = {
        displayName: profile.displayName,
        email: profile.email,
        uid: profile.uid,
        admin: !profile.isAnonymous,
      };
      commit("SET_ACCOUNT", account);
      return account;
    },
    accountUpdate({ commit, state }, data) {
      commit("SET_ACCOUNT", {
        ...state.account,
        ...data,
      });
    },
    accountReset({ commit }) {
      commit("SET_ACCOUNT", {
        displayName: null,
        email: null,
        uid: null,
      });
    },
    async signIn({ commit }, { email, password }) {
      try {
        await auth.signInWithEmailAndPassword(email, password);
      } catch (err) {
        console.error(err);
      }
    },
    setHasInteracted({ commit }) {
      commit("HAS_INTERACTED", true);
    },
    async signInAnonymously({ dispatch }, displayName) {
      try {
        await auth.signInAnonymously();
        const user = auth.currentUser;
        await user.updateProfile({
          displayName,
        });
        return user;
      } catch (err) {
        console.error(err);
      }
    },
    async signOut({ dispatch }) {
      const user = auth.currentUser;
      try {
        if (user.isAnonymous) await user.delete();
        await auth.signOut();
        window.location.href = `${window.location.protocol}//${window.location.host}`
      } catch (err) {
        console.error(err);
      }
    },
    bindCopy: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef("copy", db.collection("defaults").doc("copy"));
    }),
    updateCopy: firestoreAction(({ rootGetters }, { data }) => {
      return db.collection("defaults").doc("copy").update(data);
    }),
  },
  modules: { performance, notifications },
});
