<template>
  <container outer>
    <form-register />
  </container>
</template>
<script>
import FormRegister from "@/components/FormRegister.vue";

export default {
  name: "Register",
  components: {
    FormRegister,
  },
};
</script>
