<template>
  <form action="#" @submit.prevent="() => {}">
    <form-row
      :label="`How many outputs should this panel display?`"
      :class="$style.PanelConfig"
    >
      <template slot="label"> </template>
      <div :class="$style.RadioGroup">
        <label>
          <input
            type="radio"
            :value="1"
            v-model="outputCount"
            @change="() => setOutputLimit(1)"
          />
          1
        </label>
        <label>
          <input
            type="radio"
            :value="2"
            v-model="outputCount"
            @change="() => setOutputLimit(2)"
          />
          2
        </label>
        <label>
          <input
            type="radio"
            :value="3"
            v-model="outputCount"
            @change="() => setOutputLimit(3)"
          />
          3
        </label>
        <label>
          <input
            type="radio"
            :value="4"
            v-model="outputCount"
            @change="() => setOutputLimit(4)"
          />
          4
        </label>
        <label>
          <input
            type="radio"
            :value="6"
            v-model="outputCount"
            @change="() => setOutputLimit(6)"
          />
          6
        </label>
      </div>
    </form-row>
    <form-row
      v-show="outputCount"
      :label="`Select ${outputCount} ${outputCount > 1 ? 'outputs' : 'output'}`"
      :class="$style.PanelConfig"
    >
      <div :class="$style.OutputSelectionGrid">
        <template v-for="(output, outputIndex) in outputs">
          <div
            v-if="output.active"
            :key="`output-${outputIndex}`"
            :class="$style.OutputCheckbox"
          >
            <label>
              <input
                type="checkbox"
                v-model="panelOutputs"
                :disabled="checkIfShouldBeDisabled(output)"
                :value="output"
              />
              {{ output.label }}
            </label>
          </div>
        </template>
      </div>
    </form-row>
    <form-row>
      <shitty-button
        :disabled="checkIfButtonDisabled(outputCount, panelOutputs.length)"
        @click.native="onSubmit({ outputs: panelOutputs, outputCount })"
      >
        save
      </shitty-button>
    </form-row>
  </form>
</template>

<script>
export default {
  name: "FormPanel",
  props: {
    panel: {
      type: Object,
      required: true,
    },
    outputs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      outputCount: null,
      panelOutputs: [],
    };
  },
  mounted() {
    this.panelOutputs = this.panel.outputs;
    this.outputCount = this.panel.outputCount;
  },
  methods: {
    setOutputLimit() {
      this.panelOutputs = [];
    },
    checkIfShouldBeDisabled(output) {
      return (
        this.outputCount === this.panelOutputs.length &&
        !this.panelOutputs.some((item) => item.id === output.id)
      );
    },
    checkIfButtonDisabled(count, length) {
      return length < count;
    },
    async onSubmit({ outputs, outputCount }) {
      this.$emit("form-submit", {
        panelId: this.panel.id,
        outputs,
        outputCount,
      });
    },
  },
};
</script>

<style module lang="postcss">
.OutputSelectionGrid {
  @apply grid grid-cols-4 gap-3;
}

.RadioGroup {
  @apply flex gap-3;
}

.Label {
  @apply flex gap-3 items-center;
}
</style>
