<template>
  <div :class="{ [$style.TabGroup]: true }">
    <header :class="$style.Header">
      <nav :class="$style.TabBar">
        <slot name="tabs" />
      </nav>
      <slot name="title" />
    </header>
    <div
      :class="{
        [$style.TabGroupContent]: true,
        [$style.PadBottom]: padBottom,
      }"
    >
      <div
        :class="{
          [$style.Inner]: true,
          [$style.Scroller]: scroll,
          [$style.SetupComplete]: setupComplete,
        }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabGroup",
  props: {
    setupComplete: {
      type: Boolean,
      default: true,
    },
    padBottom: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style module lang="postcss">
.TabGroup {
  /* @apply px-3; */
  @apply h-full flex flex-col;
}
.Header {
  @apply flex justify-between items-center;
}
.TabGroupContent {
  @apply border border-solid border-keyline flex-grow relative;
}

.PadBottom {
  @apply mb-14;
}

.Scroller {
  @apply overflow-y-auto top-0 bottom-0 left-0 right-0 absolute;
}
.Inner {
  @apply h-full p-3;
}
</style>
