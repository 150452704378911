<template>
  <form action="#" @submit.prevent="() => {}">
    <form-row label="Label" :error="!$v.label.required">
      <input
        v-model.trim="$v.label.$model"
        type="text"
        placeholder="Enter label for this output"
      />
      <form-error v-if="!$v.label.required">This field is required</form-error>
    </form-row>
    <form-row>
      <form-label :class="$style.Label">
        <input v-model.trim="$v.active.$model" type="checkbox" /> active
      </form-label>
    </form-row>
    <form-row>
      <shitty-button
        full-width
        :disabled="$v.$anyError || !$v.$anyDirty"
        @click.native="onSubmit({ label, active })"
      >
        Update
      </shitty-button>
    </form-row>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "FormOutput",
  props: {
    output: {
      type: [Object, Boolean],
      default: false,
    },
  },
  data() {
    return {
      label: "",
      active: true,
    };
  },
  validations: {
    label: {
      required,
    },
    active: {},
  },
  mounted() {
    this.label = this.output.label;
    this.active = this.output.active;
  },
  methods: {
    onSubmit(data) {
      this.$emit("form-submit", { outputId: this.output.id, data });
      this.$v.$reset();
    },
  },
};
</script>

<style module lang="postcss">
.Label {
}
</style>
