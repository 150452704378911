<template>
  <section
    :class="{
      [$style.Wrap]: true,
    }"
  >
    You need a bigger screen to use this application.
  </section>
</template>

<script>
export default {
  name: "ScreenBlocker",
};
</script>
<style module lang="postcss">
.Wrap {
  @apply fixed bg-black top-0 left-0 w-full h-full;
  @apply flex justify-center items-center z-50;
  @apply lg:hidden;
}
</style>
