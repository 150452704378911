<template>
  <nav :class="$style.AudienceNav">
    <div :class="$style.Label">Cam System CNTRL</div>
    <div :class="$style.Controls">
      <div :class="[$style.Divider, $style.DividerLeft]" />
      <shitty-button
        :class="{ [$style.Cta]: true, [$style.CtaPrev]: true }"
        :disabled="panel === 0"
        @click.native="prevPanel"
      >
        <img :src="require('@/assets/icons/arrowprev.svg')" />
        left
      </shitty-button>
      <shitty-button
        :class="{ [$style.Cta]: true, [$style.CtaNext]: true }"
        :disabled="panel === panels"
        @click.native="nextPanel"
      >
        right
        <img :src="require('@/assets/icons/arrownext.svg')" />
      </shitty-button>
      <div :class="[$style.Divider, $style.DividerRight]" />
    </div>
    <div :class="$style.Pagination">
      CAMSET_{{ panel + 1 }}/{{ panels + 1 }}
    </div>
  </nav>
</template>

<script>
export default {
  name: "AudienceNav",
  props: {
    panels: {
      type: Number,
      required: true,
    },
    panel: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    prevPanel() {
      this.$emit("prev-panel");
    },
    nextPanel() {
      this.$emit("next-panel");
    },
  },
};
</script>

<style module lang="postcss">
.AudienceNav {
  @apply w-full;
  @apply flex gap-3 items-center justify-center relative;
  &:before {
    content: "";
    @apply h-px bg-gray-500 w-full absolute top-1/2 transform -translate-y-px;
  }
}

.Controls {
  @apply flex gap-2 bg-black relative z-10 px-3 w-1/3 flex-shrink-0;
  min-width: 40%;
}

.Cta {
  @apply flex items-center whitespace-nowrap flex-grow;
  & img {
    @apply w-4 block;
  }
}

.Label {
  @apply text-left bg-black  z-10 pr-2 flex-shrink absolute left-0;
}

.Pagination {
  @apply text-right bg-black  z-10 pl-2 flex-grow-0 absolute right-0;
}

.Divider {
  @apply absolute w-10 h-12 top-1/2  transform -translate-y-1/2;
  &:before,
  &:after {
    content: "";
    @apply h-px w-4 absolute bg-gray-500;
  }
  &:before {
    @apply top-0;
  }
  &:after {
    @apply bottom-0;
  }
}

.DividerLeft {
  @apply border-l border-solid border-gray-500 left-0;
  &:before,
  &:after {
    @apply left-0;
  }
}
.DividerRight {
  @apply border-r border-solid border-gray-500 right-0;
  &:before,
  &:after {
    @apply right-0;
  }
}
</style>
