<template>
  <div outer v-if="current && lobby" :class="$style.Wrap">
    <transition appear name="fade-video">
      <video
        v-show="
          !current.performance_started &&
          lobby &&
          !lobby.show_live_intro &&
          lobby.background_video &&
          !current.performance_complete
        "
        key="bg-video"
        :src="lobby.background_video.url"
        autoplay
        loop
        muted
        :class="$style.BackgroundVideo"
      />
    </transition>
    <transition appear name="fade-text">
      <section
        v-show="!lobby.show_live_intro"
        key="intro-text"
        :class="$style.Message"
      >
        <transition name="fade">
          <div
            v-if="!current.performance_complete"
            key="text-template"
            :class="$style.MessageInner"
          >
            <p>{{ copy.waiting_room_text }}</p>
          </div>
          <div
            v-if="current.performance_complete"
            key="text-template"
            :class="$style.MessageInner"
          >
            <p>{{ copy.ended_text }}</p>
          </div>
        </transition>
      </section>
    </transition>
    <transition name="fade-video">
      <output-lobby
        v-if="current && lobby && lobby.active_feed && lobby.show_live_intro"
        key="live"
        :uid="lobby.active_feed.uid"
        :class="$style.LiveVideo"
      />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OutputLobby from "@/components/OutputLobby";
export default {
  name: "WaitingRoom",
  components: {
    OutputLobby,
  },
  computed: {
    ...mapState("performance/lobby", ["lobby"]),
    ...mapState("performance", ["current"]),
    ...mapState(["copy"]),
  },
  data() {
    return {};
  },
  watch: {
    current(val) {
      if (!val) return;
      if (val.active === true) {
        this.$router
          .replace({
            name: "performance",
            params: { id: this.$route.params.id },
          })
          .catch(() => {});
      }
      if (val.active === false) {
        this.$router
          .replace({
            name: "waiting-room",
            params: { id: this.$route.params.id },
          })
          .catch(() => {});
      }
    },
  },
  async mounted() {
    try {
      await this.bind(this.$route.params.id);
      await this.bindLiveStreams(this.$route.params.id);
      await this.bindLobby(this.$route.params.id);
      await this.initStreams();
      this.setHasInteracted(true);
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    ...mapActions(["setHasInteracted"]),
    ...mapActions("performance", ["bind"]),
    ...mapActions("performance/lobby", ["bindLobby"]),
    ...mapActions("performance/liveStreams", [
      "bindLiveStreams",
      "initStreams",
    ]),
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center;
}
.BackgroundVideo {
  @apply block object-cover fixed top-0 left-0 z-10 w-full h-full;
}
.LiveVideo {
  aspect-ratio: 4/3;
  @apply max-w-lg h-auto;
}

.Message {
  @apply absolute w-full h-full z-20 flex justify-center items-center text-center px-52;
  @apply bg-black bg-opacity-60 transform whitespace-pre-wrap leading-tight;
}

.MessageInner {
  @apply bg-black border border-solid border-keyline p-4;
}
</style>
