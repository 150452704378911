import { firestoreAction } from "vuexfire";
import { db, FieldValue } from "@/firebase";

const audience = {
  namespaced: true,
  state: {
    list: null,
  },
  getters: {},
  actions: {
    addAudience: firestoreAction(
      ({ rootGetters }, { performanceId, ticketId }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("audience")
          .doc(ticketId)
          .set({ user: {} });
      }
    ),
    addBulkAudience: firestoreAction(
      ({ rootGetters }, { performanceId, tickets }) => {
        var batch = db.batch();
        tickets.forEach((ticket) => {
          const docRef = db
            .collection("performances")
            .doc(performanceId)
            .collection("audience")
            .doc(ticket);
          batch.set(docRef, {
            logins: 0,
            performanceId: performanceId,
            user: {},
          });
        });
        return batch.commit();
      }
    ),
    updateAudience: firestoreAction(
      ({ rootGetters }, { performanceId, ticketId, displayName }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("audience")
          .doc(ticketId)
          .update({
            ticket_id: ticketId,
            users: FieldValue.arrayUnion(displayName),
            logins: FieldValue.increment(1),
          })
          .catch((err) => {
            console.error(err);
          });
      }
    ),
    deleteAudience: firestoreAction(
      ({ rootGetters }, { performanceId, ticketId }) => {
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("audience")
          .doc(ticketId)
          .delete()
          .then((resp) => {
            return resp;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    ),

    bindAudience: firestoreAction(
      ({ bindFirestoreRef, rootGetters }, performanceId) => {
        // Bind all performances to the state
        return bindFirestoreRef(
          "list",
          db
            .collection("performances")
            .doc(performanceId)
            .collection("audience")
        );
      }
    ),
    unbindAudience: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("list");
    }),
    validateTicketId: firestoreAction(async ({ dispatch }, ticketId) => {
      const audience = db.collectionGroup("audience");
      let performances = [];
      await audience.get(ticketId).then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          if (doc.ref.id === ticketId) {
            performances.push({
              performanceId: doc.ref.parent.parent.id,
              ticketId: doc.ref.id,
            });
          }
        });
      });
      return performances;
    }),
  },
};

export default audience;
