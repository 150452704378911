<template>
  <div :class="{ [$style.VideoWrap]: true }">
    <slot name="timestamp" />
    <video
      v-show="playback"
      ref="player"
      :src="url"
      :loop="true"
      :muted="muted"
      preload="true"
      :controls="controls"
      :class="$style.Video"
      @pause="onPause"
      @play="onPlay"
      @seeked="onSeekEnd"
      @canplay="onCanPlay"
    />
    <screen-static-disabled v-show="!playback" />

    <!-- <div v-show="loading && !isAdmin" :class="$style.Loading">Loading...</div> -->
    <slot name="output" />
  </div>
</template>

<script>
import format from "date-fns/format";
import ScreenStaticDisabled from "@/components/ScreenStaticDisabled";

export default {
  name: "OutputRecorded",
  components: {
    ScreenStaticDisabled,
  },
  props: {
    label: {
      type: [String, Boolean],
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    playback: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    paused: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    muted: {
      type: Boolean,
      default: true,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
  data() {
    return {
      autoplayAvailable: true,
      timestamp: "--",
      loading: true,
      isSeeking: false,
    };
  },
  mounted() {
    this.getTimestamp();
    this.$nextTick(() => {
      if (this.$refs.player) this.$refs.player.load();
    });
  },
  watch: {
    feedUrl: function (newVal, oldVal) {
      if (this.$refs.player && newVal !== oldVal) {
        this.$refs.player.load();
      }
    },
    currentTime: function (newVal, oldVal) {
      if (this.$refs.player && newVal !== oldVal && !this.isAdmin) {
        this.$refs.player.currentTime = newVal;
      }
    },
    paused: function (newVal, oldVal) {
      if (this.$refs.player && !this.isAdmin && newVal !== oldVal)
        newVal ? this.$refs.player.pause() : this.$refs.player.play();
      this.loading = false;
    },
  },
  methods: {
    onCanPlay() {
      if (this.$refs.player) {
        if (this.paused === false) {
          this.$refs.player.play();
        } else {
          this.$refs.player.pause();
        }
        this.loading = false;
      }
    },
    onPause() {
      if (this.isAdmin) {
        this.$emit("pause", true);
      }
    },
    onPlay() {
      if (this.isAdmin) {
        this.$emit("play", true);
      }
    },
    onSeekEnd(val) {
      if (this.isAdmin) {
        console.log("onSeekEnd", val);
        if (this.$refs.player) {
          this.$emit("seeked", this.$refs.player.currentTime);
        }
      }
    },
    getTimestamp() {
      setInterval(() => {
        const date = new Date();
        this.timestamp = format(date, "hh:mm:ss/dd.MMM.yy");
      }, 1000);
    },
  },
};
</script>

<style module lang="postcss">
.VideoWrap {
  @apply relative w-full h-full bg-black;
  & video {
    aspect-ratio: 4/3;
    @apply object-cover bg-black w-full h-full object-center;
  }
}

.Loading {
  @apply absolute top-0 left-0 w-full h-full;
  @apply flex items-center justify-center;
}
</style>
