<template>
  <div :class="$style.Wrap">{{ text }}</div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    text: {
      type: String,
      default: "Loading. Please wait...",
    },
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply px-3 h-full flex items-center justify-center;
}
</style>
