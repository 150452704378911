<template>
  <div :class="{ [$style.VideoWrap]: true }">
    <slot name="timestamp" />
    <video
      v-show="connected"
      ref="stream"
      autoplay
      playsInline
      :muted="muted"
      :controls="isAdmin ? 'true' : null"
      :class="$style.Video"
      @pause="onPause"
      @play="onPlay"
    />

    <screen-static-disabled
      v-show="!connected || (connected && !playback)"
      :class="{ [$style.NotConnected]: !connected }"
    />

    <div
      v-show="!connected && !connectionError && playback"
      :class="$style.Loading"
    >
      Connecting...
    </div>
    <div v-show="!connected && connectionError" :class="$style.Loading">
      Connection error!
    </div>
    <slot name="output" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ScreenStaticDisabled from "@/components/ScreenStaticDisabled";
export default {
  name: "LiveOutput",
  components: {
    ScreenStaticDisabled,
  },
  computed: {
    ...mapState("performance/liveStreams", ["streams"]),
    connected() {
      return this.stream?.connected;
    },
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
    activePanel: {
      type: [Number, Boolean],
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    playback: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    paused: {
      type: Boolean,
      default: true,
    },
    muted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      connectionError: false,
      interval: null,
      stream: false,
      feedUrl: null,
    };
  },
  watch: {
    uid(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.stream) {
        this.stream = this.streams[newVal];
      }
    },
    stream(newVal, oldVal) {
      if (newVal && newVal !== oldVal && this.$refs.stream) {
        this.$refs.stream.srcObject = newVal.srcObject;
      }
    },
    activePanel(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.stream) {
        this.$refs.stream.load();
      }
    },
    paused: function (newVal, oldVal) {
      if (this.$refs.stream && !this.isAdmin && newVal !== oldVal)
        newVal ? this.$refs.stream.pause() : this.$refs.stream.play();
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.stream = this.streams === undefined ? false : this.streams[this.uid];
      if (this.$refs.stream) this.$refs.stream.load();
      if (this.stream) this.interval = clearInterval(this.interval);
    }, 1000);
  },
  methods: {
    onPause() {
      if (this.isAdmin) {
        this.$emit("pause", true);
      }
    },
    onPlay() {
      if (this.isAdmin) {
        this.$emit("play", true);
      }
    },
    onTimeUpdate(e) {
      // if (e.target.muted) e.target;
      console.log("onTimeUpdate", e);
    },
  },
};
</script>

<style module lang="postcss">
.VideoWrap {
  @apply relative w-full h-full bg-black;
  & video {
    aspect-ratio: 4/3;
    @apply object-cover w-full h-full bg-black;
  }
}

.Loading {
  @apply absolute top-0 left-0 w-full h-full;
  @apply flex items-center justify-center;
}

.NotConnected {
  @apply relative;
}
</style>
