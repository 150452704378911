<template>
  <router-link
    :to="to"
    :class="{
      ['c-btn']: true,
      ['c-btn-danger']: danger,
      ['c-btn-success']: success,
    }"
  >
    <span><slot /></span>
  </router-link>
</template>

<script>
export default {
  name: "ShittyLink",
  props: {
    to: {
      type: String,
      required: true,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
