<template>
  <div :class="$style.Grid">
    <block light-header :class="$style.Info">
      <template slot="header">
        <h3>Setting default Output values</h3>
      </template>
      <!-- <p>
        Each new performance is created with a set of default values. These
        values can be left as they are, or caan be customised to suit the
        script/venue as required.
      </p> -->
      <p>
        Each performance supports up to 12 "Outputs". An Output is a video
        screen that can be assigned to a panel for the audience to view. Each
        Output can show either a Live Feed or a Recorded Video.
      </p>
      <p>
        By default, Outputs are labeled Output 1, Output 2, Output 3 etc. In
        production it could be useful to give the Outputs more meaningful nanes,
        perhaps assigning them in groups depending on where the live cameras are
        setup, for example.
      </p>
      <p>
        An Output can be assigned to multiple Panels, but any change to each
        Output will affect all Panels the Output is assigned to e.g If an Output
        is assigned to all 6 Panels, changing from live to recorded will impact
        all 6 Panels instantly.
      </p>
    </block>
    <div :class="$style.Outputs">
      <ul :class="$style.List">
        <li v-for="(output, i) in outputs" :key="`output-${i}`">
          <block light-header>
            <template slot="header"> Output {{ output.index }} </template>
            <form-output :output="output" @form-submit="update" />
          </block>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import FormOutput from "@/components/FormOutput";
import { mapActions } from "vuex";
export default {
  name: "AdminOutputs",
  components: {
    FormOutput,
  },
  props: {
    outputs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    ...mapActions("performance/outputs", ["updateOutput"]),
    async update(output) {
      try {
        await this.updateOutput({
          performanceId: this.$route.params.id,
          outputId: output.outputId,
          data: output.data,
        });
        this.$modal.show("dialog", {
          title: "Output updated",
          buttons: [
            {
              title: "OK",
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
          ],
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid gap-4 grid-cols-5;
}

.Info {
  @apply col-span-2 self-start;
}

.Outputs {
  @apply col-span-3;
}

.List {
  @apply grid grid-cols-2 gap-4;
}
</style>
