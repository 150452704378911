<template>
  <section :class="$style.Grid">
    <block auto-content-height light-header :class="$style.Upload">
      <template slot="header">
        <h3>Upload a new video</h3>
      </template>
      <shitty-button @click.native="() => showUploadModal()">
        Upload video file
      </shitty-button>
    </block>

    <ul v-if="videos" :class="$style.Existing">
      <li
        v-for="(video, i) in videos"
        :key="`stream-${i}`"
        :class="$style.Stream"
      >
        <block light-header>
          <template slot="header">
            <h3>{{ video.label || "New Video" }}</h3>
            <button @click="() => onDeleteRecordedVideo(video.id)">
              <img :src="require('@/assets/icons/delete.svg')" />
            </button>
          </template>

          <form-row :label="'Preview'">
            <output-recorded
              :responsive="false"
              :label="video.label"
              :url="video.url"
              :type="'recorded'"
              is-admin
              :playback="true"
              controls
              :autoplay="false"
            />
          </form-row>

          <form-row no-wrap label="Video URL">
            <input
              v-model="video.url"
              type="text"
              placeholder="Stream URL"
              readonly
            />
            <shitty-button icon @click.native="() => showUploadModal(video.id)">
              <img :src="require('@/assets/icons/upload.svg')" />
              <!-- <span class="sr-only">upload a different video</span> -->
            </shitty-button>
          </form-row>

          <form-row :label="'label'" no-wrap>
            <input
              v-model="video.label"
              type="text"
              placeholder="Set the stream label"
            />
            <shitty-button
              icon
              @click.native="
                () =>
                  onUpdateRecordedVideo({
                    videoId: video.id,
                    data: { label: video.label },
                  })
              "
            >
              <img :src="require('@/assets/icons/update.svg')" />
              <!-- <span class="sr-only">Update label</span> -->
            </shitty-button>
          </form-row>
        </block>
      </li>
    </ul>
    <modal
      :name="`upload-video`"
      :width="'500px'"
      :height="'auto'"
      :adaptive="true"
      :classes="[$style.Modal]"
      :styles="['u-modal-overlay']"
      :clickToClose="true"
    >
      <form-upload-video
        :video-id="videoId"
        @upload="onUpload"
        @close="hideUploadModal"
      />
    </modal>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import FormUploadVideo from "@/components/FormUploadVideo";

export default {
  name: "AdminRecordedVideos",
  components: { FormUploadVideo },
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      videoId: false,
    };
  },
  methods: {
    ...mapActions("performance/recordedVideos", [
      "createRecordedVideo",
      "updateRecordedVideo",
      "deleteRecordedVideo",
    ]),
    async onUpload({ url, videoId, label }) {
      try {
        videoId
          ? await this.onUpdateRecordedVideo({ videoId, data: { url, label } })
          : await this.onCreateRecordedVideo({ url, label });
        this.$modal.hide(`upload-video`);
      } catch (err) {
        console.error(err);
        this.$modal.hide(`upload-video`);
      }
    },
    async onCreateRecordedVideo(data) {
      try {
        await this.createRecordedVideo({
          performanceId: this.$route.params.id,
          data,
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onUpdateRecordedVideo({ videoId, data }) {
      try {
        await this.updateRecordedVideo({
          performanceId: this.$route.params.id,
          videoId,
          data,
        });
      } catch (err) {
        console.error(err);
      }
    },
    onDeleteRecordedVideo(videoId) {
      this.$modal.show("dialog", {
        title: `Delete video`,
        text: "Are you sure you want to delete this video? This cannot be undone.",
        buttons: [
          {
            title: "Cancel",
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: "Delete",
            handler: async () => {
              try {
                await this.deleteRecordedVideo({
                  performanceId: this.$route.params.id,
                  videoId,
                });
                this.$modal.hide("dialog");
              } catch (err) {
                console.error(err);
                this.$modal.hide("dialog");
              }
            },
          },
        ],
      });
    },
    showUploadModal(videoId) {
      this.videoId = videoId;
      this.$modal.show(`upload-video`);
    },
    hideUploadModal() {
      this.videoId = false;
      this.$modal.hide("upload-video");
    },
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid grid-cols-8 gap-4;
}

.Upload {
  @apply col-span-2 row-span-1;
}

.Existing {
  @apply col-span-6 row-span-2 grid grid-cols-4 gap-3;
}
</style>
