<template>
  <ul :class="$style.Grid">
    <li v-for="(panel, i) in list" :key="`output-${i}`">
      <block light-header>
        <template slot="header">
          Panel {{ i + 1 }}
          <label :class="$style.Checkbox">
            active:
            <input
              type="checkbox"
              v-model="panel.active"
              @change="
                onPanelActivate({ state: panel.active, panelId: panel.id })
              "
            />
          </label>
        </template>
        <form-panel
          v-show="panel.active"
          :outputs="outputs"
          :panel="panel"
          @form-submit="update"
        />
      </block>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FormPanel from "@/components/FormPanel";
export default {
  name: "AdminPanels",
  props: {
    outputs: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    FormPanel,
  },
  computed: {
    ...mapState("performance/panels", ["list"]),
  },
  methods: {
    ...mapActions("performance/panels", ["updatePanel", "activatePanel"]),
    async onPanelActivate({ state, panelId }) {
      console.log(state, panelId);
      try {
        await this.activatePanel({
          performanceId: this.$route.params.id,
          panelId: panelId,
          data: { active: state },
        });
      } catch (err) {
        console.error(err);
      }
    },

    async update({ panelId, outputs, outputCount }) {
      try {
        await this.updatePanel({
          performanceId: this.$route.params.id,
          panelId,
          data: { outputs, outputCount },
        });
        this.$modal.show("dialog", {
          title: "Panel updated",
          buttons: [
            {
              title: "OK",
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
          ],
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid grid-cols-3 gap-4;
}

.Checkbox {
  @apply flex uppercase text-black text-xs items-center gap-3 leading-none;
  & input {
    @apply mb-0;
  }
}
</style>
