<template>
  <header :class="$style.Footer">
    <slot />
  </header>
</template>

<script>
export default {
  name: "BlockFooter",
};
</script>

<style module lang="postcss">
.Footer {
  @apply flex justify-between items-center p-2;
}
</style>
