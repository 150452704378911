<template>
  <div :class="$style.Row">
    <form-label v-if="label">
      {{ label }}
    </form-label>
    <div
      :class="{
        [$style.Input]: true,
        [$style.Error]: error,
        [$style.NoWrap]: noWrap,
        [$style.Flex]: flex,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormRow",
  props: {
    label: {
      type: [String, Boolean],
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style module lang="postcss">
.Row {
  @apply mb-3 w-full;
  &:last-child {
    @apply mb-0;
  }
}

.Input {
  @apply flex flex-wrap gap-x-3;
  & button {
    @apply whitespace-nowrap;
  }
}

.NoWrap {
  @apply flex-nowrap;
}
.Error {
  & input {
    @apply border-red-800 !important;
  }
  & input:focus,
  & input:active {
    @apply border-red-600 !important;
  }
}

.Flex {
  @apply flex gap-3 flex-nowrap;
}
</style>
