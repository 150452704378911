<template>
  <block v-if="output" light-header>
    <template slot="header" :class="$style.Header">
      <h2>{{ output.label }} preview</h2>
    </template>
    <div :class="$style.Output">
      <div :class="$style.Video">
        <div :class="['aspect-w-16 aspect-h-9', $style.VideoPreview]">
          <template
            v-if="
              output.active_feed &&
              output.active_feed.type === 'recorded' &&
              output.active_feed.url
            "
          >
            <output-recorded
              is-admin
              :label="output.active_feed.label"
              :url="output.active_feed.url"
              :type="output.active_feed.type"
              :uid="output.active_feed.uid"
              :playback="output.playback"
              :paused="output.paused"
              :loop="output.loop"
              :muted="output.muted"
              :controls="true"
              @play="() => onPlayPause(false)"
              @pause="() => onPlayPause(true)"
              @seeked="(time) => onSeeked(time)"
            />
          </template>
          <template
            v-if="
              output.active_feed &&
              output.active_feed.type === 'live' &&
              output.active_feed.url
            "
          >
            <output-live
              is-admin
              :label="output.active_feed.label"
              :url="output.active_feed.url"
              :type="output.active_feed.type"
              :uid="output.active_feed.uid"
              :playback="output.playback"
              :muted="output.muted"
              :paused="output.paused"
              @play="() => onPlayPause(false)"
              @pause="() => onPlayPause(true)"
            />
          </template>

          <span :class="$style.textOverlayOutput">
            {{ output.text_overlay }}
          </span>
        </div>
      </div>
      <div :class="$style.Options">
        <input v-model="textOverlay" type="text" placeholder="text overlay" />
        <shitty-button
          :disabled="
            (output.text_overlay === '' && textOverlay === '') ||
            output.text_overlay === textOverlay
          "
          @click.native="onUpdateTextOverlay"
        >
          <img :src="require('@/assets/icons/update.svg')" />
        </shitty-button>
      </div>
      <div :class="$style.Feeds">
        <select v-model="feed" class="c-select" @change="onSetFeed">
          <option disabled value="">feeds</option>
          <option
            v-for="(item, i) in feedOptions"
            :key="`feed-${i}`"
            :value="item"
          >
            {{ item.type }} - {{ item.label }}
          </option>
        </select>
      </div>
      <div :class="$style.Options">
        <div :class="$style.Playback">
          <shitty-button
            v-if="output.muted"
            danger
            @click.native="onToggleMute(false)"
          >
            <img :src="require('@/assets/icons/muted.svg')" />
          </shitty-button>
          <shitty-button
            v-if="!output.muted"
            @click.native="onToggleMute(true)"
          >
            <img :src="require('@/assets/icons/unmuted.svg')" />
          </shitty-button>
        </div>
        <shitty-button
          :class="{
            [$style.CutFeed]: true,
            ['c-btn-danger']: !output.playback,
          }"
          @click.native="output.playback ? onCutFeed() : onEnableFeed()"
        >
          {{ output.playback ? "disable" : "enable" }}
        </shitty-button>
      </div>
    </div>
  </block>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "OutputManager",
  props: {
    output: {
      type: Object,
      default: () => {},
    },
    live: {
      type: Array,
      default: () => [],
    },
    recorded: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    feedOptions() {
      const feeds = [];
      this.live.map((option) => {
        option.type = "live";
        feeds.push(option);
      });
      this.recorded.map((option) => {
        option.type = "recorded";
        feeds.push(option);
      });
      return feeds;
    },
  },
  data() {
    return {
      feed: "",
      textOverlay: "",
      playbackTime: 0,
      duration: 100,
    };
  },
  mounted() {
    this.feed = this.output.active_feed || null;
    this.textOverlay = this.output.text_overlay;
  },
  methods: {
    ...mapActions("performance/outputs", ["updateOutput"]),
    async onSetFeed() {
      try {
        await this.updateOutput({
          performanceId: this.$route.params.id,
          outputId: this.output.id,
          data: { active_feed: { ...this.feed, uid: this.feed.id } },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onPlayPause(val) {
      console.log(val);
      try {
        await this.updateOutput({
          performanceId: this.$route.params.id,
          outputId: this.output.id,
          data: { paused: val },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onSetLoop() {
      try {
        await this.updateOutput({
          performanceId: this.$route.params.id,
          outputId: this.output.id,
          data: { loop: !this.output.loop },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onCutFeed() {
      try {
        await this.updateOutput({
          performanceId: this.$route.params.id,
          outputId: this.output.id,
          data: { playback: false, mute_audience: true },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onSeeked(time) {
      try {
        await this.updateOutput({
          performanceId: this.$route.params.id,
          outputId: this.output.id,
          data: { current_time: time },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onEnableFeed() {
      try {
        await this.updateOutput({
          performanceId: this.$route.params.id,
          outputId: this.output.id,
          data: { playback: true, mute_audience: false },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onUpdateTextOverlay() {
      try {
        await this.updateOutput({
          performanceId: this.$route.params.id,
          outputId: this.output.id,
          data: { text_overlay: this.textOverlay },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async onToggleMute(state) {
      try {
        await this.updateOutput({
          performanceId: this.$route.params.id,
          outputId: this.output.id,
          data: { muted: state },
        });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style module lang="postcss">
.Output {
  @apply flex flex-col gap-3;
}

.VideoPreview {
  @apply bg-black relative;
  aspect-ratio: 4/3;
  & video,
  & img {
    @apply w-full h-full;
    object-fit: cover;
  }
}

.textOverlayOutput {
  @apply absolute bottom-0 left-0 right-0 py-1 px-2 bg-black bg-opacity-25;
}
.Feeds {
  @apply flex flex-col gap-2;
}

.Options {
  @apply flex gap-3 justify-between;
}

.Playback {
  @apply flex gap-3;
}
.CutFeed {
  @apply flex-grow;
}
</style>
