import Vue from "vue";
import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";

const outputs = {
  namespaced: true,
  state: {
    list: null,
    streams: [],
  },
  getters: {
    getStreams(state) {
      return state.streams;
    },
  },
  mutations: {
    SET_STREAM(state, { idx, stream }) {
      Vue.set(state.streams, idx, stream);
    },
  },
  actions: {
    createOutput: firestoreAction(
      ({ rootGetters }, { performanceId, data }) => {
        console.log("creating output", data);
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("outputs")
          .doc()
          .set(data);
      }
    ),
    updateOutput: firestoreAction(
      ({ rootGetters }, { performanceId, outputId, data }) => {
        console.log(performanceId, outputId, data);
        return db
          .collection("performances")
          .doc(performanceId)
          .collection("outputs")
          .doc(outputId)
          .update(data);
      }
    ),
    updateOutputs: firestoreAction(
      ({ rootGetters }, { performanceId, data }) => {
        console.log(performanceId, data);
        const outputRefs = db
          .collection("performances")
          .doc(performanceId)
          .collection("outputs");

        outputRefs.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update(data);
          });
        });
      }
    ),

    bindOutputs: firestoreAction(
      ({ bindFirestoreRef, rootGetters }, performanceId) => {
        // Bind all performances to the state
        return bindFirestoreRef(
          "list",
          db
            .collection("performances")
            .doc(performanceId)
            .collection("outputs")
            .orderBy("index", "asc")
        );
      }
    ),
    unbindAllOutputs: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef("list");
    }),
  },
};

export default outputs;
