<template>
  <button
    :class="{
      [$style.Icon]: true,
      [$style.VotingOpen]: votingOpen,
      [$style.VotePassed]: votePassed,
    }"
  >
    <!-- Locked -->
    <svg
      v-if="(!votingOpen && !votePassed) || (votingOpen && !votePassed)"
      width="100"
      height="125"
      viewBox="0 0 100 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :class="$style.DynamicTheme"
        d="M76.84 57.6399H25.22H24.34C19.73 57.6399 15.99 61.3899 16 65.9999V102.65C16 107.26 19.75 111 24.36 111L76.34 110.97C80.95 110.97 84.69 107.23 84.68 102.62V65.9599C84.68 61.5299 81.21 57.9099 76.84 57.6399Z"
        fill="black"
      />
      <path
        :class="$style.DynamicTheme"
        d="M31.7367 53.907C33.5287 53.907 34.9817 52.453 34.9807 50.661L34.9757 45.328C34.9717 36.861 41.8577 29.968 50.3247 29.964C58.7917 29.96 65.6847 36.846 65.6887 45.313L65.6937 50.667C65.6957 52.457 67.1467 53.908 68.9377 53.908H71.4137C73.2067 53.908 74.6587 52.454 74.6577 50.662L74.6527 45.308C74.6437 31.897 63.7287 20.993 50.3187 21C36.9087 21.007 26.0047 31.921 26.0117 45.332L26.0167 50.667C26.0187 52.457 27.4697 53.908 29.2607 53.908H31.7367V53.907Z"
        fill="black"
      />
    </svg>

    <!-- Unlocked Icon - shows when vote has passed -->
    <svg
      v-if="locked && votePassed"
      width="100"
      height="125"
      viewBox="0 0 100 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        :class="$style.DynamicTheme"
        d="M76.84 57.6399H25.22H24.34C19.73 57.6399 15.99 61.3899 16 65.9999V102.65C16 107.26 19.75 111 24.36 111L76.34 110.97C80.95 110.97 84.69 107.23 84.68 102.62V65.9599C84.68 61.5299 81.21 57.9099 76.84 57.6399Z"
        fill="black"
      />
      <path
        :class="$style.DynamicTheme"
        d="M35.4115 51.9588C36.9634 51.0628 37.4948 49.0771 36.5979 47.5257L33.9271 42.9097C29.6901 35.579 32.207 26.1665 39.5377 21.9296C46.8683 17.6926 56.2808 20.2095 60.5178 27.5402L63.1991 32.1744C64.0959 33.7236 66.078 34.2547 67.629 33.3592L69.7733 32.1212C71.3261 31.2247 71.8565 29.2395 70.9597 27.688L68.2783 23.0538C61.5651 11.4441 46.6604 7.45844 35.0505 14.1695C23.4406 20.8806 19.4544 35.7844 26.166 47.3951L28.8378 52.0129C29.7346 53.5621 31.7167 54.0932 33.2677 53.1977L35.4115 51.9588Z"
        fill="black"
      />
    </svg>
    <div v-if="time && votingOpen" :class="$style.Time">{{ time }}s</div>
    <!-- <div :class="$style.Time">10s</div> -->
  </button>
</template>

<script>
export default {
  name: "IconLock",
  props: {
    locked: {
      type: Boolean,
      default: true,
    },
    votingOpen: {
      type: Boolean,
      default: false,
    },
    votePassed: {
      type: Boolean,
      default: false,
    },
    used: {
      type: Boolean,
      default: false,
    },
    time: {
      type: [Number, Boolean],
      default: 10,
    },
  },
  computed: {},
};
</script>

<style module lang="postcss">
.Icon {
  @apply w-14 block absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-3;
  & svg {
    @apply object-contain block w-full h-full;
  }
}

.DynamicTheme {
  @apply fill-white;
}

.VotingOpen {
  & .DynamicTheme {
    fill: theme("colors.red.500");
  }
}
.VotePassed {
  & .DynamicTheme {
    fill: theme("colors.yellow.DEFAULT");
  }
}

.Time {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-px z-20 mt-px text-black;
}
</style>
