<template>
  <container :class="{ [$style.Container]: true, [$style.Glitch]: glitch }">
    <template v-if="current && panels && ready">
      <template v-for="(panel, panelIndex) in activePanels">
        <div
          v-show="isActivePanel(panelIndex)"
          :class="{
            [$style.Outputs]: true,
            [$style.OutputsOne]: panel.outputCount === 1,
            [$style.OutputsTwo]: panel.outputCount === 2,
            [$style.OutputsThree]: panel.outputCount === 3,
            [$style.OutputsFour]: panel.outputCount === 4,
            [$style.OutputsSix]: panel.outputCount === 6,
          }"
          :key="`panel-${panelIndex}`"
        >
          <div :class="$style.Grid">
            <template v-for="(output, i) in panel.outputs">
              <audience-output
                v-if="output"
                :key="`output-${panelIndex}-${i}`"
                :output="output"
                :panel="activePanel"
                :forceMute="forceMute(panelIndex)"
                :class="$style.Output"
              />
            </template>
          </div>
        </div>
      </template>

      <nav :class="{ [$style.Controls]: true }">
        <audience-chat-window :class="$style.ChatWindow" />
        <audience-chat-terminal
          :chat-disabled="current.chat_disabled"
          :class="$style.ChatTerminal"
        />
        <section :class="$style.SystemNav">
          <audience-interactions :class="$style.System" />
          <audience-nav
            :panels="activePanels.length - 1"
            :panel="activePanel"
            @next-panel="nextPanel"
            @prev-panel="prevPanel"
          />
        </section>
      </nav>
      <audience-master-soundtrack
        v-if="current.master_soundtrack.active"
        :url="current.master_soundtrack.active.url"
        :playback="current.master_soundtrack.playback"
        :currentTime="current.master_soundtrack.current_time"
        :playerid="'audience-soundtrack'"
      />
    </template>
    <template v-else><loading /></template>
  </container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AudienceChatWindow from "@/components/audience/AudienceChatWindow.vue";
import AudienceChatTerminal from "@/components/audience/AudienceChatTerminal.vue";
import AudienceInteractions from "@/components/audience/AudienceInteractions.vue";
import AudienceOutput from "@/components/audience/AudienceOutput.vue";
import AudienceNav from "@/components/audience/AudienceNav";
import AudienceMasterSoundtrack from "@/components/audience/AudienceMasterSoundtrack";

export default {
  name: "Performance",
  transition: "fade",
  components: {
    AudienceChatWindow,
    AudienceChatTerminal,
    AudienceInteractions,
    AudienceOutput,
    AudienceNav,
    AudienceMasterSoundtrack,
  },
  computed: {
    ...mapState(["account", "hasInteracted"]),
    ...mapState("performance", ["current"]),
    ...mapState("performance/panels", { panels: "list" }),
    activePanels() {
      if (!this.panels.length) return [];
      return this.panels.filter((panel) => panel.active);
    },
  },
  watch: {
    current(val) {
      if (!val) return;
      if (val.active === false) {
        this.$router
          .replace({
            name: "waiting-room",
            params: { id: this.$route.params.id },
          })
          .catch(() => {});
      }
    },
  },
  data() {
    return {
      activePanel: 0,
      glitch: false,
      ready: false,
    };
  },
  async mounted() {
    // console.log(this.$route.meta.requiresAuth);
    if (!this.hasInteracted && this.$route.meta.requiresAuth) {
      this.$modal.show("dialog", {
        title: "Interaction Prompt",
        text: "Please click OK to enable live streams.",
        buttons: [
          {
            title: "OK",
            handler: async () => {
              try {
                await this.bind(this.$route.params.id);
                await this.bindMessages(this.$route.params.id);
                await this.bindInteractions(this.$route.params.id);
                await this.bindPanels(this.$route.params.id);
                await this.bindLiveStreams(this.$route.params.id);
                await this.bindLobby(this.$route.params.id);
                await this.initStreams();
                this.ready = true;
              } catch (err) {
                console.error(err);
              }
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    } else if (this.hasInteracted && this.$route.meta.requiresAuth) {
      try {
        await this.bind(this.$route.params.id);
        await this.bindMessages(this.$route.params.id);
        await this.bindInteractions(this.$route.params.id);
        await this.bindPanels(this.$route.params.id);
        await this.bindLiveStreams(this.$route.params.id);
        await this.bindLobby(this.$route.params.id);
        await this.initStreams();
        this.ready = true;
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        await this.bind(this.$route.params.id);
        await this.bindMessages(this.$route.params.id);
        await this.bindInteractions(this.$route.params.id);
        await this.bindPanels(this.$route.params.id);
        await this.bindLiveStreams(this.$route.params.id);
        await this.bindLobby(this.$route.params.id);
        await this.initStreams();
        this.ready = true;
      } catch (err) {
        console.error(err);
      }
    }
  },
  methods: {
    ...mapActions("performance", ["bind", "unbind"]),
    ...mapActions("performance/messages", ["bindMessages"]),
    ...mapActions("performance/interactions", ["bindInteractions"]),
    ...mapActions("performance/lobby", ["bindLobby"]),
    ...mapActions("performance/liveStreams", [
      "bindLiveStreams",
      "initStreams",
    ]),
    ...mapActions("performance/panels", ["bindPanels"]),
    nextPanel() {
      if (this.activePanel < this.panels.length) {
        this.activePanel = this.activePanel + 1;
      }
    },
    prevPanel() {
      if (this.activePanel > 0) {
        this.activePanel = this.activePanel - 1;
      }
    },
    isActivePanel(panelIndex) {
      return this.activePanel === panelIndex;
    },
    forceMute(panelIndex) {
      return this.activePanel !== panelIndex;
    },
  },
};
</script>

<style module lang="postcss">
.Container {
  @apply relative;
}
.Outputs {
  @apply border border-solid border-keyline p-3 py-4 gap-2 border-t-0;
  @apply absolute top-0 left-2 right-2;
  bottom: calc(theme("spacing.72") - theme("spacing.3"));
  @apply flex items-center justify-center;
}

.Grid {
  @apply w-full grid gap-2 max-w-full h-full place-content-center items-center justify-items-center content-center;
}

.Output {
  @apply max-h-full max-h-full;
  aspect-ratio: 4/3;
}

.OutputsOne .Grid {
  @apply grid-rows-1 items-center;
  & .Output {
    @apply min-h-full;
    /* width: 700px; */
  }
}

.OutputsTwo .Grid {
  @apply w-auto  grid-rows-1 grid-cols-2;
  & .Output {
    /* max-width: 500px; */
  }
}

.OutputsThree .Grid {
  @apply w-auto  grid-rows-1 grid-cols-3;
  & .Output {
    /* max-width: 500px; */
  }
}

.OutputsFour .Grid {
  @apply grid-cols-2 grid-rows-2;
  & .Output {
    &:nth-child(odd) {
      @apply justify-self-end;
    }
    &:nth-child(even) {
      @apply justify-self-start;
    }
  }
}

.OutputsSix .Grid {
  /* @apply flex flex-wrap; */
  @apply w-auto grid-cols-3 grid-rows-2  justify-center;
  & .Output {
    @apply max-w-full;
    /* &:nth-child(1),
    &:nth-child(4) {
      @apply justify-self-end;
    }
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
    }
    &:nth-child(3),
    &:nth-child(6) {
      @apply justify-self-start;
    } */
  }
}

.Controls {
  @apply absolute bottom-2 w-full;
  @apply grid grid-cols-12 gap-3 px-2 flex-shrink-0 flex-grow-0;
  @apply h-controls !important;
}

.ChatWindow {
  @apply col-span-5;
}

.ChatTerminal {
  @apply col-span-2;
}

.SystemNav {
  @apply col-span-5 flex flex-col gap-6  pb-2;
}

.System {
  @apply flex-grow;
}
</style>
